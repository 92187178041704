import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TextAnnotation, BoundingPoly, Vertex, Page, } from '../../lib/gvision';
import classnames from 'classnames';
import { ExtractionFields } from './editor';
import _ from 'lodash';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  region: {
    mixBlendMode: 'multiply',
    fill: 'white',
    '&:hover': {
      fill: 'rgba(255,255,200,0.8)'
    }
  },
  highlight: {
    fill: 'rgba(200,200,255,0.5)'
  },
  selected: {
    fill: 'yellow'
  },
  selectionRect: {
    fill: 'none',
    stroke: 'black',
    strokeDasharray: '12',
    strokeOpacity: 0.2,
    strokeWidth: 4,
    vectorEffect: 'non-scaling-stroke',
  },
  label: {
    mixBlendMode: 'multiply',
    userSelect: 'none',
    '&:hover': {
      fill: 'rgba(0,0,0,0)'
    },
  },
  labelSender: {
    fill: '#0000FF',
  },
  labelReceiver: {
    fill: '#FF0000',
  },
  labelOther: {
    fill: '#009900',
  },
  line: {
    stroke: theme.palette.primary.main,
    strokeOpacity: 0.8,
    strokeWidth: '3px',
    vectorEffect: 'non-scaling-stroke',
  }
}));

const RAD_TO_DEG = 180 / Math.PI;
interface Props {
  data: TextAnnotation;
  image: string;
  selected: number[];
  highlighted: number[];
  labels: { [position: number]: { text: string, path: string } };
  rotation: number;
  zoom: number;
  onSelected: (a: { selected: number[], raw: string }, addToSelection: boolean) => void;
  onNext: () => void;
  fieldsRef: React.RefObject<ExtractionFields>;
  scrollLeftRef: React.RefObject<HTMLDivElement>;
  scrollRightRef: React.RefObject<HTMLDivElement>;
  currentPath?: string;
}

function getSelection(bbox: BBox, pageData: Page | null): { selected: number[], raw: string } {
  const selected = [];
  if (!pageData) {
    return { selected: [], raw: '' };
  }
  let i = 0;
  let raw = '';
  for (const block of pageData.blocks) {
    for (const paragraph of block.paragraphs) {
      for (const word of paragraph.words) {
        if (intersects(toBBox(word.bounding_box.vertices), bbox)) {
          selected.push(i);
          for (const symbol of word.symbols) {
            raw += symbol.text;
            if (symbol.property && symbol.property.detected_break && symbol.property.detected_break.type !== 0) {
              raw += ' ';
            }
          }
        }
        i++;
      }
    }
  }
  raw = raw.trim();
  return { selected, raw };
}

export const VisionViewer = React.memo(
  function VisionViewer(props: Props): JSX.Element {
    const classes = useStyles();
    const [start, setStart] = React.useState({ x: 0, y: 0 });
    const [end, setEnd] = React.useState({ x: 0, y: 0 });
    const [isSelecting, setSelecting] = React.useState(false);
    const svgRef = React.useRef<SVGSVGElement>(null);
    const gRef = React.useRef<SVGSVGElement>(null);
    const lineRef = React.useRef<SVGPathElement>(null);
    const page = props.data.pages[0];

    const fromClientCoords = React.useCallback(
      (x: number, y: number): number[] => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const point = svgRef.current!.createSVGPoint();
        point.x = x;
        point.y = y;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const p = point.matrixTransform(gRef.current!.getScreenCTM()!.inverse());
        return [p.x, p.y];
      }
      , [svgRef, gRef]);

    const toClientCoords = React.useCallback(
      (x: number, y: number): number[] => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const point = svgRef.current!.createSVGPoint();
        point.x = x;
        point.y = y;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const p = point.matrixTransform(gRef.current!.getScreenCTM()!);
        return [p.x, p.y];
      }
      , [svgRef]);

    const coordsOf = React.useCallback((e: React.MouseEvent): number[] => {
      return fromClientCoords(e.clientX, e.clientY);
    }, [fromClientCoords]);

    const startSelection = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        if (e.button !== 0) { return; }
        const [x, y] = coordsOf(e);
        const newStart = { x, y };
        const newEnd = { x, y };
        setStart(newStart);
        setEnd(newEnd);
        setSelecting(true);
      },
      [coordsOf]
    );

    const endSelection = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        if (e.button !== 0) { return; }
        const bbox = toBBox([start, end]);
        setSelecting(false);
        props.onSelected(getSelection(bbox, page), e.shiftKey);
      },
      [start, end, page, props]
    );

    const updateSelection = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        if (isSelecting) {
          const [x, y] = coordsOf(e);
          setEnd({ x, y });
        }
      },
      [isSelecting, coordsOf]
    );

    const onContextMenu = React.useCallback((e: React.MouseEvent<SVGElement, MouseEvent>) => {
      e.preventDefault();
      props.onNext();
    }, [props]);

    const polys = [];
    const labels = [];
    const selectedWords = [];
    const selectingBBox = toBBox([start, end]);
    let selectedBBox = { x1: Infinity, y1: Infinity, x2: -Infinity, y2: -Infinity };
    let selectedCoords: { x: number, y: number } | undefined = undefined;

    React.useLayoutEffect(() => {
      function updateLine() {
        let path = '';
        if (props.currentPath && selectedCoords && props.fieldsRef?.current) {
          const input: HTMLElement | null = props.fieldsRef?.current?.inputs[props.currentPath].current;
          if (input) {
            const rect = input.getBoundingClientRect();
            const x = rect.right;
            const y = rect.top + rect.height / 2;
            const [lineX0, lineY0] = fromClientCoords(x, y);
            const [lineX1, lineY1] = fromClientCoords(x + 80, y);
            let [lineX2, lineY2] = toClientCoords(selectedCoords.x, selectedCoords.y);
            [lineX2, lineY2] = fromClientCoords(lineX2 - 20, lineY2);
            const [lineX3, lineY3] = [selectedCoords.x, selectedCoords.y];
            path = `M${lineX0} ${lineY0} L${lineX1} ${lineY1} L${lineX2} ${lineY2} L${lineX3} ${lineY3}`;
          }

        }
        lineRef.current?.setAttribute('d', path);
      }
      updateLine();
      if (props.scrollLeftRef.current != null && props.scrollRightRef.current != null) {
        props.scrollLeftRef.current.addEventListener('scroll', updateLine);
        props.scrollRightRef.current.addEventListener('scroll', updateLine);
      }
      return () => {
        props.scrollLeftRef.current?.removeEventListener('scroll', updateLine);
        props.scrollRightRef.current?.removeEventListener('scroll', updateLine);
      };
    });

    if (!page) {
      return <img src={props.image} />;
    }

    for (const block of page.blocks) {
      for (const paragraph of block.paragraphs) {
        for (const word of paragraph.words) {
          const i = polys.length;
          const selected = props.selected.includes(i);
          const wordBB = toBBox(word.bounding_box.vertices);
          if (selected) {
            selectedWords.push(word);
            selectedBBox = mergeBBox(selectedBBox, wordBB);
          }
          const selecting = isSelecting && intersects(wordBB, selectingBBox);
          polys.push(
            <polygon
              key={i}
              points={boundinBoxToSvg(word.bounding_box)}
              className={classnames(
                classes.region,
                (isSelecting ? selecting : selected) && classes.selected,
                props.highlighted.includes(i) && classes.highlight
              )}
              style={{ opacity: word.confidence }}
            />
          );
          if (props.labels[i] != null) {
            const { text: label, path } = props.labels[i];
            const x = word.bounding_box.vertices[0].x;
            const y = word.bounding_box.vertices[0].y;
            if (selected) {
              selectedCoords = { x, y };
            }
            const x2 = word.bounding_box.vertices[1].x;
            const y2 = word.bounding_box.vertices[1].y;
            // cos a = u.v / (|u||v|)
            // v = 1,0
            const ux = x2 - x;
            const uy = y2 - y;
            const ul = Math.sqrt(ux * ux + uy * uy); // |u|
            const angle = Math.acos(ux / ul) * RAD_TO_DEG;
            let className = classes.labelOther;
            if (path.startsWith('sender')) {
              className = classes.labelSender;
            } else if (path.startsWith('receiver')) {
              className = classes.labelReceiver;
            }
            labels.push(
              <text
                key={'label' + i}
                x={x}
                y={y}
                transform={`rotate(${angle}, ${x}, ${y})`}
                className={classnames(classes.label, className)}
              >
                {label}
              </text>
            );
          }
        }
      }
    }

    const showRect = isSelecting ? selectingBBox : props.selected.length ? selectedBBox : null;
    const isLandscape = props.rotation === 90 || props.rotation === 270;
    const rotations = [0, 90, 180, 270];
    const rotationIndex = rotations.indexOf(props.rotation);
    const rotationTransX = [0, 0, -page.width, -page.width][rotationIndex];
    const rotationTransY = [0, -page.height, -page.height, 0][rotationIndex];
    const w = isLandscape ? page.height : page.width;
    const h = isLandscape ? page.width : page.height;
    return (
      <svg
        style={{ width: `${props.zoom}%` }}
        ref={svgRef}
        viewBox={`0 0 ${w} ${h}`}
        onMouseDown={startSelection}
        onMouseUp={endSelection}
        onMouseMove={updateSelection}
        onContextMenu={onContextMenu}
      >
        <g transform={`rotate(${props.rotation})`}>
          <g transform={`translate(${rotationTransX},${rotationTransY})`}>
            <g ref={gRef} />
            <image width={page.width} height={page.height} href={props.image} />
            <path ref={lineRef} className={classes.line} fill="none" />
            {polys}
            {labels}
            {showRect ?
              <rect
                className={classes.selectionRect}
                x={showRect.x1}
                y={showRect.y1}
                width={showRect.x2 - showRect.x1}
                height={showRect.y2 - showRect.y1}
              /> : null
            }
          </g>
        </g>
      </svg>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

function boundinBoxToSvg(b: BoundingPoly): string {
  return b.vertices.map((v) => `${v.x},${v.y}`).join(' ');
}

interface BBox {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

function toBBox(vertices: Vertex[]): BBox {
  let x1 = Infinity;
  let y1 = Infinity;
  let x2 = -Infinity;
  let y2 = -Infinity;
  for (const v of vertices) {
    x1 = Math.min(v.x, x1);
    y1 = Math.min(v.y, y1);
    x2 = Math.max(v.x, x2);
    y2 = Math.max(v.y, y2);
  }
  return { x1, x2, y1, y2 };
}

function intersects(a: BBox, b: BBox): boolean {
  return (a.x1 < b.x2 &&
    a.x2 > b.x1 &&
    a.y1 < b.y2 &&
    a.y2 > b.y1);
}

function mergeBBox(a: BBox, b: BBox): BBox {
  return {
    x1: Math.min(a.x1, b.x1),
    y1: Math.min(a.y1, b.y1),
    x2: Math.max(a.x2, b.x2),
    y2: Math.max(a.y2, b.y2),
  };
}
