const INTERCOM_ID = 'flvzbqyb';


export function getIntercomUserUrlByIntercomId(intercomId: string): string {
  return `https://app.intercom.com/a/apps/${INTERCOM_ID}/users/${intercomId}/all-conversations`;
}

export const intercomSenders = [
  { name: 'Verónica', email: 'veronica.santos@facturadirecta.com' },
  { name: 'Anna', email: 'anna.aumatell@facturadirecta.com' },
  { name: 'Jordi', email: 'jordi.ortega@facturadirecta.com' },
  { name: 'Administración', email: 'admin@facturadirecta.com' },
];

export const defaultIntercomSender = 'admin@facturadirecta.com';