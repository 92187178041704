// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const validPercentRE = /^[0-9]{0,3}(\.[0-9]{1,2})?$/;
export const isValidPercent = (s: string): boolean => {
  if (s.match(validPercentRE) != null) {
    const v = Number.parseFloat(s);
    return v >= 0 && v <= 100;
  }
  return false;
};

const forbiddenInPercent = /[^0-9.]/g;
export const parsePercentWithoutDefault = (s: string): string | undefined => {
  const str = s.replace(',', '.').replace(forbiddenInPercent, '');
  const splitted = str.split('.').filter(t => t.length > 0);
  if (splitted.length === 1) {
    return splitted[0];
  } else if (splitted.length === 2) {
    let zeros = 0;
    const decimalPart = splitted[1];
    for (let i = 0; i < decimalPart.length; i++) {
      if (decimalPart[decimalPart.length - 1 - i] !== '0') {
        break;
      }
      zeros++;
    }
    const numNotZeros = decimalPart.length - zeros;
    if (zeros === decimalPart.length) {
      return splitted[0];
    } else {
      const decimalPartNoZeros = decimalPart.substr(0, numNotZeros);
      return splitted[0] + '.' + decimalPartNoZeros;
    }
  } else {
    return undefined;
  }
};

export const parsePercent = (s: string): string => {
  const result = parsePercentWithoutDefault(s);
  return result === undefined ? s : result;
};