// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const zipcodeESRE = /^[0-9]{5}$/;
const zipcodeIntlRE = /^[0-9A-Z]+$/;

const forbiddenInZipcodeRE = /[^0-9A-Z]/g;

export const isValidZipcode = (country: string | null | undefined, s: string): boolean => {
  if (country == null || country === 'ES') {
    let result = s.match(zipcodeESRE) != null;
    if (result) {
      result = s > '01' && s < '53';
    }
    return result;
  } else {
    return s.match(zipcodeIntlRE) != null;
  }
};
export const parseZipcode = (s: string): string => s.replace(forbiddenInZipcodeRE, '');
