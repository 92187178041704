// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const currencyNames: { [s: string]: string } = require('./currencyNames.json');
const currencyCodes: { [s: string]: string } = require('./currencyCodes.json');

const collator = new Intl.Collator(undefined, { ignorePunctuation: true, usage: 'search', sensitivity: 'base' });

export const isValidCurrency = (s: string): boolean => currencyCodes[s] != null;
export const parseCurrencyWithoutDefault = (s: string): string | undefined => {
  for (const [key, value] of Object.entries(currencyNames)) {
    if (collator.compare(s, key) === 0) {
      return value;
    }
  }
  return undefined;
};

export const parseCurrency = (s: string): string => {
  const result = parseCurrencyWithoutDefault(s);
  return result === undefined ? s : result;
};
