// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const codeRE = /^[0-9A-Z/]+$/;
const spaceRE = /\s/g;
const forbiddenInCodeRE = /[^0-9A-Z/]/g;

export const isValidCode = (s: string): boolean => s.match(codeRE) != null;
export const parseCode = (s: string): string => {
  const code = s.toUpperCase().replace(spaceRE, '').replace(forbiddenInCodeRE, '/'); //
  if (code === "") {
    return code;
  } else {
    return "/" + code; // codes starting with / are meant to be processed by accounting to extract the correct series/number
  }
};
