import * as React from 'react';
import classNames from 'classnames';
import {
  Grid, Card,
  CardContent, Button, CardActions, Box
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { Theme } from '@mui/material';
import { Route, Switch, RouteProps } from 'react-router-dom';
import { advisorRoutes, transcriptorRoutes } from './routes';
import { Sidebar } from './sidebar';
import { Notifications, WatchServerUpdates } from './notifications';

import { getUserProfile } from './lib/utilBrowser';
import { Loading } from './widgets/loading';
import { QLoggedUser_viewer } from './gen/QLoggedUser';
import { UserProfile } from './gen/globalTypes';
import { logout } from './auth';
import CompanyTitle from './widgets/companyTitle';
import { userInfo } from './lib/utilBrowser';
import { resetStore, subscribeRefetch, unsubscribeRefetch } from './client';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import { Worklist } from './worklist';

const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '64px',
    },
    refreshToolbar: {
      position: 'absolute',
      bottom: '16px',
      right: '16px',
      zIndex: 2000,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
  });

interface AppProps {
  userId: string | null;
}

interface AppState {
  open: boolean;
  loadingUser: boolean;
  userData: QLoggedUser_viewer | null;
  needsRefresh: boolean;
}

function getUserRoutes(profile: UserProfile | null): RouteProps[] {
  if (profile === UserProfile.ADVISOR) {
    return advisorRoutes;
  } else if (profile === UserProfile.TRANSCRIPTOR) {
    return transcriptorRoutes;
  } else {
    return [];
  }
}

const RefreshIconButton = () => {
  const [needsRefresh, setNeedsRefresh] = React.useState(false);
  React.useEffect(() => {
    function onRefetch() {
      setNeedsRefresh(false);
    }
    subscribeRefetch(onRefetch);
    return () => {
      unsubscribeRefetch(onRefetch);
    };
  });
  const onServerChanged = () => {
    setNeedsRefresh(true);
  };
  return (
    <React.Fragment>
      <WatchServerUpdates onServerChanged={onServerChanged} />

      <IconButton
        color="inherit"
        onClick={() => resetStore()}
      >
        {needsRefresh ?
          <RefreshIcon /> :
          <AutorenewIcon />
        }
      </IconButton>
    </React.Fragment>
  );

};
class App extends React.Component<AppProps & WithStyles<typeof styles>, AppState> {

  state = {
    open: true,
    loadingUser: true,
    userData: null,
  } as AppState;

  componentDidMount = async () => {
    const rememberOpen = window.localStorage.getItem('open');
    this.setState({ open: rememberOpen === null || rememberOpen === 'true' });
    if (this.props.userId) {
      const userData = await getUserProfile();
      userInfo.isAdvisor = userData?.profile === UserProfile.ADVISOR;
      this.setState({ loadingUser: false, userData });
    } else {
      this.setState({ loadingUser: false });
    }
  }

  handleDrawerOpen = () => {
    window.localStorage.setItem('open', 'true');
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    window.localStorage.setItem('open', 'false');
    this.setState({ open: false });
  };
  render() {
    const classes = this.props.classes;
    if (this.state.loadingUser) {
      return (<Loading />);
    }

    const profile = this.state.userData ? this.state.userData.profile : null;
    if (!profile) {
      return (
        <Grid
          container
          style={{ flexGrow: 1, height: '100vh' }}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent style={{ alignContent: 'center' }}>
                <Typography gutterBottom align="center">Acceso denegado</Typography>
                {/* Helper to know userId to create new users */}
                <div style={{ display: 'none' }}>{this.props.userId}</div>
              </CardContent>
              <CardActions style={{ justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={logout}
                >
                  Salir
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid >
      );
    }

    return (
      <React.Fragment>
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <>
                  <Switch>
                    <Route path="/company/:id" component={CompanyTitle} />
                    <Route children={() => { document.title = 'Abaq'; return (<span>Abaq</span>); }} />
                  </Switch>
                </>
              </Typography>
              <RefreshIconButton />
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Sidebar profile={profile} />
          </Drawer>
          <Box paddingTop={"72px"} overflow="auto" flexGrow={1} height="100vh">
            {
              getUserRoutes(profile).map((route, index) => (
                <Route
                  key={profile + '_' + index}
                  {...route}
                />
              ))
            }
          </Box>
        </div>
        <Notifications />
        <Worklist />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);