
import { LoadingComponent } from './widgets/loading';
import LoadableComponent from '@loadable/component';
import Doc from './pages/doc/doc'; // doesn't work with code split...
import { RouteProps } from 'react-router-dom';

export const advisorRoutes: RouteProps[] = [
  {
    path: '/',
    exact: true,
    component: LoadableComponent(() => import('./pages/allTasks'), { fallback: LoadingComponent })
  },
  {
    path: '/user',
    exact: true,
    component: LoadableComponent(() => import('./pages/users'), { fallback: LoadingComponent })
  },
  {
    path: '/reports',
    exact: true,
    component: LoadableComponent(() => import('./pages/allReports'), { fallback: LoadingComponent })
  },
  {
    path: '/user/:userId',
    exact: true,
    component: LoadableComponent(() => import('./pages/user'), { fallback: LoadingComponent })
  },
  {
    path: '/company',
    exact: true,
    component: LoadableComponent(() => import('./pages/companies'), { fallback: LoadingComponent })
  },
  {
    path: '/notifications',
    exact: true,
    component: LoadableComponent(() => import('./pages/allNotifications'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company',
    exact: true,
    component: LoadableComponent(() => import('./pages/dashboard'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/documents',
    exact: true,
    component: LoadableComponent(() => import('./pages/docs'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/ai',
    exact: true,
    component: LoadableComponent(() => import('./pages/ai'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/reports',
    exact: true,
    component: LoadableComponent(() => import('./pages/reports'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/settings',
    exact: true,
    component: LoadableComponent(() => import('./pages/settings'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/payments',
    exact: true,
    component: LoadableComponent(() => import('./pages/payments'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/notifications',
    exact: true,
    component: LoadableComponent(() => import('./pages/notifications'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/drive',
    exact: true,
    component: LoadableComponent(() => import('./pages/drive'), { fallback: LoadingComponent })
  },
  {
    path: '/company/:company/documents/:doc',
    exact: true,
    component: Doc
  },
  {
    path: '/pricing',
    exact: true,
    component: LoadableComponent(() => import('./pages/pricing'), { fallback: LoadingComponent })
  },
  {
    path: '/extractions',
    exact: true,
    component: LoadableComponent(() => import('./pages/extractions'), { fallback: LoadingComponent })
  },
  {
    path: '/ai',
    exact: true,
    component: LoadableComponent(() => import('./pages/ai'), { fallback: LoadingComponent })
  },

  {
    path: '/extractions/:doc/:mode(single|multi)/:ai(ai)?',
    exact: true,
    component: LoadableComponent(
      () => import('./pages/extraction/extractionDialog'),
      { fallback: LoadingComponent }
    )
  },
];

export const transcriptorRoutes: RouteProps[] = [
  {
    path: '/',
    exact: true,
    component: LoadableComponent(() => import('./pages/transcriptorHome'), { fallback: LoadingComponent })
  },
  {
    path: '/extractions/:doc/:mode(multi)/:ai(ai)?',
    exact: true,
    component: LoadableComponent(
      () => import('./pages/extraction/extractionDialog'),
      { fallback: LoadingComponent }
    )
  },
];
