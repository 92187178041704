// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const codeRE = /^[0-9A-Z/]+$/;
const spaceRE = /\s/g;
const forbiddenInCodeRE = /[^0-9A-Z/]/g;

export const isValidCode3 = (s: string): boolean => s.match(codeRE) != null;
export const parseCode3 = (s: string): string => {
  const code = s.toUpperCase().replace(spaceRE, '').replace(forbiddenInCodeRE, '/'); //
  return code;
};
