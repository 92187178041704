import * as React from 'react';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Subscription } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { Theme, Snackbar } from '@mui/material';
import { QSubscription } from './gen/QSubscription';
import { withRouter, RouteComponentProps } from 'react-router';
import { getCompanyName } from './lib/utilBrowser';

const QUERY = gql`
subscription QSubscription {
  realtimeUpdates {
    company,
    message
  }
}
`;

const styles = (theme: Theme) =>
  createStyles({
    companyName: {
      paddingRight: '8px',
    }
  });

interface MessageInfo {
  key: number;
  message: string;
  company?: string;
  companyName?: string;
}

let mountedNotifications: NotificationsNoStyle | null = null;
export const notificationsClient = {
  addMessage: (message: string) => {
    if (mountedNotifications) {
      mountedNotifications.addExternalMessage(message);
    }
  },
};

export interface NotificationProps extends RouteComponentProps {
  onServerChange?: () => Promise<void>;
}

class NotificationsNoStyle extends React.Component<NotificationProps & WithStyles<typeof styles>> {
  state: { messageInfo: MessageInfo | null } = {
    messageInfo: null
  };
  componentDidMount = () => {
    mountedNotifications = this;
  }
  addExternalMessage = (message: string) => {
    this.setState({ messageInfo: { message, key: new Date().getTime() } });
  }
  handleClose = () => {
    this.setState({ messageInfo: null });
  }
  render = () => (
    <div>
      <Subscription<QSubscription>
        subscription={QUERY}
        onSubscriptionData={async ({ client, subscriptionData }) => {
          if (subscriptionData.data && subscriptionData.data.realtimeUpdates) {
            const messageInfo = {
              ...subscriptionData.data.realtimeUpdates,
              key: new Date().getTime(),
              companyName: await getCompanyName(subscriptionData.data.realtimeUpdates.company)
            };
            this.setState({ messageInfo });
          }
          if (this.props.onServerChange) {
            this.props.onServerChange();
          }
        }}
      />
      <Snackbar
        key={this.state.messageInfo?.key || undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.messageInfo != null}
        autoHideDuration={500}
        onClose={this.handleClose}
        message={
          <React.Fragment>
            {
              this.state.messageInfo?.companyName ? (
                <React.Fragment>
                  <span
                    className={this.props.classes.companyName}
                  >
                    {this.state.messageInfo.companyName}.
                  </span>
                </React.Fragment>
              ) : null
            }
            {this.state.messageInfo?.message || null}
          </React.Fragment>
        }
      />
    </div>
  )
}
export const Notifications = withStyles(styles)(withRouter(NotificationsNoStyle));

interface Props {
  onServerChanged: (company?: string) => void;
}
export class WatchServerUpdates extends React.Component<Props> {
  render = () => (
    <Subscription<QSubscription>
      subscription={QUERY}
      onSubscriptionData={async ({ client, subscriptionData }) => {
        this.props.onServerChanged(subscriptionData.data?.realtimeUpdates.company);
      }}
    />
  )
}