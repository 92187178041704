import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { UserProfile } from '../gen/globalTypes';
import { client as apolloClient } from '../client';
import { QCompanyNameVariables, QCompanyName } from '../gen/QCompanyName';
import { ApolloQueryResult } from '@apollo/client';
import gql from 'graphql-tag';
import { QLoggedUser, QLoggedUser_viewer } from '../gen/QLoggedUser';
import { createBrowserHistory } from 'history';
import NiceModal, { muiDialogV5, useModal } from '@ebay/nice-modal-react';

export interface AlertProps {
  body: string | React.ReactNode;
  title?: string;
  ok?: string;
}

const AlertDialog = NiceModal.create((props: AlertProps) => {
  const modal = useModal();
  const title = props.title !== undefined ? props.title : 'Abaq';
  const ok = React.useCallback(() => { modal.hide(); }, [modal]);
  return (
    <Dialog {...muiDialogV5(modal)} onClose={() => { modal.hide() }}>
      {
        (() => {
          if (title.length > 0) {
            return (
              <DialogTitle>{title}</DialogTitle>
            );
          }
          return null;
        })()
      }
      <DialogContent>
        <DialogContentText>{props.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ok}>{props.ok || 'Aceptar'}</Button>
      </DialogActions>
    </Dialog >
  )
});

export async function Alert(props: AlertProps): Promise<void> {
  return await NiceModal.show(AlertDialog, props);
}

export interface ConfirmProps {
  body: string | React.ReactNode;
  title?: string;
  cancel?: string;
  confirm?: string;
}

const MuiConfirm = NiceModal.create((props: ConfirmProps) => {
  const modal = useModal();
  const ok = React.useCallback(() => { modal.resolve(true); modal.hide() }, [modal]);
  const nok = React.useCallback(() => { modal.resolve(false); modal.hide() }, [modal]);
  // Allow to pass empty title ('') VS default one
  const title = props.title !== undefined ? props.title : '¿Estás seguro?';
  return (
    <Dialog {...muiDialogV5(modal)} onClose={nok}>
      {
        (() => {
          if (title.length > 0) {
            return (
              <DialogTitle>{title}</DialogTitle>
            );
          }
          return null;
        })()
      }
      <DialogContent>
        <DialogContentText>{props.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={nok}>{props.cancel || 'Cancelar'}</Button>
        <Button onClick={ok} autoFocus>{props.confirm || 'Continuar'}</Button>
      </DialogActions>
    </Dialog>
  );
});

export async function Confirm(props: ConfirmProps): Promise<boolean> {
  return await NiceModal.show(MuiConfirm, props);
}

export function formatUserProfile(userProfile: UserProfile): string {
  switch (userProfile) {
    case UserProfile.ADVISOR: {
      return 'Asesor';
    }
    case UserProfile.TRANSCRIPTOR: {
      return 'Transcriptor';
    }
    case UserProfile.USER: {
      return 'Usuario';
    }
    default: {
      return userProfile;
    }
  }
}

export async function getCompanyName(companyId: string): Promise<string> {
  const result = await apolloClient.query<QCompanyName, QCompanyNameVariables>({
    query: gql`
      query QCompanyName($company: ID!) {
        company(id: $company) {
          id
          name,
          taxId,
        }
      }
    `,
    variables: { company: companyId }
  });
  if (result.data.company) {
    return result.data.company.name || 'Empresa ' + result.data.company.taxId;
  }
  return '';
}

export async function getUserProfile(): Promise<QLoggedUser_viewer | null> {
  return await apolloClient.query<QLoggedUser>({
    query: gql`
      query QLoggedUser {
        viewer {
          id, profile, name, email
        }
      }
    `
  }).then((result: ApolloQueryResult<QLoggedUser>) => {
    return result.data.viewer;
  });
}

export const history = createBrowserHistory();

export function navigate(args: { pathname: string, event?: React.MouseEvent }) {
  if (args.event?.ctrlKey || args.event?.metaKey) {
    window.open(args.pathname, '_blank');
    args.event.preventDefault();
  } else {
    history.push(args.pathname);
  }
}

// hack to globally store if user is advisor
export const userInfo = { isAdvisor: false };
