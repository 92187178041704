'use strict'; // code generated by pbf v3.2.0

// Any ========================================

var Any = exports.Any = {};

Any.read = function (pbf, end) {
    return pbf.readFields(Any._readField, {type_url: "", value: null}, end);
};
Any._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.type_url = pbf.readString();
    else if (tag === 2) obj.value = pbf.readBytes();
};

// Status ========================================

var Status = exports.Status = {};

Status.read = function (pbf, end) {
    return pbf.readFields(Status._readField, {code: 0, message: "", details: []}, end);
};
Status._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.code = pbf.readVarint(true);
    else if (tag === 2) obj.message = pbf.readString();
    else if (tag === 3) obj.details.push(Any.read(pbf, pbf.readVarint() + pbf.pos));
};

// AnnotateImageResponse ========================================

var AnnotateImageResponse = exports.AnnotateImageResponse = {};

AnnotateImageResponse.read = function (pbf, end) {
    return pbf.readFields(AnnotateImageResponse._readField, {full_text_annotation: null, error: null}, end);
};
AnnotateImageResponse._readField = function (tag, obj, pbf) {
    if (tag === 12) obj.full_text_annotation = TextAnnotation.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 9) obj.error = Status.read(pbf, pbf.readVarint() + pbf.pos);
};

// Vertex ========================================

var Vertex = exports.Vertex = {};

Vertex.read = function (pbf, end) {
    return pbf.readFields(Vertex._readField, {x: 0, y: 0}, end);
};
Vertex._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.x = pbf.readVarint(true);
    else if (tag === 2) obj.y = pbf.readVarint(true);
};

// NormalizedVertex ========================================

var NormalizedVertex = exports.NormalizedVertex = {};

NormalizedVertex.read = function (pbf, end) {
    return pbf.readFields(NormalizedVertex._readField, {x: 0, y: 0}, end);
};
NormalizedVertex._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.x = pbf.readFloat();
    else if (tag === 2) obj.y = pbf.readFloat();
};

// BoundingPoly ========================================

var BoundingPoly = exports.BoundingPoly = {};

BoundingPoly.read = function (pbf, end) {
    return pbf.readFields(BoundingPoly._readField, {vertices: [], normalized_vertices: []}, end);
};
BoundingPoly._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.vertices.push(Vertex.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 2) obj.normalized_vertices.push(NormalizedVertex.read(pbf, pbf.readVarint() + pbf.pos));
};

// Position ========================================

var Position = exports.Position = {};

Position.read = function (pbf, end) {
    return pbf.readFields(Position._readField, {x: 0, y: 0, z: 0}, end);
};
Position._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.x = pbf.readFloat();
    else if (tag === 2) obj.y = pbf.readFloat();
    else if (tag === 3) obj.z = pbf.readFloat();
};

// TextAnnotation ========================================

var TextAnnotation = exports.TextAnnotation = {};

TextAnnotation.read = function (pbf, end) {
    return pbf.readFields(TextAnnotation._readField, {pages: [], text: ""}, end);
};
TextAnnotation._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.pages.push(Page.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 2) obj.text = pbf.readString();
};

// TextAnnotation.DetectedLanguage ========================================

TextAnnotation.DetectedLanguage = {};

TextAnnotation.DetectedLanguage.read = function (pbf, end) {
    return pbf.readFields(TextAnnotation.DetectedLanguage._readField, {language_code: "", confidence: 0}, end);
};
TextAnnotation.DetectedLanguage._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.language_code = pbf.readString();
    else if (tag === 2) obj.confidence = pbf.readFloat();
};

// TextAnnotation.DetectedBreak ========================================

TextAnnotation.DetectedBreak = {};

TextAnnotation.DetectedBreak.read = function (pbf, end) {
    return pbf.readFields(TextAnnotation.DetectedBreak._readField, {type: 0, is_prefix: false}, end);
};
TextAnnotation.DetectedBreak._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.type = pbf.readVarint();
    else if (tag === 2) obj.is_prefix = pbf.readBoolean();
};

TextAnnotation.DetectedBreak.BreakType = {
    "UNKNOWN": {
        "value": 0,
        "options": {}
    },
    "SPACE": {
        "value": 1,
        "options": {}
    },
    "SURE_SPACE": {
        "value": 2,
        "options": {}
    },
    "EOL_SURE_SPACE": {
        "value": 3,
        "options": {}
    },
    "HYPHEN": {
        "value": 4,
        "options": {}
    },
    "LINE_BREAK": {
        "value": 5,
        "options": {}
    }
};

// TextAnnotation.TextProperty ========================================

TextAnnotation.TextProperty = {};

TextAnnotation.TextProperty.read = function (pbf, end) {
    return pbf.readFields(TextAnnotation.TextProperty._readField, {detected_languages: [], detected_break: null}, end);
};
TextAnnotation.TextProperty._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.detected_languages.push(TextAnnotation.DetectedLanguage.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 2) obj.detected_break = TextAnnotation.DetectedBreak.read(pbf, pbf.readVarint() + pbf.pos);
};

// Page ========================================

var Page = exports.Page = {};

Page.read = function (pbf, end) {
    return pbf.readFields(Page._readField, {property: null, width: 0, height: 0, blocks: [], confidence: 0}, end);
};
Page._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.property = TextAnnotation.TextProperty.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.width = pbf.readVarint(true);
    else if (tag === 3) obj.height = pbf.readVarint(true);
    else if (tag === 4) obj.blocks.push(Block.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 5) obj.confidence = pbf.readFloat();
};

// Block ========================================

var Block = exports.Block = {};

Block.read = function (pbf, end) {
    return pbf.readFields(Block._readField, {property: null, bounding_box: null, paragraphs: [], block_type: 0, confidence: 0}, end);
};
Block._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.property = TextAnnotation.TextProperty.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.bounding_box = BoundingPoly.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 3) obj.paragraphs.push(Paragraph.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 4) obj.block_type = pbf.readVarint();
    else if (tag === 5) obj.confidence = pbf.readFloat();
};

Block.BlockType = {
    "UNKNOWN": {
        "value": 0,
        "options": {}
    },
    "TEXT": {
        "value": 1,
        "options": {}
    },
    "TABLE": {
        "value": 2,
        "options": {}
    },
    "PICTURE": {
        "value": 3,
        "options": {}
    },
    "RULER": {
        "value": 4,
        "options": {}
    },
    "BARCODE": {
        "value": 5,
        "options": {}
    }
};

// Paragraph ========================================

var Paragraph = exports.Paragraph = {};

Paragraph.read = function (pbf, end) {
    return pbf.readFields(Paragraph._readField, {property: null, bounding_box: null, words: [], confidence: 0}, end);
};
Paragraph._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.property = TextAnnotation.TextProperty.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.bounding_box = BoundingPoly.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 3) obj.words.push(Word.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 4) obj.confidence = pbf.readFloat();
};

// Word ========================================

var Word = exports.Word = {};

Word.read = function (pbf, end) {
    return pbf.readFields(Word._readField, {property: null, bounding_box: null, symbols: [], confidence: 0}, end);
};
Word._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.property = TextAnnotation.TextProperty.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.bounding_box = BoundingPoly.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 3) obj.symbols.push(Symbol.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 4) obj.confidence = pbf.readFloat();
};

// Symbol ========================================

var Symbol = exports.Symbol = {};

Symbol.read = function (pbf, end) {
    return pbf.readFields(Symbol._readField, {property: null, bounding_box: null, text: "", confidence: 0}, end);
};
Symbol._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.property = TextAnnotation.TextProperty.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.bounding_box = BoundingPoly.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 3) obj.text = pbf.readString();
    else if (tag === 4) obj.confidence = pbf.readFloat();
};
