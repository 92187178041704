import moment from 'moment';
import { UserProfile } from '../gen/globalTypes';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import * as accounting from 'accounting';
import { Decimal } from 'decimal.js';
import _ from 'lodash';

export const ACTIVE_ROW_BGCOLOR = 'rgba(0,0,0,0.14)';

export function defaultPageSize(): number { return +(localStorage.getItem('pageSize') ?? 10); }

export function formatISODate(date: string): string {
  return moment(date).format('YYYY-MM-DD');
}

export function formatDate(isoDate: string): string {
  return moment(isoDate).format('DD-MM-YYYY');
}

export function formatDateTime(isoDateTime: string): string {
  return moment(isoDateTime).format('DD-MM-YYYY HH:mm:ss');
}

export function formatUserProfile(userProfile: UserProfile): string {
  switch (userProfile) {
    case UserProfile.ADVISOR: {
      return 'Asesor';
    }
    case UserProfile.TRANSCRIPTOR: {
      return 'Transcriptor';
    }
    case UserProfile.USER: {
      return 'Usuario';
    }
    default: {
      return userProfile;
    }
  }
}

export function sqlToRows<T>(jsonResult: { columns: unknown[], rows: unknown[][] }, add?: Record<string, any>): Array<T> {
  const rows = jsonResult.rows.map((r) => {
    let index = 0;
    const res: Record<string, any> = {};
    for (const c of r) {
      res[jsonResult.columns[index] as string] = c;
      index += 1;
    }
    _.extend(res, add);
    return res as T;
  });
  return rows;
}

export function renderAmount(amount: number) {
  return (amount / 100.0).toFixed(2);
}

export function renderPercent(amount: number) {
  return (amount * 100.0).toFixed(2);
}

export function formatDateLikeMobile(date: string): string {
  let result = '';
  const d = dayjs(date).locale('es');
  if (d.year === dayjs().year) {
    result = d.format('D MMM');
  } else {
    result = d.format('D MMM YYYY');
  }
  return result.toUpperCase();
}

export function round(value: number, ndec = 2): number {
  // why 6? Because in classic was working that way, I really don't know the minimun needed (blush)
  return new Decimal(value.toFixed(ndec + 6)).toDP(ndec).toNumber();
}

const DECIMAL_SEPARATOR = ',';
const THOUSAND_SEPARATOR = '.';
export function formatCurrencyCentsLikeMobile(cents: number, currency: string, ndec = 2): string {
  return accounting.formatMoney(round(cents / 100, ndec), {
    symbol: currency === 'EUR' ? '€' : currency,
    format: '%v%s',
    decimal: DECIMAL_SEPARATOR,
    thousand: THOUSAND_SEPARATOR,
    precision: ndec
  });
}

export function formatNumber(amount: number, ndec = 2): string {
  return accounting.formatNumber(round(amount, ndec), {
    precision: ndec,
    thousand: '.',
    decimal: ',',
  });
}

export function sanitizeNumberInputText(value: string): string {
  let result = value
    .replace(/[^0-9,.-]/g, '')
    .replace(/\./g, ',');
  const tokens = value.split(',');
  if (tokens.length > 2) {
    result = '';
    tokens.forEach((s, i) => {
      if (i === tokens.length - 2) {
        result += s;
      }
      result += s;
    });
  }
  return result;
}

export function parseNumber(value: string, ndec = 2): number {
  return round(accounting.unformat(value, DECIMAL_SEPARATOR), ndec);
}

const TWO_DECIMAL_SUFFIX = DECIMAL_SEPARATOR + '00';
export function formatPercentage(amount: number, ndec = 2): string {
  const s = formatNumber(100 * amount, ndec);
  if (_.endsWith(s, TWO_DECIMAL_SUFFIX)) {
    return s.substring(0, s.length - TWO_DECIMAL_SUFFIX.length) + '%';
  } else {
    return s + '%';
  }
}

export type FilterBoolean = 'checked' | 'unchecked';

export function getPreviousQuarter(date: dayjs.Dayjs): { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs } {
  const startDate = date.startOf('month').subtract(3 + date.get('month') % 3, 'month');
  const endDate = startDate.add(3, 'month').subtract(1, 'day');
  return { startDate, endDate };
}

export type DateRange = { startDate: string, endDate: string };
export type FiscalPeriod = '1T' | '2T' | '3T' | '4T';

export function getDateRangeForFiscalPeriod(year: number, periodo: FiscalPeriod): DateRange {
  switch (periodo) {
    case '1T': {
      return { startDate: `${year}-01-01`, endDate: `${year}-03-31` };
    }
    case '2T': {
      return { startDate: `${year}-04-01`, endDate: `${year}-06-30` };
    }
    case '3T': {
      return { startDate: `${year}-07-01`, endDate: `${year}-09-30` };
    }
    case '4T': {
      return { startDate: `${year}-10-01`, endDate: `${year}-12-31` };
    }
    default:
      throw new Error('Invalid period: ' + periodo);
  }
}

export const delay = (ms: number) => new Promise((resolve: any) => setTimeout(resolve, ms));
