// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

// Do not change these requires to imports, they must be require to work both in abaq-server and abaq-front
const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
// @ts-ignore
const chrono = require('chrono-node');
import _ = require('lodash');

dayjs.extend(customParseFormat);

const dateRE = /^\d{4}\-\d{2}-\d{2}$/;
export const isValidDate = (s: string): boolean => {
  if (s.match(dateRE) == null) {
    return false;
  }
  return dayjs(s, { format: 'YYYY-MM-DD' }).format('YYYY-MM-DD') === s;
};

export const parseDateWithoutDefault = (s: string): string | undefined => {
  let parsed = chrono.es.strict.parseDate(s);
  if (!parsed) {
    parsed = chrono.en.strict.parseDate(s);
    if (!parsed) {
      parsed = parse_custom_dates(s);
      if (!parsed) {
        return undefined;
      }
    }
  }
  return dayjs(parsed).format('YYYY-MM-DD');
};

export const parseDate = (s: string): string => {
  const result = parseDateWithoutDefault(s);
  return result === undefined ? s : result;
};

const DATE_FORMAT_DIEGO_LOPEZ = /(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|setiembre|octubre|noviembre|diciembre) *([0-9]+), *(20[0-9][0-9])/i;

function parse_mes(mes: string): string {
  switch (mes.toLocaleLowerCase()) {
    case 'enero': return '01';
    case 'febrero': return '02';
    case 'marzo': return '03';
    case 'abril': return '04';
    case 'mayo': return '05';
    case 'junio': return '06';
    case 'julio': return '07';
    case 'agosto': return '08';
    case 'septiembre': return '09';
    case 'setiembre': return '09';
    case 'octubre': return '10';
    case 'noviembre': return '11';
    case 'diciembre': return '12';
    default: throw new Error();
  }
}
function parse_date_diego_lopez(s: string): Date | null {
  const matches = s.match(DATE_FORMAT_DIEGO_LOPEZ);
  if (matches) {
    const mes = matches[1];
    const dia = matches[2];
    const year = matches[3];
    return dayjs(`${year}-${parse_mes(mes)}-${_.padStart(dia, 2, '0')}`).toDate();
  }
  return null;
}
function parse_custom_dates(s: string): Date | null {
  return parse_date_diego_lopez(s);
}