// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const countryCodes: { [s: string]: string } = require('./countryCodes.json');
const countryNames: { [s: string]: string } = require('./countryNames.json');

const collator = new Intl.Collator(undefined, { ignorePunctuation: true, usage: 'search', sensitivity: 'base' });
export const isValidCountry = (s: string): boolean => countryCodes[s] != null;

export const parseCountryWithoutDefault = (s: string): string | undefined => {
  for (const [key, value] of Object.entries(countryNames)) {
    if (collator.compare(s, key) === 0) {
      return value;
    }
  }
  return undefined;
};

export const parseCountry = (s: string): string => {
  const result = parseCountryWithoutDefault(s);
  return result === undefined ? s : result;
};
