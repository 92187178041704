import { ThemeOptions, createTheme } from '@mui/material/styles';
import { WS_URL } from './config';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: WS_URL.includes("localhost") ? '#00bcd4' : '#d00273',
    },
    secondary: {
      main: '#f50057',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiButton: {
      // styleOverrides: {
      //   colorInherit: {
      //     backgroundColor: "#eeeeee",
      //   },
      // },
      defaultProps: {
        color: "inherit",
      },
    },
  }
};

export const theme = createTheme(themeOptions)