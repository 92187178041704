import { Confirm } from './lib/utilBrowser';
import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SettingsIcon from '@mui/icons-material/Settings';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import CompaniesIcon from '@mui/icons-material/Domain';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ForumIcon from '@mui/icons-material/Forum';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EuroIcon from '@mui/icons-material/EuroSymbol';

import Divider from '@mui/material/Divider';
import { logout } from './auth';
import { Route, Link } from 'react-router-dom';
import { List } from '@mui/material';
import { UserProfile } from './gen/globalTypes';
import { Summarize } from '@mui/icons-material';

interface PropsType {
  icon: JSX.Element;
  title: string;
  route: string;
  exact?: boolean;
}
class MenuItemExt extends React.Component<PropsType, {}> {
  render() {
    return (
      <Route
        path={this.props.route}
        exact={this.props.exact}
        children={({ match, history }) => (
          // @ts-ignore
          <ListItem button selected={!!match} to={this.props.route} component={Link}>
            <ListItemIcon>
              {this.props.icon}
            </ListItemIcon>
            <ListItemText primary={this.props.title} />
          </ListItem>
        )}
      />
    );
  }
}

const LogoutOption = () => {
  return (
    <ListItem
      button
      onClick={async function () {
        if (await Confirm({ body: 'Vas a salir del programa. ¿Continuar?' })) {
          logout();
        }
      }}
    >
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Salir" />
    </ListItem>
  );
};

const DocumentationOption = () => {
  return (
    <ListItem button onClick={() => window.open('http://api.abaq.app/docs/', '_blank')}>
      <ListItemIcon>
        <LibraryBooksIcon />
      </ListItemIcon>
      <ListItemText primary="Documentación" />
    </ListItem>
  );
};

export class Sidebar extends React.Component<{ profile: UserProfile | null }> {
  render = () => {
    if (this.props.profile === UserProfile.ADVISOR) {
      return (
        <List style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <MenuItemExt icon={<AllInboxIcon />} title="Todas las tareas" route="/" exact />
          <MenuItemExt icon={<PhotoLibraryIcon />} title="Extracción" route="/extractions" exact />
          <MenuItemExt
            icon={<Summarize />}
            title="Informes"
            route={`/reports`}
          />
          <MenuItemExt icon={<PeopleIcon />} title="Usuarios" route="/user" exact />
          <MenuItemExt icon={<CompaniesIcon />} title="Empresas" route="/company" exact />
          <Divider />
          <Route
            path="/company/:id"
            render={({ match }) => {
              const id = match.params.id;
              return (
                <React.Fragment >
                  <ListSubheader inset>Empresa</ListSubheader>
                  <MenuItemExt
                    icon={<DashboardIcon />}
                    title="Dashboard"
                    route={`/company/${id}`}
                    exact
                  />
                  <MenuItemExt
                    icon={<BookIcon />}
                    title="Documentos"
                    route={`/company/${id}/documents`}
                  />
                  <MenuItemExt
                    icon={<AssignmentIcon />}
                    title="Informes"
                    route={`/company/${id}/reports`}
                  />
                  <MenuItemExt
                    icon={<SettingsIcon />}
                    title="Configuración"
                    route={`/company/${id}/settings`}
                  />
                  <MenuItemExt
                    icon={<MonetizationOnIcon />}
                    title="Pagos"
                    route={`/company/${id}/payments`}
                  />
                  <MenuItemExt
                    icon={<ForumIcon />}
                    title="Notificaciones"
                    route={`/company/${id}/notifications`}
                  />
                </React.Fragment>
              );
            }}
          />
          <Divider />
          <div style={{ flex: '1' }} />
          <Divider />
          <MenuItemExt icon={<EuroIcon />} title="Precios" route="/pricing" exact />
          <Divider />
          <DocumentationOption />
          <Divider />
          <LogoutOption />
        </List>
      );
    } else {
      return (
        <List style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <MenuItemExt icon={<HomeIcon />} title="Inicio" route="/" exact />
          <div style={{ flex: '1' }} />
          <LogoutOption />
        </List>
      );
    }
  }
}