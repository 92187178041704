import * as React from 'react';
import {
  Dialog, AppBar,
  Button, Toolbar, Typography, IconButton, Menu, MenuItem
} from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import MuiDialogContent from '@mui/material/DialogContent';

import { Theme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { Editor } from './editor';
import { ContextViewer } from './contextViewer';
import { Query, Mutation } from '@apollo/client/react/components';
import { MutationFunction } from '@apollo/client';
import { Loading } from '../../widgets/loading';
import { QSave, QSaveVariables } from '../../gen/QSave';
import { client as apolloClient, refetch } from '../../client';
import { Confirm } from '../../lib/utilBrowser';
import { onMutationError } from '../../lib/errorReporter';
import { MDeleteDoc, MDeleteDocVariables } from '../../gen/MDeleteDoc';
import { QGeneratedAccounting, QGeneratedAccountingVariables } from '../../gen/QGeneratedAccounting';
import { DocType, Extraction, ExtractionContentType } from '../../gen/globalTypes';
import { QDoc } from '../../gen/QDoc';
import { ExtractionEditor } from '../extraction/editor';
import { CompanyTitleById as CompanyTitle } from '../../widgets/companyTitle';
import clipboardCopy = require('clipboard-copy');
import { DELETE_DOC_MUTATION } from '../../graphql';
import { worklistGoNextIfExists } from '../../worklist';

const QUERY = gql`
  query QDoc($company: ID!, $doc: ID!) {
    company(id: $company) {
      id,
      doc(id: $doc) {
        id,
        accounting,
        docType, 
        deleted, 
        receivedDate,
        title,
        text,
        mimeType,
        thumbnailUrl,
        contentUrl, 
        extraction,
        imageOriginalPages,
        imagePages {
          imageUrl,
          visionUrl,
          width,
          height,
        }
      }
    }
  }
  `;

const SAVE_MUTATION = gql`
  mutation QSave($company: ID!, $id: ID!, $accounting: String!, $oldAccounting: String, $check: Boolean!) {
    updateAccounting(id: $id, company: $company, accounting: $accounting , oldAccounting: $oldAccounting, check: $check) {
      docId
      idx
      title {es}
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
      display: 'flex',
    },
    panel: {
      display: 'flex',
      flex: '1'
    },
    panelScroller: {
      minHeight: 0
    },
    rightPanel: {
      flexDirection: 'column',
    },
    masterDocumentPanel: {
      flexDirection: 'row-reverse',
    },
    wrapper: {
      paddingTop: '64px',
    },
    flex: {
      flex: 1,
    },
    rightToolbar: {
      flexDirection: 'row-reverse'
    }
  });

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    overflowY: 'hidden'
  },
}))(MuiDialogContent);

interface Props extends
  RouteComponentProps<{
    doc: string;
    company: string;
  }>,
  WithStyles<typeof styles> { }

async function getGeneratedAccounting(companyId: string, id: string): Promise<string> {
  const result = await apolloClient.query<QGeneratedAccounting, QGeneratedAccountingVariables>({
    query: gql`
    query QGeneratedAccounting($company: ID!, $id: ID!) {
      company(id: $company) {
        id
        accounting:generatedAccounting(id: $id)
      }
    }
    `,
    variables: { company: companyId, id },
    fetchPolicy: 'no-cache'
  });
  if (result.data.company) {
    return result.data.company.accounting;
  }
  return '';
}

interface DocState {
  moreVisible: boolean;
  moreAnchor?: HTMLElement;
}

class Doc extends React.Component<Props, DocState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      moreVisible: false,
    };
  }
  private editorRef = React.createRef<Editor>();
  canExitWithChanges = async () => {
    if (this.editorRef.current && this.editorRef.current.props.value !== this.editorRef.current.value()) {
      return await Confirm({
        body: 'El documento tiene cambios sin guardar, ¿quieres continuar y descartar los cambios?'
      });
    }
    return true;
  }
  handleClose = async () => {
    if (await this.canExitWithChanges()) {
      if (!worklistGoNextIfExists()) {
        this.props.history.goBack();
      }
    }
  }
  handleMoreButton = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ moreVisible: !this.state.moreVisible, moreAnchor: event.currentTarget });
  }
  reprocessDoc = async (docId: string) => {
    if (this.editorRef.current) {
      const accounting = await getGeneratedAccounting(this.props.match.params.company, docId);
      this.editorRef.current.replaceText(accounting);
    }
  }
  openExtraction = async (docId: string) => {
    if (await this.canExitWithChanges()) {
      this.props.history.push({
        pathname: `/extractions/${this.props.match.params.doc}/single`,
      });
    }
  }
  _getContent = () => {
    if (this.editorRef.current) {
      return this.editorRef.current.value();
    } else {
      return undefined;
    }
  }
  handleSave = async (saveHandler: MutationFunction<QSave, QSaveVariables>, serverAccounting: string) => {
    const accounting = this._getContent();
    if (accounting != null && serverAccounting !== accounting) {
      const errors = await saveHandler({
        variables: {
          company: this.props.match.params.company,
          id: this.props.match.params.doc,
          accounting,
          oldAccounting: serverAccounting,
          check: true
        }
      });
      if (errors.data?.updateAccounting && errors.data.updateAccounting.length > 0) {
        const doSave = await Confirm({
          body: (
            <span>
              Si guardas se generarán los siguientes errores
              <ul>
                {errors.data.updateAccounting.map((t, i) => <li key={i}>{t.title.es}</li>)}
              </ul>
            </span>
          )
        });
        if (doSave) {
          await saveHandler({
            variables: {
              company: this.props.match.params.company,
              id: this.props.match.params.doc,
              accounting,
              check: false
            }
          });
          refetch();
        }
      } else {
        refetch();
      }
    }
  }
  handleUpdateDeleted = async (
    updateHandler: MutationFunction<MDeleteDoc, MDeleteDocVariables>,
    id: string,
    deleted: boolean,
  ) => {
    const confirmDelete = await Confirm({
      body: deleted ?
        'Se borrará el documento de la contabilidad, ¿quieres continuar?' :
        'Se recuperará el documento y volverá a la contabilidad, ¿quieres continuar?'
    });
    if (confirmDelete) {
      let result = await updateHandler({
        variables: {
          company: this.props.match.params.company,
          id,
          deleted,
          check: true
        }
      });
      if (result?.data?.result.errors.length !== 0) {
        const ignoreErrors = await Confirm({
          body: (
            <span>
              Si continúas se generarán los siguientes errores
              <ul>
                {result?.data?.result.errors.map((t, i) => <li key={i}>{t.title.es}</li>)}
              </ul>
            </span>
          )
        });
        if (ignoreErrors) {
          result = await updateHandler({
            variables: {
              company: this.props.match.params.company,
              id,
              deleted,
              check: false
            }
          });
        }
      }
      if (result?.data?.result.deleted) {
        // When we are deleting the doc we close the document view
        this.handleClose();
      } else {
        // When we are deleting the doc we stay in this view and we just close the Menu
        this.setState({ moreVisible: false });
      }
    }
  }
  insertText = (text: string) => {
    if (this.editorRef.current) {
      this.editorRef.current.insertText(text);
    }
  }
  render = () => {
    const company = this.props.match.params.company;
    const doc = this.props.match.params.doc;
    const classes = this.props.classes;
    return (
      <Dialog
        fullScreen
        open={true}
        onClose={this.handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <Query<QDoc>
          query={QUERY}
          variables={{ doc, company }}
          fetchPolicy="no-cache"
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (<Loading />);
            }
            if (error || !data) {
              return (<div>Error {JSON.stringify(error) || 'no data'}</div>);
            }
            if (data.company == null) {
              return <div />;
            }
            if (data.company.doc == null) {
              return <div />;
            }
            const docData = data.company.doc;
            const extraction: Extraction = docData.extraction ?? {
              userTaskType: "MESSAGE",
              contentType: ExtractionContentType.OTHER_DOC,
              todo: '',
              userMessage: ''
            };
            const isMasterDocument = data.company.doc.docType === DocType.STANDALONE;
            const allowToCalculateAccountingAgain = data.company.doc.docType === DocType.IMAGE;
            const allowToExtractAgain = data.company.doc.docType === DocType.IMAGE;
            const isDeleted = data.company.doc.deleted;

            const moreButtonVisible = allowToCalculateAccountingAgain || !isMasterDocument || allowToExtractAgain;

            return (
              <React.Fragment>
                <AppBar>
                  <Toolbar>
                    <Button size="small" color="inherit" onClick={this.handleClose}>
                      <ArrowBackIcon />
                    </Button>
                    <Typography variant="h6" color="inherit" className={classes.flex}>
                      <CompanyTitle id={company} />
                    </Typography>
                    {
                      (() => {
                        if (moreButtonVisible) {
                          return (
                            <React.Fragment>
                              <IconButton color="inherit" onClick={this.handleMoreButton}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                open={this.state.moreVisible}
                                onClose={() => { this.setState({ moreVisible: false }); }}
                                anchorEl={this.state.moreAnchor}
                              >
                                {
                                  allowToCalculateAccountingAgain ? (
                                    <MenuItem onClick={() => { this.reprocessDoc(doc); }}>
                                      Volver a calcular
                                    </MenuItem>
                                  ) : null
                                }
                                {
                                  allowToExtractAgain ? (
                                    <MenuItem onClick={() => { this.openExtraction(doc); }}>
                                      Abrir extracción
                                    </MenuItem>
                                  ) : null
                                }
                                {
                                  !isMasterDocument ? (
                                    <Mutation<MDeleteDoc, MDeleteDocVariables>
                                      mutation={DELETE_DOC_MUTATION}
                                      onError={(e) => { onMutationError(e); }}
                                    >
                                      {(updateDeletedFn, { loading: updatingDeleted }) => (
                                        <MenuItem
                                          disabled={updatingDeleted}
                                          onClick={() => {
                                            this.handleUpdateDeleted(updateDeletedFn, doc, !isDeleted);
                                          }}
                                        >
                                          {isDeleted ? 'Recuperar' : 'Borrar'}
                                        </MenuItem>
                                      )}
                                    </Mutation>
                                  ) : null
                                }
                              </Menu>
                            </React.Fragment>
                          );
                        }
                        return null;
                      })()
                    }
                    {
                      data.company.doc.contentUrl ?
                        <Button
                          size="small"
                          color="inherit"
                          href={data.company.doc.contentUrl}
                          target="_blank"
                        >
                          VER ORIGINAL
                        </Button>
                        : undefined
                    }

                    <Mutation<QSave, QSaveVariables>
                      mutation={SAVE_MUTATION}
                      onError={(e) => { onMutationError(e); }}
                      fetchPolicy="no-cache"
                    >
                      {(saveFn, { loading: saving }) => (
                        <Button
                          size="small"
                          color="inherit"
                          disabled={saving}
                          onClick={() => this.handleSave(saveFn, data!.company!.doc!.accounting)}
                        >
                          Guardar
                        </Button>
                      )}

                    </Mutation>
                    <Button size="small" color="inherit" onClick={this.handleClose}>
                      Cerrar
                    </Button>
                  </Toolbar>

                </AppBar>
                <DialogContent className={classNames(classes.wrapper, classes.panel, classes.panelScroller)}>
                  {
                    docData.imagePages.length > 0 ?
                      docData.docType === 'IMAGE' ?
                        <div className={classes.panel}>
                          <ExtractionEditor
                            docId={doc}
                            companyId={company}
                            companyName=""
                            taxId=""
                            editor={false}
                            imageOriginalPages={docData.imageOriginalPages}
                            imagePages={docData.imagePages}
                            onSave={(ex) => null}
                            onSelected={(txt) => { clipboardCopy(txt); }}
                            close={() => {
                              this.props.history.push(`/company/${company}/extractions/${doc}`);
                            }}
                            initialValue={extraction}
                            receivedDate={docData.receivedDate}
                            masterAccounting=""
                            isAI={false}
                          />
                        </div> : (
                          docData.docType === 'DIGITAL' ?
                            <DigitalViewer
                              imagePages={docData.imagePages}
                              imageOriginalPages={docData.imageOriginalPages}
                            />
                            : null
                        ) : null
                  }
                  <div
                    className={classNames(
                      classes.panel,
                      !isMasterDocument && classes.rightPanel,
                      isMasterDocument && classes.masterDocumentPanel,
                    )}>{/*tslint:disable-line: jsx-alignment*/}
                    <div className={classNames(classes.panel, classes.panelScroller)}>
                      <ContextViewer doc={doc} company={company} />
                    </div>
                    <div className={classes.panel}>
                      <Editor ref={this.editorRef} doc={doc} company={company} value={data.company.doc.accounting} />
                    </div>
                  </div>
                </DialogContent>
              </React.Fragment>
            );
          }}
        </Query>
      </Dialog>
    );
  }
}

const DigitalViewer = (props: { imagePages: Array<{ imageUrl: string }>, imageOriginalPages: number }) => {
  const [page, setPage] = React.useState(0);
  return (
    <div style={{ display: 'flex', flex: '1', position: 'relative', alignItems: 'flex-start' }}>
      <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
        <img src={props.imagePages[page].imageUrl} style={{ width: '100%', marginBottom: '40px' }} />
      </div>
      <div style={{ position: 'absolute', bottom: 10, width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          size="small"
          onClick={() => setPage(page === 0 ? 0 : page - 1)}
        >
          <NavigateBeforeIcon />
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          size="small"
        >
          {page + 1} / {props.imagePages.length}
          {
            props.imageOriginalPages > props.imagePages.length ?
              ('+' + String(props.imageOriginalPages - props.imagePages.length))
              : ''
          }
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          size="small"
          onClick={() => setPage(page === props.imagePages.length - 1 ? page : page + 1)}
        >
          <NavigateNextIcon />
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Doc);