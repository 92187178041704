// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const nameRE = /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ \d\-&]+$/u;
const forbiddenInNameRE = /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ \d\-&]/ug;
const doubleSpaceRE = /\s\s+/g;
export const isValidName = (s: string): boolean => {
  if (s.startsWith(' ') || s.endsWith(' ')) {
    return false;
  }
  return s.match(nameRE) != null && s.match(doubleSpaceRE) == null;
};
export const parseName = (s: string): string => {
  return s.trim().replace(forbiddenInNameRE, '').replace(doubleSpaceRE, ' ');
};
