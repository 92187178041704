{
  "AD": "AD",
  "AE": "AE",
  "AF": "AF",
  "AG": "AG",
  "AI": "AI",
  "AL": "AL",
  "AM": "AM",
  "AO": "AO",
  "AQ": "AQ",
  "AR": "AR",
  "AS": "AS",
  "AT": "AT",
  "AU": "AU",
  "AW": "AW",
  "AX": "AX",
  "AZ": "AZ",
  "Afganistán": "AF",
  "Afghanistan": "AF",
  "Aforika Borwa": "ZA",
  "Afrika Borwa": "ZA",
  "Afrika Dzonga": "ZA",
  "Afrika-Borwa": "ZA",
  "Afurika Tshipembe": "ZA",
  "Alandia": "AX",
  "Albania": "AL",
  "Alemania": "DE",
  "Algeria": "DZ",
  "American Samoa": "AS",
  "Andorra": "AD",
  "Angola": "AO",
  "Anguila": "AI",
  "Anguilla": "AI",
  "Antarctica": "AQ",
  "Antigua and Barbuda": "AG",
  "Antigua y Barbuda": "AG",
  "Antártida": "AQ",
  "Aotearoa": "NZ",
  "Arab Republic of Egypt": "EG",
  "Arabia Saudí": "SA",
  "Argelia": "DZ",
  "Argentina": "AR",
  "Argentine Republic": "AR",
  "Armenia": "AM",
  "Aruba": "AW",
  "Asociado de Puerto Rico": "PR",
  "Australia": "AU",
  "Austria": "AT",
  "Ayiti": "HT",
  "Azerbaijan": "AZ",
  "Azerbaiyán": "AZ",
  "Azərbaycan": "AZ",
  "Azərbaycan Respublikası": "AZ",
  "BA": "BA",
  "BB": "BB",
  "BD": "BD",
  "BE": "BE",
  "BF": "BF",
  "BG": "BG",
  "BH": "BH",
  "BI": "BI",
  "BJ": "BJ",
  "BL": "BL",
  "BM": "BM",
  "BN": "BN",
  "BO": "BO",
  "BQ": "BQ",
  "BR": "BR",
  "BS": "BS",
  "BT": "BT",
  "BV": "BV",
  "BW": "BW",
  "BY": "BY",
  "BZ": "BZ",
  "Bahamas": "BS",
  "Bahrain": "BH",
  "Bahrein": "BH",
  "Bailiwick of Guernsey": "GG",
  "Bailiwick of Jersey": "JE",
  "Bailliage de Guernesey": "GG",
  "Bailliage de Jersey": "JE",
  "Bailliage dé Jèrri": "JE",
  "Bailía de Guernsey": "GG",
  "Bailía de Jersey": "JE",
  "Bangladesh": "BD",
  "Barbados": "BB",
  "Belarus": "BY",
  "Belau": "PW",
  "Belgien": "BE",
  "Belgique": "BE",
  "Belgium": "BE",
  "België": "BE",
  "Belice": "BZ",
  "Belize": "BZ",
  "Beluu er a Belau": "PW",
  "Benin": "BJ",
  "Benín": "BJ",
  "Bermuda": "BM",
  "Bermudas": "BM",
  "Bhutan": "BT",
  "Bielorrusia": "BY",
  "Bolivarian Republic of Venezuela": "VE",
  "Bolivia": "BO",
  "Bonaire, San Eustaquio y Saba": "BQ",
  "Bonaire, Sint Eustatius and Saba": "BQ",
  "Bonaire, Sint Eustatius en Saba": "BQ",
  "Boneiru, Sint Eustatius y Saba": "BQ",
  "Bosna i Hercegovina": "BA",
  "Bosnia and Herzegovina": "BA",
  "Bosnia y Herzegovina": "BA",
  "Botswana": "BW",
  "Bouvet Island": "BV",
  "Bouvetøya": "BV",
  "Brasil": "BR",
  "Brazil": "BR",
  "British Indian Ocean Territory": "IO",
  "British Virgin Islands": "VG",
  "Brunei": "BN",
  "Bulgaria": "BG",
  "Buliwya": "BO",
  "Buliwya Mamallaqta": "BO",
  "Bundesrepublik Deutschland": "DE",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Bután": "BT",
  "Bélgica": "BE",
  "Bénin": "BJ",
  "Bêafrîka": "CF",
  "CA": "CA",
  "CC": "CC",
  "CD": "CD",
  "CF": "CF",
  "CG": "CG",
  "CH": "CH",
  "CI": "CI",
  "CK": "CK",
  "CL": "CL",
  "CM": "CM",
  "CN": "CN",
  "CO": "CO",
  "CR": "CR",
  "CU": "CU",
  "CV": "CV",
  "CW": "CW",
  "CX": "CX",
  "CY": "CY",
  "CZ": "CZ",
  "Cabo Verde": "CV",
  "Cambodia": "KH",
  "Camboya": "KH",
  "Cameroon": "CM",
  "Cameroun": "CM",
  "Camerún": "CM",
  "Canada": "CA",
  "Canadá": "CA",
  "Cape Verde": "CV",
  "Caribbean Netherlands": "BQ",
  "Caribe Neerlandés": "BQ",
  "Caribisch Nederland": "BQ",
  "Catar": "QA",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  "Chad": "TD",
  "Chalo cha Malawi, Dziko la Malaŵi": "MW",
  "Chequia": "CZ",
  "Chile": "CL",
  "China": "CN",
  "Chipre": "CY",
  "Christmas Island": "CX",
  "Ciudad del Vaticano": "VA",
  "Co-operative Republic of Guyana": "GY",
  "Cocos (Keeling) Islands": "CC",
  "Colectividad de San Barthélemy": "BL",
  "Collectivity of Saint Barthélemy": "BL",
  "Collectivité de Saint-Barthélemy": "BL",
  "Collectivité territoriale de Saint-Pierre-et-Miquelon": "PM",
  "Colombia": "CO",
  "Commonwealth de las Bahamas": "BS",
  "Commonwealth of Australia": "AU",
  "Commonwealth of Dominica": "DM",
  "Commonwealth of Puerto Rico": "PR",
  "Commonwealth of the Bahamas": "BS",
  "Commonwealth of the Northern Mariana Islands": "MP",
  "Comoras": "KM",
  "Comores": "KM",
  "Comoros": "KM",
  "Confederación Suiza": "CH",
  "Confederazione Svizzera": "CH",
  "Confederaziun svizra": "CH",
  "Confédération suisse": "CH",
  "Congo": "CG",
  "Congo (Rep. Dem.)": "CD",
  "Cook Islands": "CK",
  "Corea del Norte": "KP",
  "Corea del Sur": "KR",
  "Costa Rica": "CR",
  "Costa de Marfil": "CI",
  "Country of Curaçao": "CW",
  "Croacia": "HR",
  "Croatia": "HR",
  "Cuba": "CU",
  "Curazao": "CW",
  "Curaçao": "CW",
  "Cyprus": "CY",
  "Czech Republic": "CZ",
  "Czechia": "CZ",
  "Côte d'Ivoire": "CI",
  "Cộng hòa xã hội chủ nghĩa Việt Nam": "VN",
  "DE": "DE",
  "DJ": "DJ",
  "DK": "DK",
  "DM": "DM",
  "DO": "DO",
  "DR Congo": "CD",
  "DZ": "DZ",
  "Danmark": "DK",
  "Democratic People's Republic of Korea": "KP",
  "Democratic Republic of São Tomé and Príncipe": "ST",
  "Democratic Republic of Timor-Leste": "TL",
  "Democratic Republic of the Congo": "CD",
  "Democratic Socialist Republic of Sri Lanka": "LK",
  "Denmark": "DK",
  "Departamento de Mayotte": "YT",
  "Department of Mayotte": "YT",
  "Deutschland": "DE",
  "Dgèrnésiais": "GG",
  "Dinamarca": "DK",
  "Ditunga dia Kongu wa Mungalaata": "CD",
  "Djibouti": "DJ",
  "Dominica": "DM",
  "Dominican Republic": "DO",
  "Département de Mayotte": "YT",
  "EC": "EC",
  "EE": "EE",
  "EG": "EG",
  "EH": "EH",
  "ER": "ER",
  "ES": "ES",
  "ET": "ET",
  "Ecuador": "EC",
  "Eesti": "EE",
  "Eesti Vabariik": "EE",
  "Egipto": "EG",
  "Egypt": "EG",
  "El Salvador": "SV",
  "Ellan Vannin or Mannin": "IM",
  "Emiratos Árabes Unidos": "AE",
  "Equatorial Guinea": "GQ",
  "Eritrea": "ER",
  "Eslovenia": "SI",
  "Espagne": "ES",
  "Espanha": "ES",
  "Espanja": "ES",
  "Espanjan kuningaskunta": "ES",
  "España": "ES",
  "Estado Independiente de Papúa Nueva Guinea": "PG",
  "Estado Independiente de Samoa": "WS",
  "Estado Libre Asociado de Puerto Rico": "PR",
  "Estado Plurinacional de Bolivia": "BO",
  "Estado de Eritrea": "ER",
  "Estado de Israel": "IL",
  "Estado de Kuwait": "KW",
  "Estado de Libia": "LY",
  "Estado de Palestina": "PS",
  "Estado de Qatar": "QA",
  "Estados Federados de Micronesia": "FM",
  "Estados Unidos": "US",
  "Estados Unidos Islas menores alejadas de": "UM",
  "Estados Unidos Mexicanos": "MX",
  "Estados Unidos de América": "US",
  "Estonia": "EE",
  "Eswatini": "SZ",
  "Ethiopia": "ET",
  "Etiopía": "ET",
  "FI": "FI",
  "FJ": "FJ",
  "FK": "FK",
  "FM": "FM",
  "FO": "FO",
  "FR": "FR",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  "Federación de Rusia": "RU",
  "Federación de San Cristóbal y Nevisa": "KN",
  "Federal Democratic Republic of Ethiopia": "ET",
  "Federal Democratic Republic of Nepal": "NP",
  "Federal Republic of Germany": "DE",
  "Federal Republic of Nigeria": "NG",
  "Federal Republic of Somalia": "SO",
  "Federated States of Micronesia": "FM",
  "Federation of Saint Christopher and Nevisa": "KN",
  "Federative Republic of Brazil": "BR",
  "Fiji": "FJ",
  "Filipinas": "PH",
  "Finland": "FI",
  "Finlandia": "FI",
  "Fiyi": "FJ",
  "France": "FR",
  "Francia": "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Republic": "FR",
  "French Southern and Antarctic Lands": "TF",
  "Færøerne": "FO",
  "Føroyar": "FO",
  "Fürstentum Liechtenstein": "LI",
  "GA": "GA",
  "GB": "GB",
  "GD": "GD",
  "GE": "GE",
  "GF": "GF",
  "GG": "GG",
  "GH": "GH",
  "GI": "GI",
  "GL": "GL",
  "GM": "GM",
  "GN": "GN",
  "GP": "GP",
  "GQ": "GQ",
  "GR": "GR",
  "GS": "GS",
  "GT": "GT",
  "GU": "GU",
  "GW": "GW",
  "GY": "GY",
  "Gabon": "GA",
  "Gabonese Republic": "GA",
  "Gabón": "GA",
  "Gambia": "GM",
  "Georgia": "GE",
  "Georgia del Sur y las Islas Sandwich del Sur": "GS",
  "Germany": "DE",
  "Ghana": "GH",
  "Gibraltar": "GI",
  "Gran Ducado de Luxemburgo": "LU",
  "Granada": "GD",
  "Grand Duchy of Luxembourg": "LU",
  "Grand-Duché de Luxembourg": "LU",
  "Grecia": "GR",
  "Greece": "GR",
  "Greenland": "GL",
  "Grenada": "GD",
  "Groenlandia": "GL",
  "Groussherzogtum Lëtzebuerg": "LU",
  "Großherzogtum Luxemburg": "LU",
  "Grupo de Islas Pitcairn": "PN",
  "Guadalupe": "GP",
  "Guadeloupe": "GP",
  "Guam": "GU",
  "Guatemala": "GT",
  "Guayana": "GF",
  "Guayana Francesa": "GF",
  "Guernesey": "GG",
  "Guernsey": "GG",
  "Guiana": "GF",
  "Guinea": "GN",
  "Guinea Ecuatorial": "GQ",
  "Guinea-Bissau": "GW",
  "Guinea-Bisáu": "GW",
  "Guiné Equatorial": "GQ",
  "Guiné-Bissau": "GW",
  "Guinée": "GN",
  "Guinée équatoriale": "GQ",
  "Guyana": "GY",
  "Guyane": "GF",
  "Guyane française": "GF",
  "Guåhån": "GU",
  "HK": "HK",
  "HM": "HM",
  "HN": "HN",
  "HR": "HR",
  "HT": "HT",
  "HU": "HU",
  "Haiti": "HT",
  "Hashemite Kingdom of Jordan": "JO",
  "Haïti": "HT",
  "Heard Island and McDonald Islands": "HM",
  "Hellenic Republic": "GR",
  "Hispaania": "ES",
  "Hispaania Kuningriik": "ES",
  "Hiszpania": "ES",
  "Honduras": "HN",
  "Hong Kong": "HK",
  "Hong Kong Región Administrativa Especial de la República Popular China": "HK",
  "Hong Kong Special Administrative Region of the People's Republic of China": "HK",
  "Hrvatska": "HR",
  "Hungary": "HU",
  "Hungría": "HU",
  "ID": "ID",
  "IE": "IE",
  "IL": "IL",
  "IM": "IM",
  "IN": "IN",
  "IO": "IO",
  "IQ": "IQ",
  "IR": "IR",
  "IRiphabhulikhi yeNingizimu Afrika": "ZA",
  "IRiphabliki yaseMzantsi Afrika": "ZA",
  "IRiphabliki yaseNingizimu Afrika": "ZA",
  "IRiphabliki yeSewula Afrika": "ZA",
  "IS": "IS",
  "IT": "IT",
  "Iceland": "IS",
  "Ile de la Réunion": "RE",
  "Independen Stet bilong Papua Niugini": "PG",
  "Independent State of Papua New Guinea": "PG",
  "Independent State of Samoa": "WS",
  "Independent and Sovereign Republic of Kiribati": "KI",
  "India": "IN",
  "Indonesia": "ID",
  "Irak": "IQ",
  "Iran": "IR",
  "Iraq": "IQ",
  "Ireland": "IE",
  "Irlanda": "IE",
  "Isla Bouvet": "BV",
  "Isla de Man": "IM",
  "Isla de Navidad": "CX",
  "Isla de Norfolk": "NF",
  "Isla de la Reunión": "RE",
  "Islamic Republic of Afghanistan": "AF",
  "Islamic Republic of Iran": "IR",
  "Islamic Republic of Mauritania": "MR",
  "Islamic Republic of Pakistan": "PK",
  "Islandia": "IS",
  "Islas Caimán": "KY",
  "Islas Cocos o Islas Keeling": "CC",
  "Islas Cook": "CK",
  "Islas Faroe": "FO",
  "Islas Feroe": "FO",
  "Islas Georgias del Sur y Sandwich del Sur": "GS",
  "Islas Heard y McDonald": "HM",
  "Islas Malvinas": "FK",
  "Islas Marianas del Norte": "MP",
  "Islas Marshall": "MH",
  "Islas Pitcairn": "PN",
  "Islas Salomón": "SB",
  "Islas Svalbard y Jan Mayen": "SJ",
  "Islas Tokelau": "TK",
  "Islas Turcas y Caicos": "TC",
  "Islas Turks y Caicos": "TC",
  "Islas Ultramarinas Menores de Estados Unidos": "UM",
  "Islas Vírgenes": "VG",
  "Islas Vírgenes de los Estados Unidos": "VI",
  "Islas Vírgenes del Reino Unido": "VG",
  "Islas Åland": "AX",
  "Isle of Man": "IM",
  "Israel": "IL",
  "Italia": "IT",
  "Italian Republic": "IT",
  "Italy": "IT",
  "Ivory Coast": "CI",
  "JE": "JE",
  "JM": "JM",
  "JO": "JO",
  "JP": "JP",
  "Jamaica": "JM",
  "Jamhuri ya Kidemokrasia ya Kongo": "CD",
  "Jamhuri ya Muungano wa Tanzania": "TZ",
  "Jamhuuriyadda Federaalka Soomaaliya": "SO",
  "Japan": "JP",
  "Japón": "JP",
  "Jersey": "JE",
  "Jordan": "JO",
  "Jordania": "JO",
  "Jèrri": "JE",
  "KE": "KE",
  "KG": "KG",
  "KH": "KH",
  "KI": "KI",
  "KM": "KM",
  "KN": "KN",
  "KP": "KP",
  "KR": "KR",
  "KW": "KW",
  "KY": "KY",
  "KZ": "KZ",
  "Kalaallit Nunaat": "GL",
  "Kazajistán": "KZ",
  "Kazakhstan": "KZ",
  "Kenia": "KE",
  "Kenya": "KE",
  "Kingdom of Bahrain": "BH",
  "Kingdom of Belgium": "BE",
  "Kingdom of Bhutan": "BT",
  "Kingdom of Cambodia": "KH",
  "Kingdom of Denmark": "DK",
  "Kingdom of Eswatini": "SZ",
  "Kingdom of Lesotho": "LS",
  "Kingdom of Morocco": "MA",
  "Kingdom of Norway": "NO",
  "Kingdom of Saudi Arabia": "SA",
  "Kingdom of Spain": "ES",
  "Kingdom of Sweden": "SE",
  "Kingdom of Thailand": "TH",
  "Kingdom of Tonga": "TO",
  "Kingdom of the Netherlands": "NL",
  "Kirguizistán": "KG",
  "Kiribati": "KI",
  "Komori": "KM",
  "Kongeriget Danmark": "DK",
  "Kongeriket Noreg": "NO",
  "Kongeriket Norge": "NO",
  "Koninkrijk België": "BE",
  "Koninkrijk Spanje": "ES",
  "Koninkrijk der Nederlanden": "NL",
  "Konungariket Sverige": "SE",
  "Kosova": "XK",
  "Kosovo": "XK",
  "Kraljevina Španjolska": "ES",
  "Królestwo Hiszpanii ": "ES",
  "Kuwait": "KW",
  "Kyrgyz Republic": "KG",
  "Kyrgyzstan": "KG",
  "Kâmpŭchéa": "KH",
  "Ködörösêse tî Bêafrîka": "CF",
  "Königreich Belgien": "BE",
  "Königreich Spanien": "ES",
  "Kıbrıs": "CY",
  "Kıbrıs Cumhuriyeti": "CY",
  "Kūki 'Āirani": "CK",
  "LA": "LA",
  "LB": "LB",
  "LC": "LC",
  "LI": "LI",
  "LK": "LK",
  "LR": "LR",
  "LS": "LS",
  "LT": "LT",
  "LU": "LU",
  "LV": "LV",
  "LY": "LY",
  "La Réunion": "RE",
  "Land Curaçao": "CW",
  "Landskapet Åland": "AX",
  "Lao People's Democratic Republic": "LA",
  "Laos": "LA",
  "Latvia": "LV",
  "Latvija": "LV",
  "Latvijas Republikas": "LV",
  "Lebanese Republic": "LB",
  "Lebanon": "LB",
  "Lefatshe la Botswana": "BW",
  "Lefatshe la Namibia": "NA",
  "Lesotho": "LS",
  "Letonia": "LV",
  "Liban": "LB",
  "Liberia": "LR",
  "Libia": "LY",
  "Libya": "LY",
  "Liechtenstein": "LI",
  "Lietuva": "LT",
  "Lietuvos Respublikos": "LT",
  "Lithuania": "LT",
  "Lituania": "LT",
  "Luxembourg": "LU",
  "Luxemburg": "LU",
  "Luxemburgo": "LU",
  "Lëtzebuerg": "LU",
  "Líbano": "LB",
  "MA": "MA",
  "MC": "MC",
  "MD": "MD",
  "ME": "ME",
  "MF": "MF",
  "MG": "MG",
  "MH": "MH",
  "MK": "MK",
  "ML": "ML",
  "MM": "MM",
  "MN": "MN",
  "MO": "MO",
  "MP": "MP",
  "MQ": "MQ",
  "MR": "MR",
  "MS": "MS",
  "MT": "MT",
  "MU": "MU",
  "MV": "MV",
  "MW": "MW",
  "MX": "MX",
  "MY": "MY",
  "MZ": "MZ",
  "Macao": "MO",
  "Macao Special Administrative Region of the People's Republic of China": "MO",
  "Macao, Región Administrativa Especial de la República Popular China": "MO",
  "Macau": "MO",
  "Macedonia del Norte": "MK",
  "Madagascar": "MG",
  "Madagasikara": "MG",
  "Magyarország": "HU",
  "Malasia": "MY",
  "Malawi": "MW",
  "Malaysia": "MY",
  "Malaŵi": "MW",
  "Maldivas": "MV",
  "Maldives": "MV",
  "Mali": "ML",
  "Malo Saʻoloto Tutoʻatasi o Sāmoa": "WS",
  "Malta": "MT",
  "Mancomunidad de Australia": "AU",
  "Mancomunidad de Dominica": "DM",
  "Mancomunidad de las Islas Marianas del Norte": "MP",
  "Mannin": "IM",
  "Marruecos": "MA",
  "Marshall Islands": "MH",
  "Martinica": "MQ",
  "Martinique": "MQ",
  "Matanitu Tugalala o Viti": "FJ",
  "Maurice": "MU",
  "Mauricio": "MU",
  "Mauritania": "MR",
  "Mauritius": "MU",
  "Mayotte": "YT",
  "Mexico": "MX",
  "Micronesia": "FM",
  "Moldavia": "MD",
  "Moldova": "MD",
  "Monaco": "MC",
  "Mongolia": "MN",
  "Montenegro": "ME",
  "Montserrat": "MS",
  "Moris": "MU",
  "Morocco": "MA",
  "Most Serene Republic of San Marino": "SM",
  "Mozambique": "MZ",
  "Moçambique": "MZ",
  "Myanmar": "MM",
  "Mzantsi Afrika": "ZA",
  "México": "MX",
  "Mónaco": "MC",
  "M̧ajeļ": "MH",
  "NA": "NA",
  "NC": "NC",
  "NE": "NE",
  "NF": "NF",
  "NG": "NG",
  "NI": "NI",
  "NL": "NL",
  "NO": "NO",
  "NP": "NP",
  "NR": "NR",
  "NU": "NU",
  "NZ": "NZ",
  "Na Islas Mariånas": "MP",
  "Nación de Brunei, Morada de la Paz": "BN",
  "Namibia": "NA",
  "Namibië": "NA",
  "Nation of Brunei, Abode Damai": "BN",
  "Nation of Brunei, Abode of Peace": "BN",
  "Nauru": "NR",
  "Nederland": "NL",
  "Negara Brunei Darussalam": "BN",
  "Nepal": "NP",
  "Netherlands": "NL",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  "Nicaragua": "NI",
  "Niger": "NE",
  "Nigeria": "NG",
  "Ningizimu Afrika": "ZA",
  "Niue": "NU",
  "Niuē": "NU",
  "Noreg": "NO",
  "Norf'k Ailen": "NF",
  "Norfolk Island": "NF",
  "Norge": "NO",
  "Norgga": "NO",
  "Norgga gonagasriika": "NO",
  "North Korea": "KP",
  "North Macedonia": "MK",
  "Northern Mariana Islands": "MP",
  "Noruega": "NO",
  "Norway": "NO",
  "Nouvelle-Calédonie": "NC",
  "Nueva Caledonia": "NC",
  "Nueva Zelanda": "NZ",
  "Níger": "NE",
  "O'zbekiston Respublikasi": "UZ",
  "OM": "OM",
  "Oman": "OM",
  "Omán": "OM",
  "Oriental Republic of Uruguay": "UY",
  "Owganystan": "AF",
  "Owganystan Yslam Respublikasy": "AF",
  "O‘zbekiston": "UZ",
  "PA": "PA",
  "PE": "PE",
  "PF": "PF",
  "PG": "PG",
  "PH": "PH",
  "PK": "PK",
  "PL": "PL",
  "PM": "PM",
  "PN": "PN",
  "PR": "PR",
  "PS": "PS",
  "PT": "PT",
  "PW": "PW",
  "PY": "PY",
  "Pais Kòrsou": "CW",
  "Pakistan": "PK",
  "Pakistán": "PK",
  "Palau": "PW",
  "Palestina": "PS",
  "Palestine": "PS",
  "Panama": "PA",
  "Panamá": "PA",
  "Papua New Guinea": "PG",
  "Papua Niu Gini": "PG",
  "Papua Niugini": "PG",
  "Papúa Nueva Guinea": "PG",
  "Paraguay": "PY",
  "Paraguái": "PY",
  "País de Curazao": "CW",
  "Países Bajos": "NL",
  "People's Democratic Republic of Algeria": "DZ",
  "People's Republic of Bangladesh": "BD",
  "People's Republic of China": "CN",
  "Peru": "PE",
  "Perú": "PE",
  "Philippines": "PH",
  "Pilipinas": "PH",
  "Piruw": "PE",
  "Piruw Ripuwlika": "PE",
  "Piruw Suyu": "PE",
  "Pitcairn Group of Islands": "PN",
  "Pitcairn Islands": "PN",
  "Plurinational State of Bolivia": "BO",
  "Poblacht na hÉireann": "IE",
  "Poland": "PL",
  "Polinesia Francesa": "PF",
  "Polinesia francés": "PF",
  "Polonia": "PL",
  "Polska": "PL",
  "Polynésie française": "PF",
  "Portugal": "PT",
  "Portuguese Republic": "PT",
  "Principado de Andorra": "AD",
  "Principado de Liechtenstein": "LI",
  "Principado de Mónaco": "MC",
  "Principality of Andorra": "AD",
  "Principality of Liechtenstein": "LI",
  "Principality of Monaco": "MC",
  "Principat d'Andorra": "AD",
  "Principauté de Monaco": "MC",
  "Puerto Rico": "PR",
  "QA": "QA",
  "Qatar": "QA",
  "RD Congo": "CD",
  "RE": "RE",
  "RO": "RO",
  "RS": "RS",
  "RU": "RU",
  "RW": "RW",
  "Região Administrativa Especial de Macau da República Popular da China": "MO",
  "Regno di Spagna": "ES",
  "Reino Hachemita de Jordania": "JO",
  "Reino Unido": "GB",
  "Reino Unido de Gran Bretaña e Irlanda del Norte": "GB",
  "Reino de Arabia Saudita": "SA",
  "Reino de Bahrein": "BH",
  "Reino de Bután": "BT",
  "Reino de Bélgica": "BE",
  "Reino de Camboya": "KH",
  "Reino de Dinamarca": "DK",
  "Reino de Espanha": "ES",
  "Reino de España": "ES",
  "Reino de Lesotho": "LS",
  "Reino de Marruecos": "MA",
  "Reino de Noruega": "NO",
  "Reino de Suecia": "SE",
  "Reino de Tailandia": "TH",
  "Reino de Tonga": "TO",
  "Reino de eSwatini": "SZ",
  "Rephaboliki ya Aforika Borwa": "ZA",
  "Rephaboliki ya Afrika Borwa": "ZA",
  "Rephaboliki ya Afrika-Borwa ": "ZA",
  "Repiblik Ayiti": "HT",
  "Repiblik Sesel": "SC",
  "Repoblikan'i Madagasikara": "MG",
  "Repubblica italiana": "IT",
  "Repubblika ta ' Malta": "MT",
  "Repubilika ya Kongo": "CG",
  "Repubilika ya Kongo Demokratiki": "CD",
  "Republic of Albania": "AL",
  "Republic of Angola": "AO",
  "Republic of Armenia": "AM",
  "Republic of Austria": "AT",
  "Republic of Azerbaijan": "AZ",
  "Republic of Belarus": "BY",
  "Republic of Benin": "BJ",
  "Republic of Botswana": "BW",
  "Republic of Bulgaria": "BG",
  "Republic of Burundi": "BI",
  "Republic of Cabo Verde": "CV",
  "Republic of Cameroon": "CM",
  "Republic of Chad": "TD",
  "Republic of Chile": "CL",
  "Republic of China (Taiwan)": "TW",
  "Republic of Colombia": "CO",
  "Republic of Costa Rica": "CR",
  "Republic of Croatia": "HR",
  "Republic of Cuba": "CU",
  "Republic of Cyprus": "CY",
  "Republic of Côte d'Ivoire": "CI",
  "Republic of Djibouti": "DJ",
  "Republic of Ecuador": "EC",
  "Republic of El Salvador": "SV",
  "Republic of Equatorial Guinea": "GQ",
  "Republic of Estonia": "EE",
  "Republic of Fiji": "FJ",
  "Republic of Finland": "FI",
  "Republic of Ghana": "GH",
  "Republic of Guatemala": "GT",
  "Republic of Guinea": "GN",
  "Republic of Guinea-Bissau": "GW",
  "Republic of Haiti": "HT",
  "Republic of Honduras": "HN",
  "Republic of India": "IN",
  "Republic of Indonesia": "ID",
  "Republic of Iraq": "IQ",
  "Republic of Ireland": "IE",
  "Republic of Kazakhstan": "KZ",
  "Republic of Kenya": "KE",
  "Republic of Korea": "KR",
  "Republic of Kosovo": "XK",
  "Republic of Latvia": "LV",
  "Republic of Liberia": "LR",
  "Republic of Lithuania": "LT",
  "Republic of Madagascar": "MG",
  "Republic of Malawi": "MW",
  "Republic of Mali": "ML",
  "Republic of Malta": "MT",
  "Republic of Mauritius": "MU",
  "Republic of Moldova": "MD",
  "Republic of Mozambique": "MZ",
  "Republic of Namibia": "NA",
  "Republic of Nauru": "NR",
  "Republic of Nicaragua": "NI",
  "Republic of Niger": "NE",
  "Republic of North Macedonia": "MK",
  "Republic of Palau": "PW",
  "Republic of Panama": "PA",
  "Republic of Paraguay": "PY",
  "Republic of Peru": "PE",
  "Republic of Poland": "PL",
  "Republic of Rwanda": "RW",
  "Republic of Senegal": "SN",
  "Republic of Serbia": "RS",
  "Republic of Seychelles": "SC",
  "Republic of Sierra Leone": "SL",
  "Republic of Singapore": "SG",
  "Republic of Slovenia": "SI",
  "Republic of South Africa": "ZA",
  "Republic of South Sudan": "SS",
  "Republic of Suriname": "SR",
  "Republic of Tajikistan": "TJ",
  "Republic of Trinidad and Tobago": "TT",
  "Republic of Turkey": "TR",
  "Republic of Uganda": "UG",
  "Republic of Uzbekistan": "UZ",
  "Republic of Vanuatu": "VU",
  "Republic of Yemen": "YE",
  "Republic of Zambia": "ZM",
  "Republic of Zimbabwe": "ZW",
  "Republic of the Congo": "CG",
  "Republic of the Gambia": "GM",
  "Republic of the Maldives": "MV",
  "Republic of the Marshall Islands": "MH",
  "Republic of the Philippines": "PH",
  "Republic of the Sudan": "SD",
  "Republic of the Union of Myanmar": "MM",
  "Republica Moldova": "MD",
  "Republiek Suriname": "SR",
  "Republiek van Namibië": "NA",
  "Republiek van Suid-Afrika": "ZA",
  "Republik Indonesia": "ID",
  "Republik Moris": "MU",
  "Republik Namibia": "NA",
  "Republik Singapura": "SG",
  "Republik Österreich": "AT",
  "Republika Hrvatska": "HR",
  "Republika Slovenija": "SI",
  "Republika e Kosovës": "XK",
  "Republika e Shqipërisë": "AL",
  "Republika y'Uburundi ": "BI",
  "Republiken Finland": "FI",
  "Republiki ya Kongó Demokratiki": "CD",
  "Republíki ya Kongó": "CG",
  "Repubulika y'u Rwanda": "RW",
  "República Argelina Democrática y Popular": "DZ",
  "República Argentina": "AR",
  "República Bolivariana de Venezuela": "VE",
  "República Centroafricana": "CF",
  "República Checa": "CZ",
  "República Cooperativa de Guyana": "GY",
  "República Democrática Federal de Etiopía": "ET",
  "República Democrática Federal de Nepal": "NP",
  "República Democrática Popular Lao": "LA",
  "República Democrática Socialista de Sri Lanka": "LK",
  "República Democrática de Santo Tomé y Príncipe": "ST",
  "República Democrática de Timor-Leste": "TL",
  "República Democrática del Congo": "CD",
  "República Democrática do São Tomé e Príncipe": "ST",
  "República Dominicana": "DO",
  "República Eslovaca": "SK",
  "República Federal de Alemania": "DE",
  "República Federal de Nigeria": "NG",
  "República Federal de Somalia": "SO",
  "República Federativa del Brasil": "BR",
  "República Federativa do Brasil": "BR",
  "República Helénica": "GR",
  "República Independiente y Soberano de Kiribati": "KI",
  "República Islámica de Afganistán": "AF",
  "República Islámica de Irán": "IR",
  "República Islámica de Mauritania": "MR",
  "República Islámica de Pakistán": "PK",
  "República Italiana": "IT",
  "República Kirguisa": "KG",
  "República Libanesa": "LB",
  "República Oriental del Uruguay": "UY",
  "República Popular Democrática de Corea": "KP",
  "República Popular de Bangladesh": "BD",
  "República Popular de China": "CN",
  "República Portuguesa": "PT",
  "República Socialista de Vietnam": "VN",
  "República Unida de Tanzania": "TZ",
  "República da Guiné Equatorial": "GQ",
  "República da Guiné-Bissau": "GW",
  "República de Albania": "AL",
  "República de Angola": "AO",
  "República de Armenia": "AM",
  "República de Austria": "AT",
  "República de Azerbaiyán": "AZ",
  "República de Belarús": "BY",
  "República de Benin": "BJ",
  "República de Botswana": "BW",
  "República de Bulgaria": "BG",
  "República de Burundi": "BI",
  "República de Cabo Verde": "CV",
  "República de Camerún": "CM",
  "República de Chad": "TD",
  "República de Chile": "CL",
  "República de China en Taiwán": "TW",
  "República de Chipre": "CY",
  "República de Colombia": "CO",
  "República de Corea": "KR",
  "República de Costa Rica": "CR",
  "República de Croacia": "HR",
  "República de Cuba": "CU",
  "República de Côte d'Ivoire": "CI",
  "República de Djibouti": "DJ",
  "República de El Salvador": "SV",
  "República de Eslovenia": "SI",
  "República de Estonia": "EE",
  "República de Fiji": "FJ",
  "República de Finlandia": "FI",
  "República de Gabón": "GA",
  "República de Gambia": "GM",
  "República de Ghana": "GH",
  "República de Guatemala": "GT",
  "República de Guinea": "GN",
  "República de Guinea Ecuatorial": "GQ",
  "República de Guinea-Bissau": "GW",
  "República de Haití": "HT",
  "República de Honduras": "HN",
  "República de Indonesia": "ID",
  "República de Irak": "IQ",
  "República de Irlanda": "IE",
  "República de Kazajstán": "KZ",
  "República de Kenya": "KE",
  "República de Kosovo": "XK",
  "República de Letonia": "LV",
  "República de Liberia": "LR",
  "República de Lituania": "LT",
  "República de Macedonia del Norte": "MK",
  "República de Madagascar": "MG",
  "República de Malawi": "MW",
  "República de Malta": "MT",
  "República de Malí": "ML",
  "República de Mauricio": "MU",
  "República de Moldova": "MD",
  "República de Mozambique": "MZ",
  "República de Moçambique": "MZ",
  "República de Namibia": "NA",
  "República de Nauru": "NR",
  "República de Nicaragua": "NI",
  "República de Níger": "NE",
  "República de Palau": "PW",
  "República de Panamá": "PA",
  "República de Paraguay": "PY",
  "República de Perú": "PE",
  "República de Polonia": "PL",
  "República de Rwanda": "RW",
  "República de Senegal": "SN",
  "República de Serbia": "RS",
  "República de Sierra Leona": "SL",
  "República de Singapur": "SG",
  "República de Sudáfrica": "ZA",
  "República de Sudán": "SD",
  "República de Sudán del Sur": "SS",
  "República de Suriname": "SR",
  "República de Tayikistán": "TJ",
  "República de Togo": "TG",
  "República de Trinidad y Tobago": "TT",
  "República de Turquía": "TR",
  "República de Túnez": "TN",
  "República de Uganda": "UG",
  "República de Uzbekistán": "UZ",
  "República de Vanuatu": "VU",
  "República de Yemen": "YE",
  "República de Zambia": "ZM",
  "República de Zimbabue": "ZW",
  "República de la India": "IN",
  "República de la Unión de Myanmar": "MM",
  "República de las Filipinas": "PH",
  "República de las Islas Marshall": "MH",
  "República de las Maldivas": "MV",
  "República de las Seychelles": "SC",
  "República del Congo": "CG",
  "República del Ecuador": "EC",
  "República del Perú": "PE",
  "República francés": "FR",
  "República português": "PT",
  "República Árabe Saharaui Democrática": "EH",
  "República Árabe Siria": "SY",
  "República Árabe de Egipto": "EG",
  "Repúblika Demokrátika Timór-Leste": "TL",
  "Reunión": "RE",
  "Ribaberiki Kiribati": "KI",
  "Ripablik blong Vanuatu": "VU",
  "Riphabliki ra Afrika Dzonga": "ZA",
  "Riphabuḽiki ya Afurika Tshipembe": "ZA",
  "Romania": "RO",
  "România": "RO",
  "Royaume d'Espagne": "ES",
  "Royaume de Belgique": "BE",
  "Ruanda": "RW",
  "Rumania": "RO",
  "Rusia": "RU",
  "Russia": "RU",
  "Russian Federation": "RU",
  "Rwanda": "RW",
  "Rzeczpospolita Polska": "PL",
  "République centrafricaine": "CF",
  "République d'Haïti": "HT",
  "République de Côte d'Ivoire": "CI",
  "République de Djibouti": "DJ",
  "République de Guinée": "GN",
  "République de Madagascar": "MG",
  "République de Maurice": "MU",
  "République de Vanuatu": "VU",
  "République de la Guinée Équatoriale": "GQ",
  "République des Seychelles": "SC",
  "République du Burkina": "BF",
  "République du Burundi": "BI",
  "République du Bénin": "BJ",
  "République du Cameroun": "CM",
  "République du Congo": "CG",
  "République du Mali": "ML",
  "République du Niger": "NE",
  "République du Sénégal": "SN",
  "République du Tchad": "TD",
  "République démocratique du Congo": "CD",
  "République française": "FR",
  "République gabonaise": "GA",
  "République libanaise": "LB",
  "République rwandaise": "RW",
  "République togolaise": "TG",
  "Réunion": "RE",
  "Réunion Island": "RE",
  "SA": "SA",
  "SB": "SB",
  "SC": "SC",
  "SD": "SD",
  "SE": "SE",
  "SG": "SG",
  "SH": "SH",
  "SI": "SI",
  "SJ": "SJ",
  "SK": "SK",
  "SL": "SL",
  "SM": "SM",
  "SN": "SN",
  "SO": "SO",
  "SR": "SR",
  "SS": "SS",
  "ST": "ST",
  "SV": "SV",
  "SX": "SX",
  "SY": "SY",
  "SZ": "SZ",
  "Sahara Occidental": "EH",
  "Sahrawi Arab Democratic Republic": "EH",
  "Saint Barthélemy": "BL",
  "Saint Helena, Ascension and Tristan da Cunha": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and the Grenadines": "VC",
  "Saint-Barthélemy": "BL",
  "Saint-Martin": "SX",
  "Saint-Pierre-et-Miquelon": "PM",
  "Samoa": "WS",
  "Samoa Americana": "AS",
  "San Bartolomé": "BL",
  "San Cristóbal y Nieves": "KN",
  "San Marino": "SM",
  "San Pedro y Miquelón": "PM",
  "San Vicente y Granadinas": "VC",
  "San Vicente y las Granadinas": "VC",
  "Sankattan Siha Na Islas Mariånas": "MP",
  "Santa Elena, Ascensión y Tristán de Acuña": "SH",
  "Santa Lucía": "LC",
  "Santo Tomé y Príncipe": "ST",
  "Saudi Arabia": "SA",
  "Schweiz": "CH",
  "Schweizerische Eidgenossenschaft": "CH",
  "Senegal": "SN",
  "Serbia": "RS",
  "Serenissima Repubblica di San Marino": "SM",
  "Serenísima República de San Marino": "SM",
  "Sesel": "SC",
  "Sewula Afrika": "ZA",
  "Seychelles": "SC",
  "Shqipëria": "AL",
  "Sierra Leone": "SL",
  "Singapore": "SG",
  "Singapur": "SG",
  "Singapura": "SG",
  "Sint Maarten": "SX",
  "Siria": "SY",
  "Slovak Republic": "SK",
  "Slovakia": "SK",
  "Slovenia": "SI",
  "Slovenija": "SI",
  "Slovensko": "SK",
  "Slovenská republika": "SK",
  "Socialist Republic of Vietnam": "VN",
  "Solomon Islands": "SB",
  "Somalia": "SO",
  "Soomaaliya": "SO",
  "South Africa": "ZA",
  "South Georgia": "GS",
  "South Georgia and the South Sandwich Islands": "GS",
  "South Korea": "KR",
  "South Sudan": "SS",
  "Spagna": "ES",
  "Spain": "ES",
  "Spanien": "ES",
  "Spanje": "ES",
  "Sri Lanka": "LK",
  "State of Eritrea": "ER",
  "State of Israel": "IL",
  "State of Kuwait": "KW",
  "State of Libya": "LY",
  "State of Palestine": "PS",
  "State of Qatar": "QA",
  "Stato della Città del Vaticano": "VA",
  "Status Civitatis Vaticanæ": "VA",
  "Suazilandia": "SZ",
  "Sudan": "SD",
  "Sudán": "SD",
  "Sudán del Sur": "SS",
  "Suecia": "SE",
  "Suisse": "CH",
  "Suiza": "CH",
  "Sultanate of Oman": "OM",
  "Sultanato de Omán": "OM",
  "Suomen tasavalta": "FI",
  "Suomi": "FI",
  "Surinam": "SR",
  "Suriname": "SR",
  "Svalbard and Jan Mayen": "SJ",
  "Svalbard og Jan Mayen": "SJ",
  "Sverige": "SE",
  "Svizra": "CH",
  "Svizzera": "CH",
  "Sweden": "SE",
  "Swiss Confederation": "CH",
  "Switzerland": "CH",
  "Syria": "SY",
  "Syrian Arab Republic": "SY",
  "São Tomé and Príncipe": "ST",
  "São Tomé e Príncipe": "ST",
  "Sénégal": "SN",
  "Sāmoa": "WS",
  "Sāmoa Amelika": "AS",
  "TC": "TC",
  "TD": "TD",
  "TF": "TF",
  "TG": "TG",
  "TH": "TH",
  "TJ": "TJ",
  "TK": "TK",
  "TL": "TL",
  "TM": "TM",
  "TN": "TN",
  "TO": "TO",
  "TR": "TR",
  "TT": "TT",
  "TV": "TV",
  "TW": "TW",
  "TZ": "TZ",
  "Tailandia": "TH",
  "Taiwan": "TW",
  "Taiwán": "TW",
  "Tajikistan": "TJ",
  "Tanzania": "TZ",
  "Tayikistán": "TJ",
  "Tchad": "TD",
  "Teratri of Norf'k Ailen": "NF",
  "Terres australes et antarctiques françaises": "TF",
  "Territoire des Terres australes et antarctiques françaises": "TF",
  "Territoire des îles Wallis et Futuna": "WF",
  "Territorio Británico del Océano Índico": "IO",
  "Territorio de la Isla Norfolk": "NF",
  "Territorio de la Isla de Navidad": "CX",
  "Territorio de las Islas Wallis y Futuna": "WF",
  "Territorio de los (Keeling) Islas Cocos": "CC",
  "Territorio del Francés Tierras australes y antárticas": "TF",
  "Territory of Christmas Island": "CX",
  "Territory of Norfolk Island": "NF",
  "Territory of the Cocos (Keeling) Islands": "CC",
  "Territory of the French Southern and Antarctic Lands": "TF",
  "Territory of the Wallis and Futuna Islands": "WF",
  "Tetã Paraguái": "PY",
  "Tetã Volívia": "BO",
  "Thailand": "TH",
  "Tierras Australes y Antárticas Francesas": "TF",
  "Timor Oriental": "TL",
  "Timor-Leste": "TL",
  "Timór-Leste": "TL",
  "Togo": "TG",
  "Togolese Republic": "TG",
  "Tokelau": "TK",
  "Tonga": "TO",
  "Trinidad and Tobago": "TT",
  "Trinidad y Tobago": "TT",
  "Tunisia": "TN",
  "Tunisian Republic": "TN",
  "Turkey": "TR",
  "Turkmenistan": "TM",
  "Turkmenistán": "TM",
  "Turks and Caicos Islands": "TC",
  "Turquía": "TR",
  "Tuvalu": "TV",
  "Túnez": "TN",
  "Türkiye": "TR",
  "Türkiye Cumhuriyeti": "TR",
  "Türkmenistan": "TM",
  "UA": "UA",
  "UG": "UG",
  "UK": "GB",
  "UM": "UM",
  "US": "US",
  "UY": "UY",
  "UZ": "UZ",
  "Uburundi": "BI",
  "Ucrania": "UA",
  "Udzima wa Komori": "KM",
  "Uganda": "UG",
  "Ukraine": "UA",
  "Umbuso weSwatini": "SZ",
  "Union des Comores": "KM",
  "Union of the Comoros": "KM",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United Kingdom of Great Britain and Northern Ireland": "GB",
  "United Mexican States": "MX",
  "United Republic of Tanzania": "TZ",
  "United States": "US",
  "United States Minor Outlying Islands": "UM",
  "United States Virgin Islands": "VI",
  "United States of America": "US",
  "Unión de las Comoras": "KM",
  "Uruguay": "UY",
  "Uzbekistan": "UZ",
  "Uzbekistán": "UZ",
  "VA": "VA",
  "VC": "VC",
  "VE": "VE",
  "VG": "VG",
  "VI": "VI",
  "VN": "VN",
  "VU": "VU",
  "Vanuatu": "VU",
  "Vatican City": "VA",
  "Vatican City State": "VA",
  "Vaticano": "VA",
  "Vaticanæ": "VA",
  "Venezuela": "VE",
  "Vietnam": "VN",
  "Virgin Islands": "VG",
  "Virgin Islands of the United States": "VI",
  "Viti": "FJ",
  "Việt Nam": "VN",
  "Volívia": "BO",
  "WF": "WF",
  "WS": "WS",
  "Wallis and Futuna": "WF",
  "Wallis et Futuna": "WF",
  "Wallis y Futuna": "WF",
  "Western Sahara": "EH",
  "Wuliwya": "BO",
  "Wuliwya Suyu": "BO",
  "XK": "XK",
  "YE": "YE",
  "YT": "YT",
  "Yemen": "YE",
  "ZA": "ZA",
  "ZM": "ZM",
  "ZW": "ZW",
  "Zambia": "ZM",
  "Zimbabue": "ZW",
  "Zimbabwe": "ZW",
  "eSwatini": "SZ",
  "espana": "ES",
  "espanya": "ES",
  "ESP": "ES", 
  "islas Malvinas": "FK",
  "islas Salomón": "SB",
  "nueva Caledonia": "NC",
  "nueva Zelanda": "NZ",
  "usa": "US",
  "Åland": "AX",
  "Åland Islands": "AX",
  "Éire": "IE",
  "Ísland": "IS",
  "Österreich": "AT",
  "Česko": "CZ",
  "Česká republika": "CZ",
  "česká republika": "CZ",
  "Španielske kráľovstvo": "ES",
  "Španielsko": "ES",
  "Španjolska": "ES",
  "Španělsko": "ES",
  "Španělské království": "ES",
  "Δημοκρατία της Κύπρος": "CY",
  "Ελλάδα": "GR",
  "Ελληνική Δημοκρατία": "GR",
  "Κύπρος": "CY",
  "Азербайджан": "AZ",
  "Азербайджанская Республика": "AZ",
  "Белару́сь": "BY",
  "Беларусь": "BY",
  "Боснa и Херцеговина": "BA",
  "България": "BG",
  "Испания": "ES",
  "Казахстан": "KZ",
  "Киргизия": "KG",
  "Королевство Испания": "ES",
  "Косово": "XK",
  "Кыргыз Республикасы": "KG",
  "Кыргызская Республика": "KG",
  "Кыргызстан": "KG",
  "Македонија": "MK",
  "Монгол улс": "MN",
  "Република България": "BG",
  "Република Косово": "XK",
  "Република Северна Македонија": "MK",
  "Република Србија": "RS",
  "Республика Беларусь": "BY",
  "Республика Казахстан": "KZ",
  "Республика Таджикистан": "TJ",
  "Республика Узбекистан": "UZ",
  "Российская Федерация": "RU",
  "Россия": "RU",
  "Рэспубліка Беларусь": "BY",
  "Србија": "RS",
  "Таджикистан": "TJ",
  "Тоҷикистон": "TJ",
  "Туркменистан": "TM",
  "Туркмения": "TM",
  "Узбекистан": "UZ",
  "Україна": "UA",
  "Црна Гора": "ME",
  "Қазақстан": "KZ",
  "Қазақстан Республикасы": "KZ",
  "Ҷумҳурии Тоҷикистон": "TJ",
  "Հայաստան": "AM",
  "Հայաստանի Հանրապետություն": "AM",
  "ישראל": "IL",
  "מדינת ישראל": "IL",
  "إرتريا‎": "ER",
  "إسرائيل": "IL",
  "اسلامی جمہوریۂ پاكستان": "PK",
  "افغانستان": "AF",
  "الأردن": "JO",
  "الإمارات العربية المتحدة": "AE",
  "الاتحاد القمري": "KM",
  "الجزائر": "DZ",
  "الجمهورية الإسلامية الموريتانية": "MR",
  "الجمهورية التونسية": "TN",
  "الجمهورية الديمقراطية الشعبية الجزائرية": "DZ",
  "الجمهورية العربية السورية": "SY",
  "الجمهورية العربية الصحراوية الديمقراطية": "EH",
  "الجمهورية اللبنانية": "LB",
  "الجمهورية اليمنية": "YE",
  "الدولة ليبيا": "LY",
  "السودان": "SD",
  "الصحراء الغربية": "EH",
  "الصومال‎‎": "SO",
  "العراق": "IQ",
  "العربية السعودية": "SA",
  "القمر‎": "KM",
  "الكويت": "KW",
  "المغرب": "MA",
  "المملكة الأردنية الهاشمية": "JO",
  "المملكة العربية السعودية": "SA",
  "المملكة المغربية": "MA",
  "اليَمَن": "YE",
  "ایران": "IR",
  "تشاد‎": "TD",
  "تونس": "TN",
  "جمهورية السودان": "SD",
  "جمهورية الصومال‎‎": "SO",
  "جمهورية العراق": "IQ",
  "جمهورية تشاد": "TD",
  "جمهورية جيبوتي": "DJ",
  "جمهورية مصر العربية": "EG",
  "جمهوری اسلامی افغانستان": "AF",
  "جمهوری اسلامی ایران": "IR",
  "جيبوتي‎": "DJ",
  "د افغانستان اسلامي جمهوریت": "AF",
  "دولة إرتريا": "ER",
  "دولة إسرائيل": "IL",
  "دولة الإمارات العربية المتحدة": "AE",
  "دولة الكويت": "KW",
  "دولة فلسطين": "PS",
  "دولة قطر": "QA",
  "سلطنة عمان": "OM",
  "سوريا": "SY",
  "عمان": "OM",
  "فلسطين": "PS",
  "قطر": "QA",
  "لبنان": "LB",
  "مصر": "EG",
  "مليسيا": "MY",
  "مملكة البحرين": "BH",
  "مملکتِ ہسپانیہ": "ES",
  "موريتانيا": "MR",
  "پاكستان": "PK",
  "کۆماری": "IQ",
  "کۆماری عێراق": "IQ",
  "ہسپانیہ": "ES",
  "ܩܘܼܛܢܵܐ": "IQ",
  "ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ": "IQ",
  "ދިވެހިރާއްޖޭގެ": "MV",
  "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ": "MV",
  "नेपाल": "NP",
  "नेपाल संघीय लोकतान्त्रिक गणतन्त्र": "NP",
  "फिजी": "FJ",
  "भारत": "IN",
  "भारत गणराज्य": "IN",
  "रिपब्लिक ऑफ फीजी": "FJ",
  "বাংলাদেশ": "BD",
  "বাংলাদেশ গণপ্রজাতন্ত্রী": "BD",
  "இந்தியக் குடியரசு": "IN",
  "இந்தியா": "IN",
  "இலங்கை": "LK",
  "இலங்கை சனநாயக சோசலிசக் குடியரசு": "LK",
  "சிங்கப்பூர்": "SG",
  "சிங்கப்பூர் குடியரசு": "SG",
  "ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය": "LK",
  "ශ්‍රී ලංකාව": "LK",
  "ประเทศไทย": "TH",
  "ราชอาณาจักรไทย": "TH",
  "ສປປລາວ": "LA",
  "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ": "LA",
  "འབྲུག་ཡུལ་": "BT",
  "འབྲུག་རྒྱལ་ཁབ་": "BT",
  "ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်": "MM",
  "မြန်မာ": "MM",
  "საქართველო": "GE",
  "ሃገረ ኤርትራ": "ER",
  "ኢትዮጵያ": "ET",
  "ኤርትራ": "ER",
  "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ": "ET",
  "ព្រះរាជាណាចក្រកម្ពុជា": "KH",
  "‏البحرين": "BH",
  "‏ليبيا": "LY",
  "ⵍⵎⴰⵖⵔⵉⴱ": "MA",
  "ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ": "MA",
  "スペイン": "ES",
  "スペイン王国": "ES",
  "中华人民共和国": "CN",
  "中华人民共和国澳门特别行政区": "MO",
  "中华人民共和国香港特别行政区": "HK",
  "中国": "CN",
  "中華民國": "TW",
  "台灣": "TW",
  "新加坡": "SG",
  "新加坡共和国": "SG",
  "日本": "JP",
  "澳门": "MO",
  "西班牙": "ES",
  "西班牙王国": "ES",
  "香港": "HK",
  "대한민국": "KR",
  "스페인": "ES",
  "에스파냐 왕국": "ES",
  "조선": "KP",
  "조선민주주의인민공화국": "KP",
  "한국": "KR"
}