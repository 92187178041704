// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

import { parseNIFOCR, validateNIF } from '../nif';

const nifRE = /^[0-9A-Z/]+$/;
const notnifRE = /[^0-9A-Z)]/g; // ) is sometimes parsed by the ocr

export const isValidTaxIdForCountry = (country: string, s: string): boolean => {
  if (s.startsWith('ES')) {
    return false;
  }
  if (s.length === 9 && (!country || country === 'ES')) {
    return validateNIF(s);
  } else {
    return s.match(nifRE) != null;
  }
};

export const parseTaxId = (s: string): string => {
  s = s.toUpperCase().replace(notnifRE, '');
  if (s.startsWith('ES')) {
    s = s.substr(2);
  }
  return parseNIFOCR(s);
};
