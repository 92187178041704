{
  "$": "USD",
  ".د.ب": "BHD",
  ".ރ": "MVR",
  "AED": "AED",
  "AFN": "AFN",
  "ALL": "ALL",
  "AMD": "AMD",
  "ANG": "ANG",
  "AOA": "AOA",
  "ARS": "ARS",
  "AUD": "AUD",
  "AWG": "AWG",
  "AZN": "AZN",
  "Afghan afghani": "AFN",
  "Albanian lek": "ALL",
  "Algerian dinar": "DZD",
  "Angolan kwanza": "AOA",
  "Ar": "MGA",
  "Argentine peso": "ARS",
  "Armenian dram": "AMD",
  "Aruban florin": "AWG",
  "Australian dollar": "AUD",
  "Azerbaijani manat": "AZN",
  "B/.": "PAB",
  "BAM": "BAM",
  "BBD": "BBD",
  "BDT": "BDT",
  "BGN": "BGN",
  "BHD": "BHD",
  "BIF": "BIF",
  "BMD": "BMD",
  "BND": "BND",
  "BOB": "BOB",
  "BRL": "BRL",
  "BSD": "BSD",
  "BTN": "BTN",
  "BWP": "BWP",
  "BYN": "BYN",
  "BZD": "BZD",
  "Bahamian Dollar": "BSD",
  "Bahraini dinar": "BHD",
  "Bangladeshi taka": "BDT",
  "Barbadian dollar": "BBD",
  "Belarusian ruble": "BYN",
  "Belize dollar": "BZD",
  "Bermudian dollar": "BMD",
  "Bhutanese ngultrum": "BTN",
  "Bolivian boliviano": "BOB",
  "Bosnia and Herzegovina convertible mark": "BAM",
  "Botswana pula": "BWP",
  "Br": "ETB",
  "Brazilian real": "BRL",
  "British pound": "GBP",
  "Brunei dollar": "BND",
  "Bs.": "BOB",
  "Bs.S.": "VES",
  "Bulgarian lev": "BGN",
  "Burmese kyat": "MMK",
  "Burundian franc": "BIF",
  "C$": "NIO",
  "CAD": "CAD",
  "CDF": "CDF",
  "CFP franc": "XPF",
  "CHF": "CHF",
  "CKD": "CKD",
  "CLP": "CLP",
  "CNY": "CNY",
  "COP": "COP",
  "CRC": "CRC",
  "CUC": "CUC",
  "CUP": "CUP",
  "CVE": "CVE",
  "CZK": "CZK",
  "Cambodian riel": "KHR",
  "Canadian dollar": "CAD",
  "Cape Verdean escudo": "CVE",
  "Cayman Islands dollar": "KYD",
  "Central African CFA franc": "XAF",
  "Chilean peso": "CLP",
  "Chinese yuan": "CNY",
  "Colombian peso": "COP",
  "Comorian franc": "KMF",
  "Congolese franc": "CDF",
  "Cook Islands dollar": "CKD",
  "Costa Rican colón": "CRC",
  "Croatian kuna": "HRK",
  "Cuban convertible peso": "CUC",
  "Cuban peso": "CUP",
  "Czech koruna": "CZK",
  "D": "GMD",
  "DH": "MAD",
  "DJF": "DJF",
  "DKK": "DKK",
  "DOP": "DOP",
  "DZD": "DZD",
  "Danish krone": "DKK",
  "Db": "STN",
  "Djiboutian franc": "DJF",
  "Dominican peso": "DOP",
  "EGP": "EGP",
  "ERN": "ERN",
  "ETB": "ETB",
  "EUR": "EUR",
  "Eastern Caribbean dollar": "XCD",
  "Egyptian pound": "EGP",
  "Eritrean nakfa": "ERN",
  "Esc": "CVE",
  "Ethiopian birr": "ETB",
  "Euro": "EUR",
  "E£": "EGP",
  "FC": "CDF",
  "FJD": "FJD",
  "FKP": "FKP",
  "FOK": "FOK",
  "Falkland Islands pound": "FKP",
  "Faroese króna": "FOK",
  "Fijian dollar": "FJD",
  "Fr": "XOF",
  "Fr.": "CHF",
  "Ft": "HUF",
  "G": "HTG",
  "GBP": "GBP",
  "GEL": "GEL",
  "GGP": "GGP",
  "GHS": "GHS",
  "GIP": "GIP",
  "GMD": "GMD",
  "GNF": "GNF",
  "GTQ": "GTQ",
  "GYD": "GYD",
  "Ghanaian cedi": "GHS",
  "Gibraltar pound": "GIP",
  "Guatemalan quetzal": "GTQ",
  "Guernsey pound": "GGP",
  "Guinean franc": "GNF",
  "Guyanese dollar": "GYD",
  "HKD": "HKD",
  "HNL": "HNL",
  "HRK": "HRK",
  "HTG": "HTG",
  "HUF": "HUF",
  "Haitian gourde": "HTG",
  "Honduran lempira": "HNL",
  "Hong Kong dollar": "HKD",
  "Hungarian forint": "HUF",
  "IDR": "IDR",
  "ILS": "ILS",
  "IMP": "IMP",
  "INR": "INR",
  "IQD": "IQD",
  "IRR": "IRR",
  "ISK": "ISK",
  "Icelandic króna": "ISK",
  "Indian rupee": "INR",
  "Indonesian rupiah": "IDR",
  "Iranian rial": "IRR",
  "Iraqi dinar": "IQD",
  "Israeli new shekel": "ILS",
  "JD": "JOD",
  "JEP": "JEP",
  "JMD": "JMD",
  "JOD": "JOD",
  "JPY": "JPY",
  "Jamaican dollar": "JMD",
  "Japanese yen": "JPY",
  "Jersey pound": "JEP",
  "Jordanian dinar": "JOD",
  "K": "PGK",
  "KES": "KES",
  "KGS": "KGS",
  "KHR": "KHR",
  "KID": "KID",
  "KMF": "KMF",
  "KPW": "KPW",
  "KRW": "KRW",
  "KWD": "KWD",
  "KYD": "KYD",
  "KZT": "KZT",
  "Kazakhstani tenge": "KZT",
  "Kenyan shilling": "KES",
  "Kiribati dollar": "KID",
  "Ks": "MMK",
  "Kuwaiti dinar": "KWD",
  "Kyrgyzstani som": "KGS",
  "Kz": "AOA",
  "Kč": "CZK",
  "L": "SZL",
  "LAK": "LAK",
  "LBP": "LBP",
  "LKR": "LKR",
  "LRD": "LRD",
  "LSL": "LSL",
  "LYD": "LYD",
  "Lao kip": "LAK",
  "Le": "SLL",
  "Lebanese pound": "LBP",
  "Lesotho loti": "LSL",
  "Liberian dollar": "LRD",
  "Libyan dinar": "LYD",
  "MAD": "MAD",
  "MDL": "MDL",
  "MGA": "MGA",
  "MK": "MWK",
  "MKD": "MKD",
  "MMK": "MMK",
  "MNT": "MNT",
  "MOP": "MOP",
  "MRU": "MRU",
  "MT": "MZN",
  "MUR": "MUR",
  "MVR": "MVR",
  "MWK": "MWK",
  "MXN": "MXN",
  "MYR": "MYR",
  "MZN": "MZN",
  "Macanese pataca": "MOP",
  "Malagasy ariary": "MGA",
  "Malawian kwacha": "MWK",
  "Malaysian ringgit": "MYR",
  "Maldivian rufiyaa": "MVR",
  "Manx pound": "IMP",
  "Mauritanian ouguiya": "MRU",
  "Mauritian rupee": "MUR",
  "Mexican peso": "MXN",
  "Moldovan leu": "MDL",
  "Mongolian tögrög": "MNT",
  "Moroccan dirham": "MAD",
  "Mozambican metical": "MZN",
  "NAD": "NAD",
  "NGN": "NGN",
  "NIO": "NIO",
  "NOK": "NOK",
  "NPR": "NPR",
  "NZD": "NZD",
  "Namibian dollar": "NAD",
  "Nepalese rupee": "NPR",
  "Netherlands Antillean guilder": "ANG",
  "New Taiwan dollar": "TWD",
  "New Zealand dollar": "NZD",
  "Nfk": "ERN",
  "Nicaraguan córdoba": "NIO",
  "Nigerian naira": "NGN",
  "North Korean won": "KPW",
  "Norwegian krone": "NOK",
  "Nu.": "BTN",
  "OMR": "OMR",
  "Omani rial": "OMR",
  "P": "BWP",
  "PAB": "PAB",
  "PEN": "PEN",
  "PGK": "PGK",
  "PHP": "PHP",
  "PKR": "PKR",
  "PLN": "PLN",
  "PYG": "PYG",
  "Pakistani rupee": "PKR",
  "Panamanian balboa": "PAB",
  "Papua New Guinean kina": "PGK",
  "Paraguayan guaraní": "PYG",
  "Peruvian sol": "PEN",
  "Philippine peso": "PHP",
  "Polish złoty": "PLN",
  "Pound sterling": "GBP",
  "Q": "GTQ",
  "QAR": "QAR",
  "Qatari riyal": "QAR",
  "R": "ZAR",
  "R$": "BRL",
  "RM": "MYR",
  "RON": "RON",
  "RSD": "RSD",
  "RUB": "RUB",
  "RWF": "RWF",
  "Romanian leu": "RON",
  "Rp": "IDR",
  "Rs": "ZAR",
  "Rs  රු": "LKR",
  "Russian ruble": "RUB",
  "Rwandan franc": "RWF",
  "S/.": "PEN",
  "SAR": "SAR",
  "SBD": "SBD",
  "SCR": "SCR",
  "SDG": "SDG",
  "SEK": "SEK",
  "SGD": "SGD",
  "SHP": "SHP",
  "SLL": "SLL",
  "SOS": "SOS",
  "SRD": "SRD",
  "SSP": "SSP",
  "STN": "STN",
  "SYP": "SYP",
  "SZL": "SZL",
  "Saint Helena pound": "SHP",
  "Samoan tālā": "WST",
  "Saudi riyal": "SAR",
  "Serbian dinar": "RSD",
  "Seychellois rupee": "SCR",
  "Sh": "UGX",
  "Sierra Leonean leone": "SLL",
  "Singapore dollar": "SGD",
  "Solomon Islands dollar": "SBD",
  "Somali shilling": "SOS",
  "South African rand": "ZAR",
  "South Korean won": "KRW",
  "South Sudanese pound": "SSP",
  "Sri Lankan rupee": "LKR",
  "Sudanese pound": "SDG",
  "Surinamese dollar": "SRD",
  "Swazi lilangeni": "SZL",
  "Swedish krona": "SEK",
  "Swiss franc": "CHF",
  "Syrian pound": "SYP",
  "São Tomé and Príncipe dobra": "STN",
  "T": "WST",
  "T$": "TOP",
  "THB": "THB",
  "TJS": "TJS",
  "TMT": "TMT",
  "TND": "TND",
  "TOP": "TOP",
  "TRY": "TRY",
  "TTD": "TTD",
  "TVD": "TVD",
  "TWD": "TWD",
  "TZS": "TZS",
  "Tajikistani somoni": "TJS",
  "Tanzanian shilling": "TZS",
  "Thai baht": "THB",
  "Tongan paʻanga": "TOP",
  "Trinidad and Tobago dollar": "TTD",
  "Tunisian dinar": "TND",
  "Turkish lira": "TRY",
  "Turkmenistan manat": "TMT",
  "Tuvaluan dollar": "TVD",
  "UAH": "UAH",
  "UGX": "UGX",
  "UM": "MRU",
  "USD": "USD",
  "UYU": "UYU",
  "UZS": "UZS",
  "Ugandan shilling": "UGX",
  "Ukrainian hryvnia": "UAH",
  "United Arab Emirates dirham": "AED",
  "United State Dollar": "USD",
  "United States dollar": "USD",
  "Uruguayan peso": "UYU",
  "Uzbekistani soʻm": "UZS",
  "VES": "VES",
  "VND": "VND",
  "VUV": "VUV",
  "Vanuatu vatu": "VUV",
  "Venezuelan bolívar soberano": "VES",
  "Vietnamese đồng": "VND",
  "Vt": "VUV",
  "WST": "WST",
  "West African CFA franc": "XOF",
  "XAF": "XAF",
  "XCD": "XCD",
  "XOF": "XOF",
  "XPF": "XPF",
  "YER": "YER",
  "Yemeni rial": "YER",
  "ZAR": "ZAR",
  "ZK": "ZMW",
  "ZMW": "ZMW",
  "ZWB": "ZWB",
  "Zambian kwacha": "ZMW",
  "Zimbabwean bonds": "ZWB",
  "dalasi": "GMD",
  "den": "MKD",
  "denar": "MKD",
  "dollar": "USD",
  "kn": "HRK",
  "kr": "SEK",
  "kr.": "DKK",
  "krone": "NOK",
  "lari": "GEL",
  "lei": "RON",
  "m": "TMT",
  "so'm": "UZS",
  "zł": "PLN",
  "£": "GBP",
  "¥": "JPY",
  "ƒ": "ANG",
  "ЅМ": "TJS",
  "дин.": "RSD",
  "лв": "BGN",
  "с": "KGS",
  "֏": "AMD",
  "؋": "AFN",
  "د.إ": "AED",
  "د.ا": "JOD",
  "د.ت": "TND",
  "د.ج": "DZD",
  "د.ك": "KWD",
  "د.م.": "MAD",
  "دج": "DZD",
  "ر.س": "SAR",
  "ر.ع.": "OMR",
  "ر.ق": "QAR",
  "ع.د": "IQD",
  "ل.د": "LYD",
  "ل.ل": "LBP",
  "৳": "BDT",
  "฿": "THB",
  "៛": "KHR",
  "₡": "CRC",
  "₣": "XPF",
  "₦": "NGN",
  "₨": "SCR",
  "₩": "KPW",
  "₪": "ILS",
  "₫": "VND",
  "€": "EUR",
  "₭": "LAK",
  "₮": "MNT",
  "₱": "PHP",
  "₲": "PYG",
  "₴": "UAH",
  "₵": "GHS",
  "₸": "KZT",
  "₹": "INR",
  "₺": "TRY",
  "₼": "AZN",
  "₽": "RUB",
  "₾": "GEL",
  "﷼": "YER"
}