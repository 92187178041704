import React from 'react';
import { useSyncExternalStore } from 'react';

import { useLocation } from 'react-router';
import { produce } from "immer"
import { navigate } from './lib/utilBrowser';
import { Alert, IconButton, Snackbar, Tooltip } from '@mui/material';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Close from '@mui/icons-material/Close';

interface WorklistItem {
  visited: boolean;
  pathname: string;
}

interface WorklistData {
  startPath: string;
  items: WorklistItem[];
}

let worklist: WorklistData = window.localStorage.getItem("worklist") ? JSON.parse(window.localStorage.getItem("worklist")!) : { startPath: "/", items: [] };

type Listener = () => void;
let listeners: Listener[] = []

function store() {
  window.localStorage.setItem("worklist", JSON.stringify(worklist));
}

function getSnapshot(): WorklistData {
  return worklist;
}

function subscribe(l: Listener): () => void {
  listeners.push(l);
  return () => {
    listeners = listeners.filter((x) => x != l);
  }
}

function notifiyListers() {
  for (const l of listeners) {
    l();
  }
}

function markVisited(pathname: string) {
  const next = produce(worklist, (draft) => {
    if (draft) {
      for (const d of draft.items) {
        if (d.pathname === location.pathname) {
          d.visited = true;
        }
      }
    }
  });
  if (next !== worklist) {
    worklist = next;
    store();
    notifiyListers();
  }
}

function removeWorklist() {
  const next = produce(worklist, (draft) => {
    if (draft) {
      draft.items = [];
    }
  });
  worklist = next;
  store();
  notifiyListers();
}

export function startWorklist(pathnames: string[], event?: React.MouseEvent) {
  if (pathnames.length <= 1) {
    worklist = {
      startPath: window.location.pathname,
      items: [],
    };
  } else {
    worklist = {
      startPath: window.location.pathname,
      items: pathnames.map((pathname) => ({ pathname, visited: false })),
    };
  }
  store();
  notifiyListers();
  console.log(event);
  if (pathnames.length > 0) {
    navigate({ pathname: pathnames[0], event })
  }
}

export function worklistGoNextIfExists(): boolean {
  const items = worklist.items;
  const next = items.find((p) => !p.visited);
  if (next) {
    navigate({ pathname: next!.pathname });
    return true;
  } else {
    if (items.length > 0) {
      navigate({ pathname: worklist.startPath });
      removeWorklist();
      return true;
    } else {
      return false;
    }
  }
}

export const Worklist = (props: {}) => {
  let location = useLocation();
  const worklist = useSyncExternalStore(subscribe, getSnapshot);
  React.useEffect(() => {
    markVisited(location.pathname);
  }, [location]);
  const items = worklist.items ?? [];
  const visited = items.reduce((prev, c) => c.visited ? prev + 1 : prev, 0);
  const next = items.find((p) => !p.visited);
  const goNext = React.useCallback(() => { if (next) { navigate({ pathname: next.pathname }) } }, [next]);
  const close = React.useCallback((event: { ctrlKey: boolean }) => {
    if (!event.ctrlKey) {
      navigate({ pathname: worklist!.startPath });
    }
    removeWorklist()
  }, [worklist?.startPath]);
  const onKeyDown = React.useCallback((e: KeyboardEvent) => {
    if (e.code === 'KeyN' && e.altKey) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      goNext()
    } else if (e.code === 'KeyC' && e.altKey) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      close(e);
    }
  }, [goNext, close]);
  React.useEffect(() => {
    const cb = onKeyDown;
    window.addEventListener("keydown", cb, true);
    return () => {
      window.removeEventListener("keydown", cb, true);
    }
  }, [onKeyDown]);


  return <Snackbar open={items.length > 0} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
    <Alert severity="info" action={<>
      <Tooltip title="Next (Option+N)">
        <IconButton key="next" size="small" disabled={!next} onClick={goNext}><NavigateNext /></IconButton>
      </Tooltip>
      <Tooltip title="Close (Option+C)">
        <IconButton key="close" size="small" onClick={close}><Close /></IconButton>
      </Tooltip>
    </>}>
      {visited} / {items.length}
    </Alert>
  </Snackbar>
}