import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import CssBaseline from '@mui/material/CssBaseline';
import { client } from './client';
import { ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { SignInScreen } from './auth';
import { initErrorReporter } from './lib/errorReporter';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import gql from 'graphql-tag';
import moment from 'moment';
import { history } from './lib/utilBrowser';
import ErrorReporter from './lib/errorReporter';
import { HTTP_URL } from './config';

import 'moment/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledEngineProvider } from '@mui/material';
moment.locale('es');

initErrorReporter();

interface AppState {
  userId: string | null;
}

(window as any)['graphql'] = client;
(window as any)['gql'] = gql;
(window as any)['ABAQ_API'] = HTTP_URL;

class AppScreen extends React.Component<{}, AppState> {
  state = {
    userId: null,
  } as AppState;

  render = () => {
    return (
      <SignInScreen
        onUserLogged={(userId: string) => {
          this.setState({ userId });
        }}
      >
        <ApolloProvider client={client}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router history={history}>
              <NiceModal.Provider>
                <App userId={this.state.userId} />
              </NiceModal.Provider>
              <ErrorReporter />
            </Router>
          </LocalizationProvider>
        </ApolloProvider>
      </SignInScreen>
    );
  }
}

const domNode = document.getElementById('root');
const root = createRoot(domNode!);

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import NiceModal from '@ebay/nice-modal-react';

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppScreen />
    </ThemeProvider>
  </StyledEngineProvider>
  ,
);
