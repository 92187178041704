/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppType {
  AEAT = "AEAT",
  BANK = "BANK",
  CARD = "CARD",
  FD1 = "FD1",
  FD2 = "FD2",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  STRIPE = "STRIPE",
}

export enum DiscountAuditType {
  fullyApplied = "fullyApplied",
  partiallyApplied = "partiallyApplied",
  redeemed = "redeemed",
}

export enum DocChangeType {
  CREATED = "CREATED",
  DELETED = "DELETED",
  UNDELETED = "UNDELETED",
  UPDATED = "UPDATED",
}

export enum DocStatus {
  ACCOUNTED = "ACCOUNTED",
  IRRELEVANT = "IRRELEVANT",
  PENDING = "PENDING",
  REVIEW = "REVIEW",
}

export enum DocType {
  ACTIONS = "ACTIONS",
  DATA = "DATA",
  DIGITAL = "DIGITAL",
  IMAGE = "IMAGE",
  STANDALONE = "STANDALONE",
}

export enum ExtractionContentType {
  AUTONOMOS_RECEIPT = "AUTONOMOS_RECEIPT",
  BANK = "BANK",
  DOC = "DOC",
  INVOICE = "INVOICE",
  INVOICE_V2 = "INVOICE_V2",
  INVOICE_V3 = "INVOICE_V3",
  OTHER_DOC = "OTHER_DOC",
  PAYROLL = "PAYROLL",
  REJECT = "REJECT",
  UNRELATED = "UNRELATED",
}

export enum ExtractionMode {
  AI = "AI",
  MAIN = "MAIN",
}

export enum ExtractionTaskType {
  IGNORED = "IGNORED",
  MESSAGE = "MESSAGE",
  UNREADABLE = "UNREADABLE",
}

export enum FNMTStatus {
  COMPLETED = "COMPLETED",
  NONE = "NONE",
  REQUESTED = "REQUESTED",
}

export enum FormFieldType {
  Bool = "Bool",
  Button = "Button",
  Date = "Date",
  Eur = "Eur",
  IbanAEAT = "IbanAEAT",
  Int = "Int",
  LongText = "LongText",
  Markdown = "Markdown",
  Naf = "Naf",
  Percent = "Percent",
  Phone = "Phone",
  Select = "Select",
  Signature = "Signature",
  Text = "Text",
  UploadDocument = "UploadDocument",
}

export enum FriendStatus {
  APPLIED = "APPLIED",
  PENDING = "PENDING",
  READY = "READY",
}

export enum Icon {
  Aeat = "Aeat",
  Attachment = "Attachment",
  Bell = "Bell",
  Book = "Book",
  Chat = "Chat",
  ClavePin = "ClavePin",
  Clear = "Clear",
  Clock = "Clock",
  FacturaDirecta = "FacturaDirecta",
  Fuzzy = "Fuzzy",
  GoogleDrive = "GoogleDrive",
  Payment = "Payment",
  Pencil = "Pencil",
  Photo = "Photo",
  Plug = "Plug",
  QuestionMark = "QuestionMark",
  School = "School",
  Send = "Send",
  Star = "Star",
  Tax = "Tax",
  ThumbDown = "ThumbDown",
  ThumbUp = "ThumbUp",
  ThumbsUpDown = "ThumbsUpDown",
}

export enum InvoiceCategory {
  GASOLINE = "GASOLINE",
  NO_CATEGORY = "NO_CATEGORY",
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
}

export enum InvoiceRectification {
  NO = "NO",
  REPLACEMENT = "REPLACEMENT",
}

export enum InvoiceType {
  ISP = "ISP",
  NORMAL = "NORMAL",
}

export enum InvoiceV3Category {
  GASOLINE = "GASOLINE",
  HEALTH = "HEALTH",
  MEAL = "MEAL",
  NO_CATEGORY = "NO_CATEGORY",
  PRODUCT = "PRODUCT",
  RENT = "RENT",
  SERVICE = "SERVICE",
  SS = "SS",
}

export enum NotificationsOrderColumn {
  DATE = "DATE",
  REFERENCE = "REFERENCE",
}

export enum PaymentStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  NONE = "NONE",
  SETUP = "SETUP",
  UPDATED = "UPDATED",
}

export enum QueryOrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SubscriptionPeriod {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export enum TaskOrigin {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export enum TaskSeverity {
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum TaskStatus {
  COMPLETED = "COMPLETED",
  DELAYED = "DELAYED",
  HIDDEN = "HIDDEN",
  LOCKED = "LOCKED",
  PENDING = "PENDING",
}

export enum TaskVisibility {
  ADVISOR = "ADVISOR",
  USER = "USER",
}

export enum UploadDocumentType {
  NifBack = "NifBack",
  NifFront = "NifFront",
}

/**
 * Notification messages made from server to customers
 */
export enum UserNotificationChannel {
  CALL = "CALL",
  EMAIL = "EMAIL",
  INAPP = "INAPP",
  PUSH = "PUSH",
}

export enum UserProfile {
  ADVISOR = "ADVISOR",
  TRANSCRIPTOR = "TRANSCRIPTOR",
  USER = "USER",
}

export interface AutonomosReceiptExtraction {
  date: ExtractionValue;
  netTotal: ExtractionValue;
}

export interface BankExtraction {
  bank: ExtractionValue;
  amount: ExtractionValue;
  direction: ExtractionValue;
  date: ExtractionValue;
  concept: ExtractionValue;
  contact: ExtractionValue;
  name: ExtractionValue;
  currency: ExtractionValue;
  category: ExtractionValue;
  iban?: ExtractionValue | null;
  docNumber?: ExtractionValue | null;
}

export interface DocExtraction {
  docType: ExtractionValue;
  id: ExtractionValue;
}

export interface ExpenseSyncJobConfig {
  app: string;
  fromDate?: string | null;
  toDate?: string | null;
}

export interface Extraction {
  contentType: ExtractionContentType;
  userTaskType: ExtractionTaskType;
  userMessage: string;
  userHelpLink?: string | null;
  userSolutions?: string | null;
  todo: string;
  confidence?: number | null;
  invoice?: InvoiceExtraction | null;
  invoiceV2?: InvoiceV2Extraction | null;
  invoiceV3?: InvoiceV3Extraction | null;
  payroll?: PayrollExtraction | null;
  bank?: BankExtraction | null;
  doc?: DocExtraction | null;
  reject?: RejectExtraction | null;
  autonomosReceipt?: AutonomosReceiptExtraction | null;
  metadata?: any | null;
}

export interface ExtractionTax {
  tax: ExtractionValue;
  amount: ExtractionValue;
  rate: ExtractionValue;
  base: ExtractionValue;
}

export interface ExtractionValue {
  extracted: boolean;
  positions: number[];
  page: number;
  raw: string;
  value?: string | null;
  confidence?: number | null;
}

export interface InvoiceExtraction {
  category: ExtractionValue;
  rectification: ExtractionValue;
  invoiceType: ExtractionValue;
  number: ExtractionValue;
  date: ExtractionValue;
  currency: ExtractionValue;
  senderId: ExtractionValue;
  senderName: ExtractionValue;
  senderCountry: ExtractionValue;
  senderZipcode: ExtractionValue;
  receiverId: ExtractionValue;
  receiverName: ExtractionValue;
  receiverCountry: ExtractionValue;
  receiverZipcode: ExtractionValue;
  iva21Amount: ExtractionValue;
  iva10Amount: ExtractionValue;
  iva10Base?: ExtractionValue | null;
  iva4Amount: ExtractionValue;
  iva4Base?: ExtractionValue | null;
  iva5Amount?: ExtractionValue | null;
  iva5Base?: ExtractionValue | null;
  iva0Base?: ExtractionValue | null;
  ivaREAmount?: ExtractionValue | null;
  irpfAmount: ExtractionValue;
  irpfPercent: ExtractionValue;
  netTotal: ExtractionValue;
  grossTotal: ExtractionValue;
  _category?: InvoiceCategory | null;
  _categoryv3?: InvoiceV3Category | null;
  _invoiceType?: InvoiceType | null;
  _invoiceRectification?: InvoiceRectification | null;
}

export interface InvoiceV2Extraction {
  direction: ExtractionValue;
  date: ExtractionValue;
  contact: ExtractionValue;
  name: ExtractionValue;
  country: ExtractionValue;
  zipcode: ExtractionValue;
  currency: ExtractionValue;
  number: ExtractionValue;
  type: ExtractionValue;
  total: ExtractionValue;
  taxes: ExtractionTax[];
  disbursement: ExtractionValue;
  category: ExtractionValue;
  confidence?: number | null;
}

export interface InvoiceV3Extraction {
  format: ExtractionValue;
  annotation: ExtractionValue;
  direction: ExtractionValue;
  date: ExtractionValue;
  contact: ExtractionValue;
  name: ExtractionValue;
  country: ExtractionValue;
  zipcode: ExtractionValue;
  currency: ExtractionValue;
  number: ExtractionValue;
  invoiceType: ExtractionValue;
  total: ExtractionValue;
  taxes: ExtractionTax[];
  disbursement: ExtractionValue;
  category: ExtractionValue;
}

export interface KeyValue {
  key: string;
  value: string;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export interface PayrollExtraction {
  contact: ExtractionValue;
  name: ExtractionValue;
  date: ExtractionValue;
  total: ExtractionValue;
}

export interface RejectExtraction {
  reason: ExtractionValue;
}

export interface UserNotificationMessage {
  title: string;
  body: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
