// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project

const nifRE = /^[0-9A-Z/]+$/;
const notnifRE = /[^0-9A-Z)]/g; // ) is sometimes parsed by the ocr

export const isValidBankContact = (s: string): boolean => {
  return s.match(nifRE) != null;
};

export const parseBankContact = (s: string): string => {
  s = s.toUpperCase().replace(notnifRE, '');
  return s;
};
