import { InvoiceCategory, InvoiceRectification, InvoiceType, InvoiceV3Category } from '../../../gen/globalTypes';
import { isValidAmount, parseAmount } from '../../../gen/common/parsers/parseAmount';
import { isValidDate, parseDate } from '../../../gen/common/parsers/parseDate';
import { isValidCurrency, parseCurrency } from '../../../gen/common/parsers/parseCurrency';
import { isValidCountry, parseCountry } from '../../../gen/common/parsers/parseCountry';
import { isValidZipcode as isValidZipcodeForCountry, parseZipcode } from '../../../gen/common/parsers/parseZipcode';
import { isValidName, parseName } from '../../../gen/common/parsers/parseName';
import { isValidPercent, parsePercent } from '../../../gen/common/parsers/parsePercent';
import { isValidTaxIdForCountry, parseTaxId } from '../../../gen/common/parsers/parseTaxId';
import { isValidCode, parseCode } from '../../../gen/common/parsers/parseCode';
import { isValidCode3, parseCode3 } from '../../../gen/common/parsers/parseCode3';
import { isValidBankContact, parseBankContact } from '../../../gen/common/parsers/parseBankContact';

export type ExtractionContext = { [s: string]: string };

export interface Field {
  id: string;
  name: string;
  isValid: (s: string, context: ExtractionContext) => boolean;
  parse: (raw: string, context: ExtractionContext) => string;
  choices?: { v: string, h: string }[];
  header?: string;
  isArray?: true;
  children?: Field[]
}

export const isValidTaxId = (countryField: string) => (s: string, context: ExtractionContext): boolean => {
  const country = context[countryField];
  return isValidTaxIdForCountry(country, s);
};

export const isValidTaxIdForES = (s: string, context: ExtractionContext): boolean => {
  return isValidTaxIdForCountry("ES", s);
};

export const isValidZipcode = (countryField: string) => (s: string, context: ExtractionContext): boolean => {
  const country = context[countryField];
  return isValidZipcodeForCountry(country, s);
};

interface Choice {
  v: string, // value
  h: string //human string
}
const CATEGORY_CHOICES: Choice[] = [
  { v: InvoiceCategory.PRODUCT, h: "Producto" },
  { v: InvoiceCategory.SERVICE, h: "Servicio" },
  { v: InvoiceCategory.GASOLINE, h: "Gasolina" }
];

const CATEGORY_V3_CHOICES: Choice[] = [
  { v: InvoiceV3Category.PRODUCT, h: "Producto" },
  { v: InvoiceV3Category.SERVICE, h: "Servicio" },
  { v: InvoiceV3Category.GASOLINE, h: "Gasolina" },
  { v: InvoiceV3Category.MEAL, h: "Dieta" },
  { v: InvoiceV3Category.RENT, h: "Alquiler" },
  { v: InvoiceV3Category.HEALTH, h: "Seguro salud" },
  { v: InvoiceV3Category.SS, h: "Seguridad social" },
];

const TAX_CHOICES = [
  { v: "IVA", h: "IVA" },
  { v: "IRPF", h: "IRPF" },
  { v: "IVARE", h: "Recargo Equivalencia" },
  { v: "IGIC", h: "IGIC/IPSI" },
];

const DIRECTIONS = [{ v: "send", h: "Enviada" }, { v: "receive", h: "Recibida" }];

const RECTIFICATION_CHOICES = [{ v: InvoiceRectification.NO, h: "No" }, { v: InvoiceRectification.REPLACEMENT, h: "Reemplazo" }];

const INVOICEV2_TIPO = [
  { v: "F1", h: "Factura" },
  { v: "F2", h: "Simplificada" },
  { v: "F3", h: "Tickets agrupados" },
  { v: "R1", h: "Rectificativa" },
  { v: "R5", h: "Simplificada Rectificativa" },
  { v: "SF", h: "Sin factura" }];

const INVOICETYPE_CHOICES = [
  { v: InvoiceType.NORMAL, h: "Normal" },
  { v: InvoiceType.ISP, h: "ISP" },
  // InvoiceType.CAJA,
  // InvoiceType.TRANSITARIO,
];

const isValidChoice = (choices: Choice[]) => (v: string) => choices.some((c) => c.v === v);
const parseChoice = (choices: Choice[]) => () => choices[0].v;

export const invoiceFields: Field[] = [
  { id: 'number', name: 'Núm. Factura', isValid: isValidCode, parse: parseCode },
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },

  { id: 'senderId', name: 'NIF emisor', isValid: isValidTaxId('senderCountry'), parse: parseTaxId, header: 'Emisor' },
  { id: 'senderName', name: 'Nombre emisor', isValid: isValidName, parse: parseName },
  { id: 'senderCountry', name: 'País emisor', isValid: isValidCountry, parse: parseCountry },
  { id: 'senderZipcode', name: 'Código postal emisor', isValid: isValidZipcode('senderCountry'), parse: parseZipcode },

  {
    id: 'receiverId', name: 'NIF receptor', isValid: isValidTaxId('receiverCountry'), parse: parseTaxId,
    header: 'Receptor'
  },
  { id: 'receiverName', name: 'Nombre receptor', isValid: isValidName, parse: parseName },
  { id: 'receiverCountry', name: 'País receptor', isValid: isValidCountry, parse: parseCountry },
  {
    id: 'receiverZipcode', name: 'Código postal receptor', isValid: isValidZipcode('receiverCountry'),
    parse: parseZipcode
  },

  { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency, header: 'Totales' },
  { id: 'grossTotal', name: 'Total sin impuestos', isValid: isValidAmount, parse: parseAmount },
  { id: 'netTotal', name: 'Total con impuestos', isValid: isValidAmount, parse: parseAmount },

  { id: 'iva21Amount', name: 'Cuota IVA 21%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva10Amount', name: 'Cuota IVA 10%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva10Base', name: 'Base IVA 10% (EUR)', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva5Amount', name: 'Cuota IVA 5%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva5Base', name: 'Base IVA 5% (EUR)', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva4Amount', name: 'Cuota IVA 4%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva4Base', name: 'Base IVA 4% (EUR)', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva0Base', name: 'Base IVA 0% (EUR)', isValid: isValidAmount, parse: parseAmount },

  { id: 'ivaREAmount', name: 'Cuota IVA RE (EUR)', isValid: isValidAmount, parse: parseAmount },

  // { id: 'ivaForeign', name: 'Cuota IVA Extranjero' },

  { id: 'irpfAmount', name: 'Retención IRPF', isValid: isValidAmount, parse: parseAmount },
  { id: 'irpfPercent', name: 'Retención IRPF porcentaje', isValid: isValidPercent, parse: parsePercent },

  {
    id: 'category', name: 'Categoria', choices: CATEGORY_CHOICES,
    isValid: isValidChoice(CATEGORY_CHOICES), parse: parseChoice(CATEGORY_CHOICES)
  },
  {
    id: 'rectification', name: 'Rectificativa', choices: RECTIFICATION_CHOICES,
    isValid: isValidChoice(RECTIFICATION_CHOICES), parse: parseChoice(RECTIFICATION_CHOICES)
  },
  {
    id: 'invoiceType', name: 'Tipo', choices: INVOICETYPE_CHOICES,
    isValid: isValidChoice(INVOICETYPE_CHOICES), parse: parseChoice(INVOICETYPE_CHOICES)
  },

];

export const invoiceV2Fields: Field[] = [
  { id: 'direction', name: 'Sentido', isValid: isValidChoice(DIRECTIONS), parse: parseChoice(DIRECTIONS), choices: DIRECTIONS },
  {
    id: 'type', name: 'Tipo', choices: INVOICEV2_TIPO,
    isValid: isValidChoice(INVOICEV2_TIPO), parse: parseChoice(INVOICEV2_TIPO)
  },
  { id: 'number', name: 'Núm. Factura', isValid: isValidCode, parse: parseCode },
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },

  { id: 'contact', name: 'NIF', isValid: isValidTaxId('country'), parse: parseTaxId },
  { id: 'name', name: 'Nombre', isValid: isValidName, parse: parseName },
  { id: 'country', name: 'País', isValid: isValidCountry, parse: parseCountry },
  { id: 'zipcode', name: 'Código postal', isValid: isValidZipcode('country'), parse: parseZipcode },

  { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency, header: 'Totales' },
  { id: 'total', name: 'Total factura', isValid: isValidAmount, parse: parseAmount },
  { id: 'disbursement', name: 'Suplido', isValid: isValidAmount, parse: parseAmount },
  {
    id: 'taxes', name: 'Impuestos', isValid: () => true, parse: () => "", header: "Impuestos", isArray: true, children: [
      { id: "tax", name: "Impuesto", choices: TAX_CHOICES, isValid: isValidChoice(TAX_CHOICES), parse: parseChoice(TAX_CHOICES) },
      { id: "rate", name: "Porcentaje", isValid: isValidPercent, parse: parsePercent },
      { id: "amount", name: "Cantidad", isValid: isValidAmount, parse: parseAmount },
      { id: "base", name: "Base", isValid: isValidAmount, parse: parseAmount },
    ]
  },
  {
    header: "Info",
    id: 'category', name: 'Categoria', choices: CATEGORY_CHOICES,
    isValid: isValidChoice(CATEGORY_CHOICES), parse: parseChoice(CATEGORY_CHOICES)
  },
];

export const autonomosReceiptFields: Field[] = [
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },
  { id: 'netTotal', name: 'Total', isValid: isValidAmount, parse: parseAmount },
];

const FORMAT_CHOICES = [
  { v: "INVOICE", h: "Factura" },
  { v: "ESTIMATE", h: "Presupuesto/Proforma" },
  { v: "SHIPPING", h: "Albarán/Envío" },
  { v: "PAYMENT", h: "Pago" },
  { v: "INFORMAL", h: "Informal" },
];

// const ISSUER_CHOICES = [
//   { v: "NORMAL", h: "Normal" },
//   { v: "REVERSE", h: "Reverse" },
//   { v: "THIRD", h: "Tercero" },
// ];

const ANNOTATION_CHOICES = [
  { v: "REVERSE", h: "ISP" },
  { v: "EXEMPT", h: "Exenta" },
  { v: "REBU", h: "REBU" },
  { v: "IOSS", h: "IOSS" },
  { v: "UOSS", h: "UOSS" },
  { v: "RECC", h: "RECC" },
  { v: "NO", h: "Sin" },
];

const INVOICE_TYPE_V3 = [
  { v: "F1", h: "Factura" },
  { v: "F2", h: "Simplificada" },
  { v: "F3", h: "Sustituye ticket" },
  { v: "F4", h: "Tickets agrupados" },
  { v: "R1", h: "Rectificativa" },
  { v: "R5", h: "Simplificada Rectificativa" },
  { v: "SF", h: "Sin factura" }
];

const DIRECTIONS_V3 = [{ v: "SEND", h: "Enviada" }, { v: "RECEIVE", h: "Recibida" }];

export const invoiceV3Fields: Field[] = [
  { id: 'number', name: 'Núm. Factura', isValid: isValidCode3, parse: parseCode3 },
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },
  { id: "format", name: "Formato", isValid: isValidChoice(FORMAT_CHOICES), parse: parseChoice(FORMAT_CHOICES), choices: FORMAT_CHOICES },
  { id: "annotation", name: "Anotación", isValid: isValidChoice(ANNOTATION_CHOICES), parse: parseChoice(ANNOTATION_CHOICES), choices: ANNOTATION_CHOICES },
  { id: "direction", name: "Dirección", isValid: isValidChoice(DIRECTIONS_V3), parse: parseChoice(DIRECTIONS_V3), choices: DIRECTIONS_V3 },
  {
    id: 'invoiceType', name: 'Tipo', choices: INVOICE_TYPE_V3,
    isValid: isValidChoice(INVOICE_TYPE_V3), parse: parseChoice(INVOICE_TYPE_V3)
  },
  { id: 'contact', name: 'NIF', isValid: isValidTaxId('country'), parse: parseTaxId },
  { id: 'name', name: 'Nombre', isValid: isValidName, parse: parseName },
  { id: 'country', name: 'País', isValid: isValidCountry, parse: parseCountry },
  { id: 'zipcode', name: 'Código postal', isValid: isValidZipcode('country'), parse: parseZipcode },

  { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency, header: 'Totales' },
  { id: 'total', name: 'Total factura', isValid: isValidAmount, parse: parseAmount },
  { id: 'disbursement', name: 'Suplido', isValid: isValidAmount, parse: parseAmount },
  {
    id: 'taxes', name: 'Impuestos', isValid: () => true, parse: () => "", header: "Impuestos", isArray: true, children: [
      { id: "tax", name: "Impuesto", choices: TAX_CHOICES, isValid: isValidChoice(TAX_CHOICES), parse: parseChoice(TAX_CHOICES) },
      { id: "rate", name: "Porcentaje", isValid: isValidPercent, parse: parsePercent },
      { id: "amount", name: "Cantidad", isValid: isValidAmount, parse: parseAmount },
      { id: "base", name: "Base", isValid: isValidAmount, parse: parseAmount },
    ]
  },
  {
    header: "Info",
    id: 'category', name: 'Categoria', choices: CATEGORY_V3_CHOICES,
    isValid: isValidChoice(CATEGORY_V3_CHOICES), parse: parseChoice(CATEGORY_V3_CHOICES)
  },
];

const DOC_TYPE_CHOICES = [
  { v: "DUA", h: "DUA" },
  { v: "AEAT", h: "AEAT" },
  { v: "OTHER", h: "Otro" },
];

export const docFields: Field[] = [
  { id: "docType", name: "tipo", isValid: isValidChoice(DOC_TYPE_CHOICES), parse: parseChoice(DOC_TYPE_CHOICES), choices: DOC_TYPE_CHOICES },
  { id: "id", name: "Identificador", isValid: isValidCode3, parse: parseCode3 },
];

export const bankFields: Field[] = [
  { id: "bank", name: "Banco", isValid: isValidName, parse: parseName },
  { id: "amount", name: "Total", isValid: isValidAmount, parse: parseAmount },
  { id: "direction", name: "Sentido", isValid: isValidChoice(DIRECTIONS_V3), parse: parseChoice(DIRECTIONS_V3), choices: DIRECTIONS_V3 },
  { id: "date", name: "Fecha", isValid: isValidDate, parse: parseDate },
  { id: "concept", name: "Concepto", isValid: isValidName, parse: parseName },
  { id: "contact", name: "Contacto", isValid: isValidBankContact, parse: parseBankContact }, // FIXME validate taxid
  { id: "name", name: "Nombre", isValid: isValidName, parse: parseName },
  { id: "currency", name: "Moneda", isValid: isValidCurrency, parse: parseCurrency },
  {
    id: "category", name: "Categoría", choices: CATEGORY_V3_CHOICES,
    isValid: isValidChoice(CATEGORY_V3_CHOICES), parse: parseChoice(CATEGORY_V3_CHOICES),
  }
];

export const payrollFields: Field[] = [
  { id: "contact", name: "NIF", isValid: isValidTaxIdForES, parse: parseTaxId },
  { id: "name", name: "Nombre", isValid: isValidName, parse: parseName },
  { id: "date", name: "Fecha", isValid: isValidDate, parse: parseDate },
  { id: "total", name: "Total", isValid: isValidAmount, parse: parseAmount },
];

const REJECT_CHOICES = [
  { v: "UNREADABLE", h: "No leíble" },
  { v: "PARTIAL", h: "No se puede leer todo" },
  { v: "UNRELATED", h: "No relacionado" },
  { v: "EXCERPT", h: "Faltan páginas" },
  { v: "MULTIPLE", h: "Varios documentos" },
]

export const rejectFields: Field[] = [
  { id: 'reason', name: "Razón", isValid: isValidChoice(REJECT_CHOICES), parse: parseChoice(REJECT_CHOICES), choices: REJECT_CHOICES },
];