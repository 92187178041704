import { AnnotateImageResponse as Annotate } from '../../lib/textAnnotation';
import {
  Link as MUILink, Button, InputAdornment, FormControlLabel,
  Checkbox, CardHeader, CardContent, Card, Typography, Popper, PopperProps, IconButton,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Loading } from '../../widgets/loading';
import { VisionViewer } from './visionViewer';
import * as React from 'react';
import Pbf from 'pbf';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import TextField from '@mui/material/TextField';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorIcon from '@mui/icons-material/Error';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import { Extraction, ExtractionContentType, ExtractionTaskType, ExtractionValue, InvoiceV2Extraction, InvoiceV3Extraction } from '../../gen/globalTypes';
import { onGenericError } from '../../lib/errorReporter';
import { invoiceFields, Field, bankFields, ExtractionContext, autonomosReceiptFields, invoiceV2Fields, invoiceV3Fields, payrollFields, rejectFields, docFields } from './lib/fields';
import { formatDateTime, formatDate } from '../../lib/util';
import { Link } from 'react-router-dom';
import { AnnotateImageResponse, TextAnnotation } from '../../lib/gvision';
import { autocompleteTasks, AutocompleteTask } from './autocompleteTasks';
import _ from 'lodash';
import { useImmer } from 'use-immer';
import produce from 'immer';
import makeStyles from '@mui/styles/makeStyles';
import { gql, useQuery } from '@apollo/client';
import Person from '@mui/icons-material/Person';
import { getIntercomUserUrlByIntercomId } from '../../lib/intercom';
import { QEditorUsers, QEditorUsersVariables } from '../../gen/QEditorUsers';

const ZOOMS = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500]; // chrome zooms

interface Props {
  docId: string;
  imagePages: Array<{ imageUrl: string, visionUrl: string }>;
  imageOriginalPages: number;
  initialValue: Extraction;
  receivedDate: string;
  lockDate?: string | null;
  lockedBy?: string | null;
  editor: boolean;
  lockNewOnResolve?: boolean;
  companyId: string;
  masterAccounting: string;
  companyName: string | null;
  taxId: string | null;
  close: () => void;
  onSave: (extraction: Extraction, lockNewExtraction: boolean) => void;
  onDiscardTraining: (lockNewExtraction: boolean) => Promise<void>;
  onSelected?: (text: string) => void;
  keepCompany?: string;
  avoidCompanies?: string[];
  onCompanies?: (avoidCompanies: string[], keepCompany?: string) => void;
  preferV2?: boolean;
  onPreferV2?: (preferV2: boolean) => void;
  background?: string;
  isAI: boolean;
  diff?: Extraction,
}
function toExtractionContext(extraction: Extraction): ExtractionContext {
  const { fields } = contentTypeFields(extraction.contentType);
  const res: ExtractionContext = {};
  fields.forEach((f) => {
    const v = getValue(extraction, f.id);
    if (v.value != null) {
      res[f.id] = v.value;
    }
  });
  return res;
}
function contentTypeFields(contentType: ExtractionContentType): { fields: Field[], fieldName: string } {
  switch (contentType) {
    case ExtractionContentType.INVOICE:
      return { fields: invoiceFields, fieldName: 'invoice' };
    case ExtractionContentType.INVOICE_V2:
      return { fields: invoiceV2Fields, fieldName: 'invoiceV2' };
    case ExtractionContentType.INVOICE_V3:
      return { fields: invoiceV3Fields, fieldName: 'invoiceV3' };
    case ExtractionContentType.PAYROLL:
      return { fields: payrollFields, fieldName: 'payroll' };
    case ExtractionContentType.REJECT:
      return { fields: rejectFields, fieldName: 'reject' };
    case ExtractionContentType.DOC:
      return { fields: docFields, fieldName: 'doc' };
    case ExtractionContentType.BANK:
      return { fields: bankFields, fieldName: 'bank' };
    case ExtractionContentType.AUTONOMOS_RECEIPT:
      return { fields: autonomosReceiptFields, fieldName: 'autonomosReceipt' };
    // case ExtractionContentType.SALES_SUMMARY:
    //   return { fields: salesSummaryFields, fieldName: 'salesSummary' };
    // case ExtractionContentType.TAX_NOTIFICATION:
    //   return { fields: taxNotificationFields, fieldName: 'taxNotification' };
    case ExtractionContentType.OTHER_DOC:
    case ExtractionContentType.UNRELATED:
      return { fields: [], fieldName: '' };
    default:
      throw new Error(contentType);
  }
}

function emptyValue(): ExtractionValue {
  return {
    extracted: false,
    raw: '',
    page: 0,
    positions: [],
    value: undefined,
  };
}

function getValue(extraction: Extraction, path: string): ExtractionValue {
  const { fieldName } = contentTypeFields(extraction.contentType);
  return _.get((extraction as any)[fieldName], path);
}

function setValue(draft: Extraction, path: string, v: ExtractionValue) {
  const { fieldName } = contentTypeFields(draft.contentType);
  _.set((draft as any)[fieldName], path, v);
}

function initialExtraction(e: Extraction, ai: boolean, preferV2?: boolean) {
  return produce(e, (draft) => {
    if (ai) {
      const V3_EXTRACTIONS = [
        ExtractionContentType.INVOICE_V3,
        ExtractionContentType.BANK,
        ExtractionContentType.DOC,
        ExtractionContentType.PAYROLL,
        ExtractionContentType.REJECT
      ]
      if (!V3_EXTRACTIONS.includes(draft.contentType)) {
        if (draft.invoiceV3 != null) {
          draft.contentType = ExtractionContentType.INVOICE_V3;
        } else if (draft.bank != null) {
          draft.contentType = ExtractionContentType.BANK;
        } else if (draft.doc != null) {
          draft.contentType = ExtractionContentType.DOC;
        } else if (draft.payroll != null) {
          draft.contentType = ExtractionContentType.PAYROLL;
        } else if (draft.reject != null) {
          draft.contentType = ExtractionContentType.REJECT;
        }
      }
    }
    if (preferV2 && e.invoiceV2 != null) {
      draft.contentType = ExtractionContentType.INVOICE_V2;
    }
    const types = [
      { path: 'invoice', fields: invoiceFields },
      { path: 'invoiceV2', fields: invoiceV2Fields },
      { path: 'invoiceV3', fields: invoiceV3Fields },
      { path: 'bank', fields: bankFields },
      { path: 'doc', fields: docFields },
      { path: 'payroll', fields: payrollFields },
      { path: 'reject', fields: rejectFields },
      { path: 'autonomosReceipt', fields: autonomosReceiptFields }
    ];
    const draftAny = draft as any;
    for (const { path, fields } of types) {
      if (draftAny[path] == null) {
        draftAny[path] = {};
      }
      for (const f of fields) {
        if (f.isArray) {
          if (draftAny[path][f.id] == null || draftAny[path][f.id].length == 0) {
            draftAny[path][f.id] = [emptyArrayItemValue(f)];
          }
        }
        else {
          if (draftAny[path][f.id] == null) {
            draftAny[path][f.id] = emptyValue();
          }
        }
      }
    }
  });
}

function emptyArrayItemValue(f: Field) {
  if (f.children) {
    const empty: any = {};
    for (const a of f.children) {
      empty[a.id] = emptyValue();
      if (a.id == "tax") {
        empty[a.id].value = "IVA";
      }
    }
    return empty
  } else {
    return emptyValue();
  }
}

function contentTypePath(contentType: ExtractionContentType): keyof Extraction | null {
  switch (contentType) {
    case ExtractionContentType.AUTONOMOS_RECEIPT: return "autonomosReceipt";
    case ExtractionContentType.INVOICE: return "invoice";
    case ExtractionContentType.INVOICE_V2: return "invoiceV2";
    // V3
    case ExtractionContentType.INVOICE_V3: return "invoiceV3";
    case ExtractionContentType.BANK: return "bank";
    case ExtractionContentType.PAYROLL: return "payroll";
    case ExtractionContentType.REJECT: return "reject";
    case ExtractionContentType.DOC: return "doc";
    default: return null
  }
}

interface Cell {
  path: string,
  field: Field,
  error: boolean,
  header?: string,
  arrayField?: Field,
  index?: number
}

function calcCells(extraction: Extraction): Cell[] {
  const { fields } = contentTypeFields(extraction.contentType)
  const contentPath = contentTypePath(extraction.contentType);
  if (contentPath == null) { return []; }
  const context = toExtractionContext(extraction);
  const cells: Cell[] = [];

  for (const field of fields) {
    if (field.isArray) {
      const len: number = (extraction[contentPath] as any)[field.id].length ?? 0;
      for (let i = 0; i < len; i++) {
        const arrPath = `${field.id}[${i}]`;
        if (field.children != null) {
          const allEmpty = field.children.every((arrField) => !getValue(extraction, arrPath + "." + arrField.id).value);
          field.children.forEach((arrField, fieldi) => {
            const path = arrPath + "." + arrField.id;
            const v = getValue(extraction, path);
            cells.push({
              error: !allEmpty && !isValid(v, arrField, context),
              field: arrField,
              path,
              header: fieldi === 0 ? ((field.name ?? '') + " " + (i + 1)) : undefined,
              arrayField: field,
              index: i
            });
          });
        } else {
          const v = getValue(extraction, arrPath);
          cells.push({
            error: !isValid(v, field, context),
            field: field,
            path: arrPath,
            header: (field.name ?? '') + " " + (i + 1),
            arrayField: field,
            index: i
          })
        }
      }
    } else {
      const path = field.id;
      const v = getValue(extraction, path);
      cells.push({
        error: !isValid(v, field, context),
        field: field,
        path,
        header: field.header
      });
    }
  }
  return cells;
}

const useStyles = makeStyles(() => (
  {
    resolveButton: {
      marginTop: "24px"
    },
    viewerButton: {
      marginRight: "10px"
    }
  }
));
const c = <Checkbox />;
export const ExtractionEditor = (props: Props): JSX.Element => {
  const classes = useStyles();
  const [page, setPage] = React.useState<number>(0);
  const [data, setData] = React.useState<TextAnnotation | undefined>();
  const [zoom, setZoom] = React.useState(100);
  const [rotation, setRotation] = React.useState(0);

  const [seenLastPage, setSeenLastPage] = React.useState(false);
  const [lockNewOnResolve, setLockNewOnResolve] = React.useState(props.lockNewOnResolve ?? false);

  const [extraction, setExtraction] = useImmer(() => initialExtraction(props.initialValue, props.isAI, props.preferV2));
  const resolveRef = React.useRef<HTMLButtonElement>(null);

  const memoized = React.useMemo(
    () => {
      let highlighted: number[] = [];
      const labels: { [k: number]: { text: string, path: string } } = {};
      const cells = calcCells(extraction);
      const hasError = cells.some((c) => c.error);
      const canSave = seenLastPage && (!hasError || props.isAI) && cells.every((c) => getValue(extraction, c.path).extracted);
      for (const cell of cells) {
        const value = getValue(extraction, cell.path);
        if (value.page === page) {
          highlighted = highlighted.concat(value.positions);
          if (value.positions.length > 0) {
            labels[value.positions[0]] = { text: cell.field.name, path: cell.path };
          }
        }
      }
      return {
        labels,
        highlighted,
        canSave,
        cells,
        hasError,
      };
    },
    [seenLastPage, page, extraction, props.isAI]);

  const [currentPath, setCurrentPath] = React.useState<string | null>(memoized.cells[0]?.path ?? null);

  const fieldsRef = React.useRef<ExtractionFields>(null);
  const scrollLeftRef = React.useRef<HTMLDivElement>(null);
  const scrollRightRef = React.useRef<HTMLDivElement>(null);
  const diff = props.diff;
  React.useEffect(
    function loadPageEffect() {
      let canceled = false;
      async function loadPage() {
        setData(undefined);
        const newData = await loadVision(props.imagePages[page].visionUrl);
        if (!canceled) {
          if (page === props.imagePages.length - 1) {
            setSeenLastPage(true);
          }
          setData(newData);
        }
      }
      try {
        loadPage();
      } catch (e) {
        onGenericError(e);
      }
      return () => { canceled = true; }
    },
    [page, props.imagePages]
  );

  React.useEffect(
    function setCurrentFieldEffect() {
      const firstNonChoiceCell = memoized.cells.find((c) => c.field.choices == null);
      if (firstNonChoiceCell) {
        setCurrentPath(firstNonChoiceCell.path);
      }
    },
    [extraction.contentType]
  );

  React.useEffect(
    function focusCurrentFieldEffect() {
      if (currentPath != null) {
        fieldsRef.current?.focus();
      }
    },
    [currentPath, fieldsRef]
  );

  const onAutoCompleteChanged = React.useCallback(
    (option: AutocompleteTask | null) => {
      setExtraction((draft) => {
        if (option != null) {
          draft.userMessage = option.userMessage.es;
          draft.userTaskType = option.userTaskType;
        } else {
          draft.userMessage = '';
          draft.userSolutions = '';
          draft.userTaskType = ExtractionTaskType.MESSAGE;
        }
      });
    },
    [setExtraction]
  );

  const changeType = React.useCallback(
    (t: ExtractionContentType) => {
      setExtraction((draft) => {
        draft.contentType = t;
      });
    },
    [setExtraction]
  );

  const nextCell = React.useCallback(
    (path: string | null) => {
      if (path == null) { return; }
      const currentIndex = memoized.cells.findIndex((c) => c.path == path);
      setExtraction((draft) => {
        getValue(draft, path).extracted = true;
      });
      let nextIndex = currentIndex + 1;
      if (nextIndex >= memoized.cells.length || nextIndex < 0) {
        nextIndex = currentIndex;
      }
      const next = memoized.cells[nextIndex];
      setCurrentPath(next.path);
    },
    [extraction.contentType, setExtraction, memoized]
  );

  const onChange = React.useCallback(
    (cell: Cell, newValue: ExtractionValue) => {
      if (newValue.value === '') {
        newValue.value = null;
        newValue.raw = '';
        newValue.positions = [];
      }
      newValue.extracted = true;
      setExtraction((draft) => {
        setValue(draft, cell.path, newValue);
      });
      if (cell.field.choices != null) {
        nextCell(cell.path);
      }
    },
    [nextCell, setExtraction]
  );

  const onFocus = React.useCallback(
    (cell: Cell) => {
      setExtraction((draft) => {
        const v = getValue(draft, cell.path);
        v.extracted = true;
      });
      setCurrentPath(cell.path);
    },
    [setExtraction, setCurrentPath]
  );
  const addCell = React.useCallback(
    (cell: Cell) => {
      let newIndex = 0;
      setExtraction((draft) => {
        const path = contentTypePath(draft.contentType);
        if (path == null) {
          return;
        }
        const a: any[] = (draft[path] as any)[cell.arrayField!.id];
        a.splice(cell.index! + 1, 0, emptyArrayItemValue(cell.arrayField!));
        newIndex = a.length - 1;
      });
      let newPath = cell.arrayField!.id + `[${newIndex}]`;
      if (cell.arrayField?.children) {
        newPath = newPath + "." + cell.arrayField.children[0].id;
      }
      setCurrentPath(newPath);
    },
    [setExtraction, setCurrentPath]
  )
  const onAction = React.useCallback(
    (action: string, cell: Cell) => {
      if (action === "delete") {
        setExtraction((draft) => {
          const path = contentTypePath(draft.contentType);
          if (path == null) {
            return
          }
          const arr: any[] = (draft[path] as any)[cell.arrayField!.id];
          if (cell.index != -1) {
            _.pullAt(arr, cell.index!)
          }
          if (arr.length == 0) {
            arr.push(emptyArrayItemValue(cell.arrayField!))
          }
          let newIndex = cell.index! >= arr.length ? cell.index! - 1 : cell.index;
          let newPath = cell.arrayField!.id + `[${newIndex}]`;
          if (cell.arrayField?.children) {
            newPath = newPath + "." + cell.arrayField.children[0].id;
          }
          setCurrentPath(newPath);
        });
      } else if (action === "add") {
        addCell(cell)
      } else if (action === "Receptor") {
        setExtraction((draft) => {
          if (draft.invoice) {
            const i = draft.invoice;
            const { receiverCountry, receiverId, receiverName, receiverZipcode } = i;
            i.receiverId = i.senderId;
            i.receiverName = i.senderName;
            i.receiverCountry = i.senderCountry;
            i.receiverZipcode = i.senderZipcode;
            i.senderId = receiverId;
            i.senderName = receiverName;
            i.senderCountry = receiverCountry;
            i.senderZipcode = receiverZipcode;
          }
        });
      }
    }, [setExtraction, addCell, setCurrentPath]
  )

  const addAvoidedCompanies = React.useCallback(
    () => {
      if (props.avoidCompanies && props.onCompanies) {
        if (props.avoidCompanies.includes(props.companyId)) {
          props.onCompanies(props.avoidCompanies.filter((c) => c != props.companyId), props.keepCompany,);
        } else {
          props.onCompanies(props.avoidCompanies.concat([props.companyId]), props.keepCompany,);
        }
      }
    },
    [props]
  );

  const changeRotation = React.useCallback((dir: number) => {
    const rots = [0, 90, 180, 270];
    const currentIndex = rots.indexOf(rotation);
    const nextIndex = mod(currentIndex + dir, rots.length);
    setRotation(rots[nextIndex]);
  }, [setRotation, rotation]);

  const nextRotation = React.useCallback(() => { changeRotation(1); }, [changeRotation]);
  const previousRotation = React.useCallback(() => { changeRotation(-1); }, [changeRotation]);

  const changeZoom = React.useCallback((direction: number) => {
    const index = ZOOMS.indexOf(zoom);
    let nextIndex = index + direction;
    if (nextIndex < 0) {
      nextIndex = 0;
    } else if (nextIndex >= ZOOMS.length - 1) {
      nextIndex = ZOOMS.length - 1;
    }
    setZoom(ZOOMS[nextIndex]);
  }, [setZoom, zoom]);

  const nextZoom = React.useCallback(() => { changeZoom(1); }, [changeZoom]);
  const previousZoom = React.useCallback(() => { changeZoom(-1); }, [changeZoom]);

  const previousPage = React.useCallback(
    () => {
      setPage(page === 0 ? 0 : page - 1);
    },
    [page]
  );

  const nextPage = React.useCallback(
    () => {
      const last = props.imagePages.length - 1;
      setPage(page === last ? last : page + 1);
    },
    [props.imagePages.length, page]
  );

  const onKeyDown = React.useCallback((e: KeyboardEvent) => {
    if (e.key === 'º') {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      setExtraction((draft) => {
        if (memoized.cells.length === 0) { return; }
        for (const cell of memoized.cells) {
          getValue(draft, cell.path).extracted = true;
        }
      });
      resolveRef.current?.focus();
      resolveRef.current?.scrollIntoView();
    } else if (e.key === '+') {
      const cell = memoized.cells.find((cell) => cell.path === currentPath);
      if (cell != null && cell.arrayField) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        addCell(cell);
      }
    } else if (e.key === 'ArrowLeft' && e.altKey && e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      previousRotation();
    } else if (e.key === 'ArrowRight' && e.altKey && e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      nextRotation();
    } else if (e.key === 'ArrowRight' && e.altKey) {
      e.preventDefault();
      e.stopPropagation();
      nextPage();
    } else if (e.key === 'ArrowLeft' && e.altKey) {
      e.preventDefault();
      e.stopPropagation();
      previousPage();
    } else if (e.key === 'ArrowUp' && e.altKey) {
      e.preventDefault();
      e.stopPropagation();
      nextZoom();
    } else if (e.key === 'ArrowDown' && e.altKey) {
      e.preventDefault();
      e.stopPropagation();
      previousZoom();
    } else {
      console.log(e);
    }
  }, [extraction.contentType, setExtraction, nextPage, previousPage, nextZoom, previousZoom, nextRotation, previousRotation, memoized]);

  React.useEffect(() => {
    const cb = onKeyDown;
    window.addEventListener("keydown", cb, true);
    return () => {
      window.removeEventListener("keydown", cb, true);
    }
  }, [onKeyDown]);

  const save = React.useCallback(
    () => {
      const e = JSON.parse(JSON.stringify(extraction));
      // cleanup special todos
      if (e.todo != null && e.todo.startsWith(">>")) {
        e.todo = "";
      }
      const cleanExtraction: Extraction = {
        contentType: e.contentType,
        userTaskType: e.userTaskType,
        todo: e.todo,
        userMessage: e.userMessage
      };
      switch (e.contentType) {
        case ExtractionContentType.INVOICE:
          cleanExtraction.invoice = e.invoice;
          break;
        case ExtractionContentType.INVOICE_V2:
          cleanExtraction.invoiceV2 = cleanInvoiceV2(e.invoiceV2);
          break;
        case ExtractionContentType.AUTONOMOS_RECEIPT:
          cleanExtraction.autonomosReceipt = e.autonomosReceipt;
          break;
        case ExtractionContentType.INVOICE_V3:
          cleanExtraction.invoiceV3 = cleanInvoiceV3(e.invoiceV3);
          break;
        case ExtractionContentType.BANK:
          cleanExtraction.bank = e.bank;
          break;
        case ExtractionContentType.PAYROLL:
          cleanExtraction.payroll = e.payroll;
          break;
        case ExtractionContentType.DOC:
          cleanExtraction.doc = e.doc;
          break;
        case ExtractionContentType.REJECT:
          cleanExtraction.reject = e.reject;
          break;
        default:
      }
      props.onSave(cleanExtraction, lockNewOnResolve);
    },
    [extraction, lockNewOnResolve, props]
  );
  const discardTraining = React.useCallback(
    () => {
      props.onDiscardTraining(lockNewOnResolve)
    },
    [lockNewOnResolve, props]
  );

  const onSelected = React.useCallback(
    (v: { selected: number[], raw: string }, addToSelection: boolean) => {
      if (props.onSelected) {
        props.onSelected(v.raw);
      }
      if (page == null) { return; } // not loaded
      if (!props.editor) { return; }
      if (currentPath == null) { return; }
      const oldValue = getValue(extraction, currentPath);
      const cell = memoized.cells.find((cell) => cell.path === currentPath);
      if (cell == null) { return; }
      const context = toExtractionContext(extraction);
      let value: ExtractionValue;
      if (v.selected.length === 0) {
        value = {
          raw: '',
          page: 0,
          positions: [],
          value: null,
          extracted: true,
        };
      } else {
        if (addToSelection && oldValue.page === page) {
          const raw = oldValue.raw + ' ' + v.raw;
          value = {
            raw,
            page,
            positions: [...oldValue.positions, ...v.selected],
            value: cell.field.parse(raw, context),
            extracted: true
          };
        } else {
          value = {
            raw: v.raw,
            page,
            positions: v.selected,
            value: cell.field.parse(v.raw, context),
            extracted: true
          };
        }
      }
      setExtraction((draft) => setValue(draft, cell.path, value));
      fieldsRef.current?.focus();
    },
    [props, page, currentPath, memoized, extraction, setExtraction]
  );
  const onNext = React.useCallback(
    () => {
      nextCell(currentPath);
    },
    [nextCell, currentPath]
  );
  const onChangeKeepCompanies = React.useCallback((e: React.SyntheticEvent, checked: boolean) => {
    if (props.onCompanies && props.avoidCompanies) {
      props.onCompanies(props.avoidCompanies, checked ? props.companyId : undefined);
    }
  },
    [props]
  );
  const onChangeLockNewOnResolve = React.useCallback(
    (e: React.SyntheticEvent, checked: boolean) => { setLockNewOnResolve(checked); },
    [setLockNewOnResolve]
  );
  const currentValue = currentPath ? getValue(extraction, currentPath) : null;
  const selected = currentValue?.page === page ? (currentValue?.positions ?? []) : [];
  return (
    <div style={{ display: 'flex', flex: '1', flexDirection: 'row', height: '100%', background: props.background }}>
      {props.editor ?
        <div style={{ flex: '0.1 0 200px', overflow: 'auto', padding: '8px' }} ref={scrollLeftRef}>
          <Typography variant="subtitle2">
            <MUILink component={Link} color="inherit" to={`/company/${props.companyId}/documents/${props.docId}`}>
              {props.companyName} {props.taxId}
            </MUILink>
            <IntercomIcon companyId={props.companyId} />
          </Typography>
          {props.isAI ? null :
            <ScrollNoBars>
              <Typography variant="caption" style={{ lineHeight: 1.2, display: 'block', whiteSpace: 'pre' }}>
                {props.masterAccounting}
              </Typography>
            </ScrollNoBars>
          }
          {props.isAI ? null :
            <Typography variant="caption" paragraph>
              Fecha de recepción:&nbsp;
              {
                props.receivedDate ?
                  formatDate(props.receivedDate) :
                  'No especificada'
              }
            </Typography>
          }
          {
            (!props.lockNewOnResolve && props.lockedBy && props.lockDate) ? (
              <Card>
                <CardHeader title="Documento bloqueado" />
                <CardContent>
                  <Typography variant="body1">
                    Transcriptor: {props.lockedBy}
                    <br />
                    Fecha de bloqueo: {formatDateTime(props.lockDate)}
                  </Typography>
                </CardContent>
              </Card>

            ) : null
          }
          <TextField
            autoFocus
            label="Tipo de documento"
            value={extraction.contentType}
            helperText={diff ? diff.contentType : "Tipo de documento"}
            onChange={(e) => changeType(e.target.value as ExtractionContentType)}
            select={true}
            SelectProps={{ native: true }}
            InputProps={{
              startAdornment:
                (extraction.confidence != null && extraction.confidence < 0.9) ?
                  (<InputAdornment position="start">
                    <span style={{ color: 'red' }}>*</span>
                  </InputAdornment>)
                  : null
            }}
            margin="dense"
            InputLabelProps={{ shrink: true }}
            variant="filled"
            fullWidth
          >
            {props.isAI ?
              <>
                <option value={ExtractionContentType.INVOICE_V3}>Factura 3.0</option>
                <option value={ExtractionContentType.BANK}>Extracto</option>
                <option value={ExtractionContentType.PAYROLL}>Nómina</option>
                <option value={ExtractionContentType.DOC}>Documento</option>
                <option value={ExtractionContentType.REJECT}>Rechazado</option>
              </>
              :
              <>
                <option value={ExtractionContentType.INVOICE_V3}>NEW Factura 3.0</option>
                <option value={ExtractionContentType.BANK}>NEW Extracto</option>
                <option value={ExtractionContentType.PAYROLL}>NEW Nómina</option>
                <option value={ExtractionContentType.DOC}>NEW Documento</option>
                <option value={ExtractionContentType.REJECT}>NEW Rechazado</option>

                <option value={ExtractionContentType.INVOICE}>Factura</option>
                <option value={ExtractionContentType.INVOICE_V2}>Factura 2.0</option>
                <option value={ExtractionContentType.AUTONOMOS_RECEIPT}>Recibo de autónomos</option>
                <option value={ExtractionContentType.OTHER_DOC}>Otros documentos</option>
                <option value={ExtractionContentType.UNRELATED}>Irrelevante</option>
              </>
            }
          </TextField>
          {currentPath != null ?
            <ExtractionFields
              ref={fieldsRef}
              extraction={extraction}
              diff={diff}
              cells={memoized.cells}
              currentPath={currentPath}
              onChange={onChange}
              onFocus={onFocus}
              onAction={onAction}
            /> : null}
          {props.isAI ? null : <>
            <AutoCompleteTask
              value={extraction.userMessage}
              contentType={extraction.contentType}
              onChange={onAutoCompleteChanged}
            />
            <TextField
              label="Tarea para el gestor"
              value={extraction.todo}
              helperText="El gestor lo verá en su lista de tareas"
              onChange={(e) => {
                e.persist();
                setExtraction((draft) => {
                  draft.todo = e.target.value;
                });
              }}
              multiline={false}
              margin="dense"
              InputLabelProps={{ shrink: true }}
              variant="filled"
              fullWidth
            />
          </>
          }
          <Button
            onClick={save}
            disabled={!memoized.canSave}
            variant="contained"
            size="large"
            color="primary"
            className={classes.resolveButton}
            fullWidth
            ref={resolveRef}
          >
            {seenLastPage ? (memoized.hasError ? 'Resolver con error' : 'Resolver') : 'Hay más páginas'}
          </Button>
          {
            props.lockNewOnResolve ? (
              <span>
                <FormControlLabel
                  checked={lockNewOnResolve}
                  onChange={onChangeLockNewOnResolve}
                  control={c}
                  label="Abrir otro al resolver"
                />
                <FormControlLabel
                  checked={props.keepCompany === props.companyId}
                  onChange={onChangeKeepCompanies}
                  control={c}
                  label="Seguir con esta empresa"
                />
                <FormControlLabel
                  checked={props.avoidCompanies ? props.avoidCompanies.includes(props.companyId) : false}
                  onChange={addAvoidedCompanies}
                  control={c}
                  label="Evitar esta empresa"
                />
              </span>
            ) : null
          }
          {
            props.isAI ? (
              <Button
                key="ignore"
                onClick={discardTraining}
                variant="contained"
                size="large"
                style={{ marginTop: 24 }}
                fullWidth
              >
                Ignorar en entreno
              </Button>
            ) : null
          }
          <Button
            key="close"
            onClick={props.close}
            variant="contained"
            size="large"
            style={{ marginTop: 24 }}
            fullWidth
          >
            Salir
          </Button>

        </div> : null
      }
      <div
        style={{
          flex: '1', display: 'flex', flexDirection: 'column',
          position: 'relative', // backgroundColor: 'rgba(0,0,0,0.09)'
        }}
      >
        <div style={{ position: 'absolute', bottom: 10, width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            className={classes.viewerButton}
            size="small"
            onClick={previousRotation}
            title="⇧+⌥+←"
          >
            <RotateLeftIcon />
          </Button>
          <Button
            variant="contained"
            className={classes.viewerButton}
            size="small"
            onClick={nextRotation}
            title="⇧+⌥+→"
          >
            <RotateRightIcon />
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.viewerButton}
            onClick={previousZoom}
            title="⌥+↑"
          >
            <ZoomOutIcon />
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.viewerButton}
            onClick={nextZoom}
            title="⌥+↓"
          >
            <ZoomInIcon />
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.viewerButton}
            onClick={previousPage}
            title="⌥+←"
          >
            <NavigateBeforeIcon />
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.viewerButton}
          >
            {page + 1} / {props.imagePages.length}
            {
              props.imageOriginalPages > props.imagePages.length ?
                ('+' + String(props.imageOriginalPages - props.imagePages.length))
                : ''
            }
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.viewerButton}
            onClick={nextPage}
            title="⌥+→"
          >
            <NavigateNextIcon />
          </Button>

        </div>
        <div style={{ flex: '1 0', overflow: 'auto', paddingBottom: 40 }} ref={scrollRightRef}>
          {
            data != null ?
              <VisionViewer
                data={data}
                image={props.imagePages[page].imageUrl}
                selected={selected}
                highlighted={memoized.highlighted}
                labels={memoized.labels}
                onSelected={onSelected}
                rotation={rotation}
                zoom={zoom}
                onNext={onNext}
                fieldsRef={fieldsRef}
                scrollLeftRef={scrollLeftRef}
                scrollRightRef={scrollRightRef}
                currentPath={currentPath ?? undefined}
              /> : <Loading />
          }
        </div>
      </div>
    </div >);
};

const useNoscrollbarStyles = makeStyles({
  noscrollbar: {
    maxHeight: '4em',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
});

const ScrollNoBars = (props: { children: React.ReactNode }) => {
  const classes = useNoscrollbarStyles();
  return (
    <div className={classes.noscrollbar}>
      {props.children}
    </div>
  );
};

function CustomPopper(props: PopperProps): React.ReactElement {
  return (
    <Popper
      {...props}
      style={{ width: '600px' }}
    />
  );
}

const useAutocompleteStyles = makeStyles({
  bold: {
    fontWeight: 500
  }
});

const AutoCompleteTask = React.memo(_AutoCompleteTask, (prevProps, nextProps) => _.isEqual(prevProps, nextProps));


function _AutoCompleteTask(props: {
  value: string,
  contentType: ExtractionContentType,
  onChange: (o: AutocompleteTask | null) => void
}): React.ReactElement {
  const classes = useAutocompleteStyles();
  const value: AutocompleteTask = {
    userMessage: {
      es: props.value,
      ca: ''
    },
    solutions: [],
    helpLink: '',
    userTaskType: ExtractionTaskType.UNREADABLE,
    contentType: ExtractionContentType.OTHER_DOC, // not really used
  };

  return (
    <Autocomplete
      PopperComponent={CustomPopper}
      freeSolo
      autoSelect
      autoComplete
      autoHighlight
      disableClearable
      openOnFocus
      clearOnBlur
      includeInputInList
      options={autocompleteTasks.filter((a) => a.contentType === props.contentType || a.userMessage.es === '')}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.userMessage.es !== '' ?
            <Typography variant="body1">
              <span className={classes.bold}>{option.userMessage.es}</span>
              <br />
              {option.userTaskType} - {option.solutions.join(', ')} -
              <a href={'https://help.abaq.app/es/articles/' + option.helpLink}>Link</a>
              <br />

            </Typography>
            :
            <Typography variant="body1">
              <span>-Sin mensaje-</span>
            </Typography>
          }
        </li>
      )}
      value={value}
      onChange={(e, newValue) => {
        if (typeof newValue === 'string') {
          props.onChange({
            userMessage: {
              es: newValue,
              ca: ''
            },
            solutions: [],
            helpLink: '',
            userTaskType: ExtractionTaskType.MESSAGE,
            contentType: ExtractionContentType.OTHER_DOC,
          });
        } else {
          props.onChange(newValue);
        }
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        return option.userMessage.es;
      }}
      renderInput={(params) =>
        <TextField
          {...params}
          label="Mensaje para el usuario"
          variant="filled"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          fullWidth
          helperText="El usuario verá este mensaje en la zona de notificaciones"
        />
      }
    />
  );
}
interface ExtractionFieldsProps {
  extraction: Extraction;
  diff?: Extraction;
  currentPath: string;
  cells: Cell[];
  onChange: (cell: Cell, v: ExtractionValue) => void;
  onFocus: (cell: Cell) => void;
  onAction: (action: string, cell: Cell) => void;
}
export class ExtractionFields extends React.Component<ExtractionFieldsProps> {
  inputs: { [key: string]: React.RefObject<HTMLElement | null> } = {};
  focus() {
    this.inputs[this.props.currentPath].current?.focus();
  }
  createRefs() {
    for (const c of this.props.cells) {
      if (this.inputs[c.path] == null) {
        this.inputs[c.path] = React.createRef();
      }
    }
  }
  render = () => {
    this.createRefs();
    return (
      <form style={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
        {this.props.cells.map((cell, i) => {
          return <ExtractionTextField
            key={cell.path}
            cell={cell}
            value={getValue(this.props.extraction, cell.path)}
            diffValue={this.props.diff ? getValue(this.props.diff, cell.path) : null}
            ref={this.inputs[cell.path]}
            onFocus={this.props.onFocus}
            onChange={this.props.onChange}
            onAction={this.props.onAction}
            isCurrent={this.props.currentPath === cell.path}
          />;
        })}
      </form>
    );
  }
}

const _ExtractionTextField = React.forwardRef(function __ExtractionField(
  props: {
    key: string,
    cell: Cell,
    value: ExtractionValue,
    diffValue: ExtractionValue | null,
    onFocus: (f: Cell) => void,
    onChange: (f: Cell, v: ExtractionValue) => void,
    onAction: (a: string, cell: Cell) => void
    isCurrent: boolean
  },
  ref: React.Ref<unknown>
) {
  const cell = props.cell;
  const value = props.value;
  const diffValue = props.diffValue;
  const hasDiff = (diffValue != null && diffValue.value != value.value);
  const confidencePercent = value.confidence != null ? String(Math.round(value.confidence * 100)) + '%' : '';
  return (
    <React.Fragment>
      {cell.header ? <Typography variant="subtitle2">
        {cell.header}
        {cell.header === 'Receptor' ? <IconButton tabIndex={-1} onClick={() => props.onAction('Receptor', cell)}><SwapVertIcon /></IconButton> : undefined}
        {cell.arrayField != null && cell.index != -1 ? <IconButton tabIndex={-1} onClick={() => props.onAction('delete', cell)}><DeleteIcon /></IconButton> : undefined}
        {cell.arrayField != null ? <IconButton tabIndex={-1} onClick={() => props.onAction('add', cell)}><AddIcon /></IconButton> : undefined}
      </Typography>
        : undefined
      }
      <TextField
        key={cell.path}
        inputRef={ref}
        select={cell.field.choices != null}
        SelectProps={{
          native: true,
        }}
        id={cell.field.id}
        label={cell.field.name}
        value={(value.value) || ''}
        error={cell.error || hasDiff}
        helperText={diffValue ? ((hasDiff ? "!! " : "== ") + (diffValue.value ?? '')) : (value.raw || ' ')}
        onFocus={() => { props.onFocus(cell); }}
        onChange={(e) => {
          props.onChange(cell, { ...value, value: e.target.value, confidence: undefined });
        }}
        margin="dense"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          style: props.isCurrent ? { backgroundColor: 'rgba(200,200,255,0.5)' } : undefined,
          startAdornment:
            (value.confidence != null && value.confidence < 0.9) ?
              <InputAdornment position="start">
                <span style={{ color: 'red' }}>*</span>
              </InputAdornment>
              : null,
          endAdornment:
            <InputAdornment position="end" title={confidencePercent}>
              {!value.extracted ? < VisibilityIcon /> : cell.error ? < ErrorIcon /> : < CheckIcon />}
            </InputAdornment>
        }}
        variant="filled"
        autoComplete="false"
        autoCapitalize="false"
        autoCorrect="false"
        fullWidth
      >
        {
          cell.field.choices != null ? <option key="empty" value="">-</option> : undefined
        }
        {
          cell.field.choices != null
            ?
            (cell.field.choices.map(option => (
              <option key={option.v} value={option.v}>
                {option.h}
              </option>)))
            : undefined
        }
      </TextField>
    </React.Fragment >
  );
});

const ExtractionTextField = React.memo(_ExtractionTextField, (prevProps, nextProps) => _.isEqual(prevProps, nextProps));

function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

function isValid(v: ExtractionValue, field: Field, context: ExtractionContext): boolean {
  if (v.value == null) {
    return field.choices == null;
  } else {
    return field.isValid(v.value, context);
  }
}

async function loadVision(url: string): Promise<TextAnnotation> {
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error('Status code downloading vision: ' + response.status);
  }
  const buffer = await response.arrayBuffer();
  const bytes = new Uint8Array(buffer);
  const a = Annotate.read(new Pbf(bytes)) as AnnotateImageResponse;
  if (!a.full_text_annotation) {
    return { pages: [], text: '' };
  } else {
    return a.full_text_annotation;
  }
}

function cleanInvoiceV2(invoiceV2: InvoiceV2Extraction): InvoiceV2Extraction {
  invoiceV2.taxes = invoiceV2.taxes.filter((i) => i.base.value || i.rate.value || i.amount.value)
  return invoiceV2;
}

function cleanInvoiceV3(invoiceV3: InvoiceV3Extraction): InvoiceV3Extraction {
  invoiceV3.taxes = invoiceV3.taxes.filter((i) => i.base.value || i.rate.value || i.amount.value || i.tax.value)
  return invoiceV3;
}

const INTERCOM_USERS = gql`
  query QEditorUsers($company: ID!) {
    company(id: $company) {
      id
      intercomUsers {
        id,
        intercomId,
      }
    }
  }
`;
function IntercomIcon(props: { companyId: string }): JSX.Element {
  const q = useQuery<QEditorUsers, QEditorUsersVariables>(INTERCOM_USERS, { variables: { company: props.companyId } });
  const intercomId = q.data?.company?.intercomUsers[0]?.intercomId;
  return <IconButton disabled={!intercomId} href={getIntercomUserUrlByIntercomId(intercomId ?? '')} target="_blank">
    <Person />
  </IconButton>

}