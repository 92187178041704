import * as React from 'react';
import { Link } from 'react-router-dom';
import { TaskOrigin } from '../gen/globalTypes';

const uuidRegex = /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/ig;

function* matches(text: string, pattern: RegExp) {
  pattern.lastIndex = 0;
  let match = null;
  do {
    match = pattern.exec(text);
    if (match) {
      yield match;
    }
  } while (match);
}

interface TextItem {
  company?: { id: string };
  companyId?: string;
  docId: string | null;
  title?: { es: string };
  titleES?: string;
  idx: number | null;
  origin: TaskOrigin;
  sleepDate: string | null;
  delayUntil: string | null;
  taskGroupId?: string | null;
  taskGroupTitle?: string | null;
  taskGroup?: {
    id: string;
    title: { es: string };
  } | null;
  sleeping: boolean;
  taskStatus: "DELAYED" | "PENDING" | "COMPLETED" | "LOCKED" | "HIDDEN";
}

export const TaskText = (props: { data: TextItem }) => {
  let indexNotMatched = 0;
  const res = [];
  const style: React.CSSProperties = { fontWeight: props.data.origin === TaskOrigin.MANUAL ? 500 : 'inherit' };

  switch (props.data.taskStatus) {
    case "PENDING":
      break;
    case "COMPLETED":
      res.push(<span key="status">✅ </span>);
      style['color'] = 'rgba(0,0,0,0.5)';
      style.textDecoration = 'line-through';
      break;
    case "LOCKED":
      res.push(<span key="status">🔑 </span>);
      style['color'] = 'rgba(0,0,0,0.5)';
      style.textDecoration = 'line-through';
      break;
    case "DELAYED":
      res.push(<span key="status">💤 {props.data.delayUntil} </span>);
      style['color'] = 'rgba(0,0,0,0.5)';
      style.textDecoration = 'line-through';
      break;
    case "HIDDEN":
      res.push(<span key="status">👻 </span>);
      style['color'] = 'rgba(0,0,0,0.5)';
      style.textDecoration = 'line-through';
      break;
  }
  if (props.data.sleeping) {
    res.push(<span key="sleep">💤 {props.data.sleepDate} </span>);
    style['color'] = 'rgba(0,0,0,0.5)';
    style.textDecoration = 'line-through';
  }

  const taskGroupTitle = props.data.taskGroup?.title.es ?? props.data.taskGroupTitle;
  const taskGroupId = props.data.taskGroup?.id ?? props.data.taskGroupId;
  if (taskGroupTitle && taskGroupId !== 'errors') {
    res.push(<span key="taskgroup" style={{ 'paddingRight': '5px' }}>({taskGroupTitle})</span>);
  }

  const title = props.data?.titleES ?? props.data?.title?.es ?? '';
  const companyId = props.data?.company?.id ?? props.data.companyId;
  for (const match of matches(title, uuidRegex)) {
    if (match.index !== indexNotMatched) {
      res.push(<span key={"n" + match.index}>{title.slice(indexNotMatched, match.index)}</span>);
    }
    res.push(
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`/company/${companyId}/documents/${match[0]}`}
        key={match.index}
      >
        {match[0]}
      </Link >
    );
    indexNotMatched = match.index + match[0].length;
  }
  if (indexNotMatched !== title.length) {
    res.push(<span key={"e" + res.length}>{title.slice(indexNotMatched)}</span>);
  }
  return <span style={style}>
    {props.data.idx ? (props.data.idx + " ") : ""}{res}
  </span>
};