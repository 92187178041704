import * as React from 'react';
import {
  Typography, Link as MUILink, List, ListSubheader, ListItem,
  IconButton, ListItemText, ListItemSecondaryAction, Popover, Theme
} from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { Loading } from '../../widgets/loading';
import { Journal } from './journal';
import { QDocInfoContext } from '../../gen/QDocInfoContext';
import { DocType } from '../../gen/globalTypes';
import { Link } from 'react-router-dom';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExtraInfoIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { navigate } from '../../lib/utilBrowser';
import clipboardCopy from 'clipboard-copy';
import { notificationsClient } from '../../notifications';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { Audit } from './audit';
import { TaskText } from '../../widgets/taskText';

const QUERY = gql`
  query QDocInfoContext($company: ID!, $doc: ID!) {
    company(id: $company) {
      id,
      name,
      taxId,
      doc(id: $doc) {
        id
        deleted
        creationDate
        modificationDate
        docType
        status
        isDigitalization
        accounting
        source
        uniqueKey
        contentHash
        tasks {
          id,
          docId,
          severity,
          title {es},
          disableCode,
          taskStatus
          sleeping
          delayUntil
          sleepDate
          origin
          idx
          visibility
          company {
            id
            name
          }
          content {
            icon
          }
          taskGroup {
            id,
            title { es }
          }
        }
        userTasks {
          title {es}
          id,
          docId,
          severity,
          title {es},
          disableCode,
          taskStatus
          sleeping
          delayUntil
          sleepDate
          origin
          idx
          visibility
          company {
            id
            name
          }
          content {
            icon
          }
        }
        audit {
          changeType
          date
          accounting
          linesAdded
          linesRemoved
          user {
            id
            name
          }
        }
      }
    },
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    extraInfoIcon: {
      cursor: 'pointer',
      marginTop: -theme.spacing(1), // To preserve line-height
    },
    extraInfo: {
      padding: theme.spacing(2),
    },
  });

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  doc: string;
  company: string;
}

interface State {
  account?: string;
  oid?: number;
  extraInfoOpened: boolean;
  extraInfoAnchor?: HTMLElement;
}

class ContextViewerInternal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { account: undefined, oid: undefined, extraInfoOpened: false, extraInfoAnchor: undefined };
  }
  accountClicked = (oid: number, account: string) => {
    this.setState({ account, oid });
  }
  docClicked = (event: React.MouseEvent, doc: string) => {
    navigate({
      pathname: `/company/${this.props.company}/documents/${doc}`,
      event
    });
  }
  render = () => {
    return (
      <Query<QDocInfoContext>
        query={QUERY}
        variables={{
          company: this.props.company,
          doc: this.props.doc
        }}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (<Loading />);
          }
          if (error) {
            return (<div>error {JSON.stringify(error)}</div>);
          }
          if (!data || data.company == null) {
            return (<div>no data</div>);
          }
          if (data.company.doc == null) {
            return <div />;
          }
          const tasks =
            <div>
              {
                <List>
                  <ListSubheader>Tareas</ListSubheader>
                  {
                    data.company.doc.tasks.map((task, i) =>
                      <ListItem key={i}>
                        <ListItemText primary={<TaskText data={task} />} />
                        {task.disableCode ?
                          <ListItemSecondaryAction
                            onClick={(event) => {
                              event.stopPropagation();
                              clipboardCopy('# DISABLE ' + task.disableCode);
                              notificationsClient.addMessage('Copiado al portapapeles');
                            }
                            }
                          >
                            <IconButton edge="end">
                              <AssignmentLateIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                          : null
                        }
                      </ListItem>
                    )
                  }
                  {data.company.doc.userTasks.length > 0 ? (
                    <React.Fragment>
                      <ListSubheader>Avisos cliente</ListSubheader>
                      {data.company.doc.userTasks.map((task, i) =>
                        <ListItem key={i}>
                          <ListItemText primary={<TaskText data={task} />} />
                        </ListItem>
                      )}
                    </React.Fragment>
                  ) : null}
                </List>
              }
            </div>
            ;
          const isDeleted = data.company.doc.deleted;
          const hasExtraInfo = Boolean(data.company.doc.uniqueKey || data.company.doc.contentHash);

          let uniqueKeyLabel = `Referencia única`;
          if (data.company.doc.uniqueKey && data.company.doc.uniqueKey === data.company.doc.contentHash) {
            uniqueKeyLabel += ` y Hash`;
          }
          return (
            <div style={{ padding: '8px', flex: '1', overflow: 'auto' }}>
              <Typography variant="h6">
                <MUILink color="inherit" component={Link} to={`/company/${data.company.id}`}>
                  {data.company.name} - {data.company.taxId}
                </MUILink>
              </Typography>
              <Typography variant="body1">
                {data.company.doc.docType === DocType.STANDALONE ? 'Documento maestro' : (data.company.doc.docType === DocType.ACTIONS ? 'Registro de acciones' : 'Documento')}
                {isDeleted ? ' (BORRADO)' : ''}
                <span> (Estado: {data.company.doc.status})</span>
                <span>
                  &nbsp;
                  (Tipo:
                  {data.company.doc.docType}
                  {data.company.doc.isDigitalization ? <VerifiedUserIcon /> : null}
                  )
                </span>
              </Typography>
              <Typography variant="body1">
                <span>
                  Creado {moment(data.company.doc.creationDate).format('YYYY-MM-DD HH:mm:ss')}
                  &nbsp;
                  Modificado {moment(data.company.doc.modificationDate).format('YYYY-MM-DD HH:mm:ss')}
                  {hasExtraInfo ? (
                    <ExtraInfoIcon
                      className={this.props.classes.extraInfoIcon}
                      onClick={(e) => {
                        this.setState({
                          extraInfoAnchor: this.state.extraInfoOpened ?
                            undefined : e.currentTarget as unknown as HTMLElement,
                          extraInfoOpened: !this.state.extraInfoOpened
                        });
                      }}
                    />
                  ) : null}
                </span>
              </Typography>
              {
                hasExtraInfo ? (
                  <Popover
                    open={this.state.extraInfoOpened}
                    onClose={() => {
                      this.setState({ extraInfoOpened: false });
                    }}
                    anchorEl={this.state.extraInfoAnchor}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                  >
                    <div className={this.props.classes.extraInfo}>
                      {
                        data.company.doc.source ? (
                          <Typography variant="body2">
                            <span>
                              Origen: {data.company.doc.source}
                            </span>
                          </Typography>
                        ) : null
                      }
                      {
                        data.company.doc.uniqueKey ? (
                          <Typography variant="body2">
                            <span>
                              {uniqueKeyLabel}: {data.company.doc.uniqueKey}
                            </span>
                          </Typography>
                        ) : null
                      }
                      {
                        data.company.doc.contentHash && data.company.doc.contentHash !== data.company.doc.uniqueKey ? (
                          <Typography variant="body2">
                            <span>
                              Hash: {data.company.doc.contentHash}
                            </span>
                          </Typography>
                        ) : null
                      }
                    </div>
                  </Popover>
                ) : null
              }
              {
                data.company.doc.tasks.length + data.company.doc.userTasks.length !== 0 ? tasks : null
              }
              <Journal company={this.props.company} doc={this.props.doc} onAccountClicked={this.accountClicked} />
              <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                Historial
              </Typography>
              <Audit
                currentAccounting={data.company.doc.accounting}
                audit={data.company.doc.audit}
              />
              {/* <Ledger
                account={this.state.account}
                oid={this.state.oid}
                company={this.props.company}
                onDocClicked={this.docClicked}
              /> */}
            </div>
          );
        }}
      </Query>
    );
  }
}

export const ContextViewer = withStyles(styles)(withRouter(ContextViewerInternal));