// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project
import { round } from '../formats';

// adapted from https://github.com/caiogondim/parse-price.js/blob/master/src/index.js
function filterNumbers(str: string): string {
  return str.replace(/[^\d]/g, '');
}

function filterNumbersDotsAndCommas(str: string): string {
  return str
    .replace(/[:;`'´]/g, '.')
    .replace(/[^\d.,]/g, '')
    .replace(/[.,]$/, '');
}

function getDecimalPosition(str: string): number | null {
  // guess if a dot is a thousands separator or a decimal places separator
  let firstSeparator = null;
  let lastSeparator = null;
  let lastSeparatorIndex = null;
  let digitsAfterDot = 0;
  let dotsSeen = 0;
  for (let i = 0; i < str.length; i++) {
    const currentChar = str[i];
    if ([',', '.'].indexOf(currentChar) !== -1) {
      if (firstSeparator == null) {
        firstSeparator = currentChar;
      }
      lastSeparator = currentChar;
      lastSeparatorIndex = i;
      dotsSeen += 1;
      digitsAfterDot = 0;
    } else if (dotsSeen > 0) {
      digitsAfterDot += 1;
    }
  }
  if (dotsSeen === 0) {
    return null;
  } else {
    if (digitsAfterDot === 3) { // this is the ambigous case
      // its a decimal separator if its different the thousands separator
      if (firstSeparator !== lastSeparator) {
        return lastSeparatorIndex;
      } else {
        return null;
      }
    } else {
      return lastSeparatorIndex;
    }
  }
}

export function parseAmount(str: string): string {
  let decimalPart = '00';
  const filtered = filterNumbersDotsAndCommas(str);
  let integerPart = filtered;

  const decimalPosition = getDecimalPosition(filtered);
  if (decimalPosition) {
    decimalPart = filtered.substring(decimalPosition);
    integerPart = filtered.substring(0, decimalPosition);
  }
  return round(Number(filterNumbers(integerPart) + '.' + filterNumbers(decimalPart))).toFixed(2);
}

const validAmountRE = /^[0-9]+(\.[0-9]{2})?$/;
export const isValidAmount = (s: string): boolean => s.match(validAmountRE) != null;
