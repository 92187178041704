import * as React from 'react';
import {
  Table, TableBody, TableRow, TableCell, TableHead, IconButton, Dialog,
  DialogContent, DialogActions, Button, DialogTitle
} from '@mui/material';
import clipboardCopy from 'clipboard-copy';
import CompareIcon from '@mui/icons-material/Compare';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import moment from 'moment';
import ReactDiffViewer from 'react-diff-viewer';
import { DocChangeType } from '../../gen/globalTypes';

interface AuditItem {
  date: string;
  accounting: string;
  changeType: string;
  linesAdded: number;
  linesRemoved: number;
  user: {
    id: string;
    name: string | null;
  } | null;
}
interface Props {
  audit: AuditItem[];
  currentAccounting: string;
}

type State = { old: string, new: string, mode: 'current' | 'before' } | null;
export const Audit = ({ audit, currentAccounting }: Props) => {
  const [compareTo, setCompareTo] = React.useState<State>(null);
  return (
    <React.Fragment>
      <Dialog open={compareTo != null} onClose={() => setCompareTo(null)} maxWidth="xl" fullWidth>
        <DialogTitle>{
          compareTo?.mode === 'before' ?
            'Diferencias anterior vs seleccionado' :
            'Diferencias seleccionado vs actual'}
        </DialogTitle>
        <DialogContent>
          <ReactDiffViewer
            oldValue={compareTo?.old}
            newValue={compareTo?.new}
            styles={{ wordDiff: { padding: 0 } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCompareTo(null)} color="primary">
            Cerrar
          </Button>
          <Button onClick={() => clipboardCopy(compareTo?.new || '')} color="primary">
            Copiar contenido al portapapeles
          </Button>
        </DialogActions>
      </Dialog>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Cambio</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Autor</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            audit.map(({ date, changeType, accounting, linesAdded, linesRemoved, user }, i) =>
            (
              <TableRow key={i}>
                <TableCell>{
                  changeType === DocChangeType.UPDATED ?
                    <span>
                      <span style={{ color: 'green' }}>{'+'.repeat(linesAdded)}</span>
                      <span style={{ color: 'red' }}>{'-'.repeat(linesRemoved)}</span>
                    </span>
                    : changeType
                }
                </TableCell>
                <TableCell>
                  {moment(date).fromNow()} <br />
                  {moment(date).format('lll')}
                </TableCell>
                <TableCell>
                  {user?.name ?? ''}
                </TableCell>
                <TableCell>
                  {
                    i === audit.length - 1 ? null : <IconButton
                      onClick={() => {
                        setCompareTo({ old: (audit[i + 1]?.accounting) || '', new: accounting, mode: 'before' });
                      }}
                      title="Comparar con anterior"
                    >
                      <CallMergeIcon />
                    </IconButton>

                  }
                  {
                    i === 0 ? null : <IconButton
                      onClick={() => {
                        setCompareTo({ old: accounting, new: currentAccounting, mode: 'current' });
                      }}
                      title="Comparar con actual"
                    >
                      <CompareIcon />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            )
            )}
        </TableBody>
      </Table>
    </React.Fragment >
  );
};