// IMPORTANT: Copied from abaq-server to abaq-front, DO NOT EDIT in abaq-front project
import { Decimal } from 'decimal.js';
import * as accounting from 'accounting';

export function round(n: number, ndec: number = 2): number {
  // why 6? Because in classic was working that way, I really don't know the minimun needed (blush)
  return new Decimal(n.toFixed(ndec + 6)).toDP(ndec).toNumber();
}

export function trunc(n: number, ndec: number = 2): number {
  return new Decimal(n.toFixed(ndec)).toNumber();
}
// TODO review
export function asMoney(n: number, ndec: number = 2): string {
  return new Decimal(n.toFixed(ndec + 6)).toDP(ndec).toFixed(ndec);
}

export function asEuro(n: number): string {
  return accounting.formatMoney(n, { symbol: '€', precision: 2, thousand: '.', decimal: ',', format: '%v %s' });
}

const DECIMAL_SEPARATOR = ',';
const THOUSAND_SEPARATOR = '.';
export function formatCurrencyCentsLikeMobile(amountCents: number, currency: string, ndec: number = 2): string {
  return accounting.formatMoney(round(amountCents / 100, ndec), {
    symbol: currency === 'EUR' ? '€' : currency,
    format: '%v%s',
    decimal: DECIMAL_SEPARATOR,
    thousand: THOUSAND_SEPARATOR,
    precision: ndec
  });
}

// shortens person name: Frederic Pérez Ordeig => Frederic P.O.
export function personShortName(s: string): string {
  const parts = s.trim().split(/\s+/);
  if (parts.length <= 1) {
    return s;
  } else {
    return parts[0] + ' ' + parts.slice(1).map((x) => x[0] + '.').join('');
  }
}

export const commentAllLines = (acc: string): string => {
  if (acc === '') {
    return '';
  } else {
    return acc.replace(/^(# )*/gm, '# ')
  }
};
